import { getIDToken } from "../../utils/ms-graph-api-call";
const { REACT_APP_API_URL} = process.env;
const headers = {'Content-Type' : 'application/json'};

export default class SectionsApi {
    async getSections() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/sections`, {
            headers: headers
        }).then(response => response.json());
    }
    /**
     * getSectionsByTermStaffAndType
     * @param {number} term: the TermID of the term to view
     * @param {*} staff: the PersonID of the staff the sections are assigned to
     * @param {*} type: the ClassTypeID of the sections to view
     * @returns 
     */
    async getSectionsByTermStaffAndType(term, staff, type) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        let fetchEndpoint = `${REACT_APP_API_URL}/api/sections/filtered/other-staff`;
        if(term && staff && type) {
            fetchEndpoint = fetchEndpoint.concat(`?termID=${term}&staffID=${staff}&classTypeID=${type}`);
        } else if (term && !staff && type) {
            fetchEndpoint = fetchEndpoint.concat(`?termID=${term}&classTypeID=${type}`);
        } else if (term && staff && !type) {
            fetchEndpoint = fetchEndpoint.concat(`?termID=${term}&staffID=${staff}`);
        } else if (term && !staff && !type) {
            fetchEndpoint = fetchEndpoint.concat(`?termID=${term}`);
        } else if (!term && staff && type) {
            fetchEndpoint = fetchEndpoint.concat(`?staffID=${staff}&classTypeID=${type}`);
        } else if (!term && staff && !type) {
            fetchEndpoint = fetchEndpoint.concat(`?staffID=${staff}`);
        } else if (!term && !staff && type) {
            fetchEndpoint = fetchEndpoint.concat(`?classTypeID=${type}`);
        }
        return fetch(fetchEndpoint, {
            headers: headers
        }).then(response => response.json());
    }

    async getSectionsByTermAndType(term, type) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        let fetchEndpoint = `${REACT_APP_API_URL}/api/sections/filtered/admin`;
        if(term  && type) {
            fetchEndpoint = fetchEndpoint.concat(`?termID=${term}&classTypeID=${type}`);
        } else if (term && !type) {
            fetchEndpoint = fetchEndpoint.concat(`?termID=${term}`);
        } else if (!term && type) {
            fetchEndpoint = fetchEndpoint.concat(`?classTypeID=${type}`);
        }
        return fetch(fetchEndpoint, {
            headers: headers
        }).then(response => response.json());
    }

    /**
     * method addSection
     * @param {object} sectionObj: Please include the following items-SectionNM (section name),
     * SectionScheduleID (ID to reference section scheudle table),
     * SectionStaffID (PersonID of the Primary Staff assigned to a section),
     * TermID (what term the section is held), ClassID (what class the section is based off of) 
     * @returns the newly created section.
     */
    async addSection(sectionObj) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/sections`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(sectionObj)
        }).then(response => response.json());
    }

    /**
     * method editSection
     * @param {object} sectionObj: Please include the following items- SectionID of section to edit,
     * SectionNM (section name), SectionStaffID (reassign a different instructor to the section using their PersonID)
     * @returns the edited section.
     */
    async editSection(sectionObj) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;
        
        return fetch(`${REACT_APP_API_URL}/api/sections`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(sectionObj)
        }).then(response => response.json());
    }

    async deleteSection(SectionID) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/sections/${SectionID}`, {
            method: 'DELETE',
            headers: headers
        }).then(response => response.json());
    }
}