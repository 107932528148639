import React, {useState} from 'react';
import AdminClassView from "../admin-class";
import AdminTermView from "../admin-term";
import AdminSectionView from "../admin-sections";
import AdminLevelsView from "../admin-levels";
import AdminServiceCoordinatorView from "../admin-service-coordinators";
import AdminManageTitles from "../admin-manage-titles";
import AdminManageSectionStudents from "../admin-section-students";
import AdminManageStudents from "../admin-students";
import AdminUsersView from "../admin-users";
import "./admin.css";

export default function AdminViewComponent() {
    const [currentView, setCurrentView] = useState("term-view");

    const pageToDisplay = currentView === "class-view" ? <AdminClassView/>
        : currentView === "term-view" ? <AdminTermView/>
        : currentView === "section-view" ? <AdminSectionView/>
        : currentView === "levels" ? <AdminLevelsView/>
        : currentView === "service-coordinator" ? <AdminServiceCoordinatorView/>
        : currentView === "manage-titles" ? <AdminManageTitles/>
        : currentView === "manage-section-students" ? <AdminManageSectionStudents/>
        : currentView === "manage-students" ? <AdminManageStudents/>
        : currentView === "manage-users" ? <AdminUsersView/>
        : null;

    const activeTab = "nav-link adm-link active";
    const passiveTab = "nav-link adm-link";
    return (
        <div className="container-fluid">
            <ul className="nav nav-tabs mt-2 mb-2" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className={currentView === "term-view" ? activeTab : passiveTab} onClick={() => setCurrentView("term-view")}>
                        MANAGE TERMS
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={currentView === "class-view" ? activeTab : passiveTab} onClick={() => setCurrentView("class-view")}>
                        MANAGE CLASSES
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={currentView === "section-view" ? activeTab : passiveTab} onClick={() => setCurrentView("section-view")}>
                        MANAGE SECTIONS
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={currentView === "manage-section-students" ? activeTab : passiveTab} onClick={() => setCurrentView("manage-section-students")}>
                        MANAGE SECTION STUDENTS
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={currentView === "levels" ? activeTab : passiveTab} onClick={() => setCurrentView("levels")}>
                        MANAGE LEVELS
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={currentView === "service-coordinator" ? activeTab : passiveTab} onClick={() => setCurrentView("service-coordinator")}>
                        SERVICE COORDINATORS
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={currentView === "manage-titles" ? activeTab : passiveTab} onClick={() => setCurrentView("manage-titles")}>
                        MANAGE TITLES
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={currentView === "manage-students" ? activeTab : passiveTab} onClick={() => setCurrentView("manage-students")}>
                        MANAGE STUDENTS
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={currentView === "manage-users" ? activeTab : passiveTab} onClick={() => setCurrentView("manage-users")}>
                        MANAGE USERS
                    </button>
                </li>
            </ul>
            <div>
                {pageToDisplay}
            </div>
        </div>
    ) 
}
