import React, { useEffect, useState } from 'react';
import { editIcon, saveIcon } from '../../assets/images/images';
import { PersonApi } from '../../shared/services';
import { ObjectDropdown } from "../../shared/components";

export default function UserTableRow({user, personTypes, users, setUsers}) {
    const [editable, setEditable] = useState(false);
    const [firstNM, setFirstNM] = useState(user.FirstNM);
    const [lastNM, setLastNM] = useState(user.LastNM);
    const [personType, setPersonType] = useState();
    const [emailTXT, setEmailTXT] = useState(user.EmailTXT);
    const [activeFLG, setActiveFLG] = useState(user.ActiveFLG);

    useEffect(() => {
        const index = personTypes.findIndex(ptype => ptype.PersonTypeID === user.PersonTypeID);
        setPersonType(personTypes[index]);
    }, [user, personTypes]);

    const handleEdit = () => {
        const myPersonApi = new PersonApi();
        myPersonApi.editEmployee({
            PersonID: user.PersonID,
            PersonTypeID: personType.PersonTypeID,
            FirstNM: firstNM,
            LastNM: lastNM,
            EmailTXT: emailTXT,
            ActiveFLG: activeFLG
        }).then(editedUser => {
            let tempUsers = [...users];
            tempUsers.forEach((u, index) => {
                if(u.PersonID === editedUser.PersonID) {
                    tempUsers[index] = editedUser;
                }
            });
            setUsers(tempUsers);
        });
        setEditable(false);
    }
    
    if(editable) {
        return (
            <tr>
                <td>
                    <input className="form-control" value={firstNM} onChange={({target}) => setFirstNM(target.value)}/>
                </td>
                <td>
                    <input className="form-control" value={lastNM} onChange={({target}) => setLastNM(target.value)}/>
                </td>
                <td>
                    <input className="form-control" value={emailTXT} onChange={({target}) => setEmailTXT(target.value)}/>
                </td>
                <td>
                    <ObjectDropdown
                        items={personTypes}
                        itemNameKey="PersonTypeDSC"
                        selected={personType}
                        setSelected={setPersonType}
                        label="Role"
                        isBig={false}
                    />
                </td>
                <td>
                    <input
                        className="form-check-input"
                        type="checkbox" 
                        disabled={!editable}
                        checked={activeFLG === 1 ? true : false}
                        onChange={() => setActiveFLG(activeFLG === 1 ? 0 : 1)}
                    />
                </td>
                <td>
                    <button className="btn" onClick={handleEdit}>
                        <img src={saveIcon} className="edit" alt="edit"/>
                    </button>
                </td>
            </tr>
        )
    } else {
        return (
            <tr>
                <td>{firstNM}</td>
                <td>{lastNM}</td>
                <td>{emailTXT ? emailTXT : "None"} </td>
                <td>
                    {personType ? personType.PersonTypeDSC : ""}
                </td>
                <td>
                    <input
                        className="form-check-input"
                        type="checkbox" 
                        disabled={!editable}
                        checked={activeFLG === 1 ? true : false}
                        onChange={() => setActiveFLG(activeFLG === 1 ? 0 : 1)}
                    />
                </td>
                <td>
                    <button className="btn" onClick={() => setEditable(true)} >
                        <img src={editIcon} className="edit" alt="edit"/>
                    </button>
                </td>
            </tr>
        )

    }
}
