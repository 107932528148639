import { getIDToken } from "../../utils/ms-graph-api-call";
const { REACT_APP_API_URL} = process.env;
const headers = {'Content-Type' : 'application/json'};

export default class ActivityTypeApi {
    /**
     * method getActivityTypes
     * @returns a promise of an array of all activity types.
     */
    async getActivityTypes() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/activity-type`, {
            headers: headers
        })
        .then(response => response.json());
    }
}