import React, { useEffect, useState } from 'react';
import { Dropdown, ObjectDropdown } from "../../shared/components";
import { ClassApi, ClassTypesApi, FrequencyApi, PersonApi, SectionsApi, SectionRollApi, SectionScheduleApi, TermsApi } from "../../shared/services";
import AddScheduleModal from "./add-schedule-modal";
import { compareDateStrings } from '../../utils/date-format';

export default function AddSectionForm({setSections}) {
    const [terms, setTerms] = useState([]);
    const [term, setTerm] = useState();
    const [classTypes, setClassTypes] = useState([]);
    const [classType, setClassType] = useState();
    const [allCourses, setAllCourses] = useState([]);
    const [courses, setCourses] = useState([])
    const [course, setCourse] = useState();
    const [staff, setStaff] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState();
    const [frequencies, setFrequencies] = useState([]);
    const [schedule, setSchedule] = useState();
    const [students, setStudents] = useState([]);
    const [student, setStudent] = useState();
    const levels = ["Level 1", "Level 2", "Level 3", "Level 4"];
    const [level, setLevel] = useState();
    const [sectionNM, setSectionNM] = useState("");
    const [displayAlert, setDisplayAlert] = useState(false);
    const [alertMSG, setAlertMSG] = useState("");
    const [alertStyle, setAlertStyle] = useState("");
    const mySectionsApi = new SectionsApi();
    const mySectionRollApi = new SectionRollApi();
    const mySectionSchedule = new SectionScheduleApi();

    useEffect(() => {
        const myPersonApi = new PersonApi();
        myPersonApi.getInstructors()
            .then(staffList => {
                setStaff(staffList);
            });
        myPersonApi.getStudents()
            .then(studentList => {
                setStudents(studentList);
            });

        const myTermsApi = new TermsApi();
        myTermsApi.getTerms()
            .then(termsList => {
                setTerms(termsList.sort((a,b) => {
                    return compareDateStrings(a.EndDTS, b.EndDTS);
                }));
            });

        const myClassApi = new ClassApi();
        myClassApi.getClasses()
            .then(classesList => {
                classesList.sort((a,b) => {
                    return a.ClassNM > b.ClassNM ? 1 : -1;
                })
                setAllCourses(classesList);
                setCourses(classesList);
            });

        const myFrequencyApi = new FrequencyApi();
        myFrequencyApi.getFrequencies()
            .then(frequencyList => setFrequencies(frequencyList));

        const myClassTypesApi = new ClassTypesApi();
        myClassTypesApi.getClassTypes()
            .then(classTypeList => setClassTypes(classTypeList));
    }, []);

    useEffect(() => {
        if(classType) {
            const filteredClasses = allCourses.filter((c) => {
                return c.ClassTypeID === classType.ClassTypeID;
            });
            setCourses(filteredClasses);
        } else {
            setCourses(allCourses);
        }
    }, [classType, allCourses]);

    const handleSubmit = async () => {
        let filledOutFields = false;
        let sectionName;    //Will depend on the class type.

        if(term && course && selectedStaff && schedule) {   //If bare min fields are filled out
            if(classType && classType.ClassTypeID === 2 && student && level) {  //If creating a 1 on 1 and all required fields are filled out
                filledOutFields = true;
                sectionName = `${student.FirstNM} ${student.LastNM}`;   //1 on 1 section name based off of student's name
            }
            if(classType && classType.ClassTypeID !== 2 && sectionNM !== "") {  //If creating a class or club and all required fields are filled out
                filledOutFields = true;
                sectionName = sectionNM;    //Class or club section name based off of whatever user inputs.
            }
        }

        if(filledOutFields) {   //If user filled out fields correctly based on classType, create the new section.
            const newSchedule = await mySectionSchedule.addSectionSchedule(schedule);
            const newSection = await mySectionsApi.addSection({
                SectionNM: sectionName,
                SectionScheduleID: newSchedule.SectionScheduleID,
                SectionStaffID: selectedStaff.PersonID,
                TermID: term.TermID,
                ClassID: course.ClassID
            });
            //Add instructor to section
            mySectionRollApi.addPersonToSection({
                ClassID: course.ClassID,
                SectionID: newSection.SectionID,
                PersonID: selectedStaff.PersonID
            });
            //if 1 on 1, also add student to section
            if(classType && classType.ClassTypeID === 2) {
                mySectionRollApi.addPersonToSection({
                    ClassID: course.ClassID,
                    SectionID: newSection.SectionID,
                    PersonID: student.PersonID
                });
            }

            //Get refreshed list of section data
            const updatedSections = await mySectionsApi.getSections();
            setSections(updatedSections);
            //Set alerts that section was successfully made
            setAlertMSG(`New section ${course.ClassNM} ${sectionName} has been created.`);
            setAlertStyle("alert alert-info alert-dismissible");
            setDisplayAlert(true);
            //Clear out fields
            setTerm(null);
            setClassType(null);
            setCourse(null);    //Course is selected class
            setStudent(null);
            setSectionNM("");
            setSelectedStaff(null);
            setSchedule(null);

        } else {
            setAlertMSG("Please fill out all fields, including the schedule.");
            setAlertStyle("alert alert-warning alert-dismissible");
            setDisplayAlert(true);
        }
    }

    //Used by level dropdown, which requires an onSelect function.
    const onSelect = (key, value) => {
        setLevel(value);
    }

    const levelHeader = classType && classType.ClassTypeID === 2 ? <th>LEVEL</th> : null;
    const studentHeader = classType && classType.ClassTypeID === 2 ? <th>STUDENT</th> : null;

    const levelDropdown = classType && classType.ClassTypeID === 2 ? <td>
        <Dropdown 
            id="level-dropdown"
            items={levels}
            onSelect={onSelect}
            label="Level"
            isBig={false}
        />
    </td> : null;

    const studentDropdown = classType && classType.ClassTypeID === 2 ? <td>
        <ObjectDropdown 
            id="student-dropdown"
            items={students}
            itemNameKey='FirstNM'
            itemNameSecondKey='LastNM'
            selected={student}
            setSelected={setStudent}
            label="Student"
            isBig={false}
        />
    </td> : null;

    const nameHeader = !classType || (classType && classType.ClassTypeID) !== 2 ? <th>SECTION NAME</th> : null;
    const nameForm = !classType || (classType && classType.ClassTypeID) !== 2 ? <td>
        <input className="form-control" value={sectionNM} onChange={({target}) => setSectionNM(target.value)}/>
    </td> : null;

    const alert = displayAlert ? <div className={alertStyle} role="alert">
            {alertMSG}
            <button type="button" className="btn-close" onClick={() => setDisplayAlert(false)} aria-label="Close"></button>
        </div> : null;

    return (
        <>
            {alert}
            <table className="table table-sm table-hover mx-0 mt-2">
                <thead className="table-light">
                    <tr>
                        <th>TERM</th>
                        <th>CLASS TYPE</th>
                        <th>CLASS</th>
                        {nameHeader}
                        {levelHeader}
                        {studentHeader}
                        <th>STAFF</th>
                        <th>SCHEDULE</th>
                        <th>SAVE</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <ObjectDropdown 
                                id="terms-dropdown"
                                items={terms}
                                itemNameKey='TermNM'
                                selected={term}
                                setSelected={setTerm}
                                label="Term"
                                isBig={false}
                            />
                        </td>
                        <td>
                            <ObjectDropdown 
                                id="class-types-dropdown"
                                items={classTypes}
                                itemNameKey="ClassTypeNM"
                                selected={classType}
                                setSelected={setClassType}
                                label="Class Type"
                                isBig={false}
                            />
                        </td>
                        <td>
                            <ObjectDropdown 
                                id="class-dropdown"
                                items={courses}
                                itemNameKey="ClassNM"
                                selected={course}
                                setSelected={setCourse}
                                label="Class"
                                isBig={false}
                            />
                        </td>
                        {nameForm}
                        {levelDropdown}
                        {studentDropdown}
                        <td>
                            <ObjectDropdown 
                                id="staff-dropdown"
                                items={staff}
                                itemNameKey='FirstNM'
                                itemNameSecondKey="LastNM"
                                selected={selectedStaff}
                                setSelected={setSelectedStaff}
                                label="Staff"
                                isBig={false}
                            />
                        </td>
                        <td>
                            <AddScheduleModal 
                                frequencies={frequencies} 
                                schedule={schedule} 
                                setSchedule={setSchedule} 
                                term={term}
                            />
                        </td>
                        <td>
                            <button className="btn btn-primary" onClick={handleSubmit}>
                                Add New Section
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}
