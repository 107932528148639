import { getIDToken } from "../../utils/ms-graph-api-call";
const { REACT_APP_API_URL} = process.env;
const headers = {'Content-Type' : 'application/json'};

export default class ActivityApi {
    /**
     * method getActivities
     * @returns a promise of an array of all activities.
     */
    async getActivities() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/activity`, {
            headers: headers
        })
        .then(response => response.json());
    }

    async addActivityRobust(activityObj) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/activity/activity`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(activityObj)
        }).then(response => response.json());
    }

    /**
     * method addActivity
     * @param {object} activity: an object describing the new activity to add.
     * @returns a promise of the newly created activity object.
     */
    async addActivity(activity) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/activity`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(activity)
        }).then(response => response.json());
    }

    /**
     * method editActivity
     * @param {object} activity: an object describing the values of the activity to edit 
     * @returns a promise of the updated activity object
     */
    async editActivity(activity) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;
        
        return fetch(`${REACT_APP_API_URL}/api/activity`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(activity)
        }).then(response => response.json());
    }

    /**
     * method deleteActivity
     * @param {int} activityID: the id of the activity
     * @returns a promise of the deleted activity
     */
     async deleteActivity(activityID) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;
        
        return fetch(`${REACT_APP_API_URL}/api/activity/${activityID}`, {
            method: 'DELETE',
            headers: headers,
        }).then(response => response.json());
    }
}