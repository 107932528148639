import React, {useEffect, useState} from 'react';
import { ObjectDropdown, ObjectMultiSelect } from "../../shared/components/";
import AddStudentNotes from "./add-student-notes";
import { ActivityApi, ActivityTypeApi, PersonApi } from "../../shared/services";
import { useAuth } from "../../context/auth-context";
import { compareDateStrings, dateAndTimeToDatabase } from "../../utils/date-format";

export default function ActivitiesForm({ activities, setActivities}) {
    const [contactType, setContactType] = useState(null);   //The selected contact/activity type
    const [contactTypes, setContactTypes] = useState([]);   //The options for type of contact/activity
    const [contactName, setContactName] = useState(""); //The activity/contact event name
    const [students, setStudents] = useState([]);   //All possible students to select from
    const [selectedStudents, setSelectedStudents] = useState([]);   //All students that have been selected in multi select
    const [staff, setStaff] = useState([]); //All possible instructors to select from
    const [primaryStaff, setPrimaryStaff] = useState(null); //A single staff member in charge of activity/contact
    const [otherStaff, setOtherStaff] = useState([]);   //All other staff involved with contact selected in multi select
    const [date, setDate] = useState("");
    const [time, setTime] = useState("");
    const [length, setLength] = useState("");
    const [contactNotes, setContactNotes] = useState("");   //Notes to cover entire event
    const [studentNotes, setStudentNotes] = useState([]);   //Notes to cover each student who attended activity/contact
    const [displayAlert, setDisplayAlert] = useState(false); //Should the alert be displayed?
    const [alertMSG, setAlertMSG] = useState("");   //What message the alert should have?
    const [alertStyle, setAlertStyle] = useState("alert alert-info alert-dismissible");   //Should the alert style be warn or info?
    const [buttonDisabled, setButtonDisabled] = useState(false);
    
    const myActivityApi = new ActivityApi();
    const { currentUser } = useAuth();

    useEffect(() => {
        const myPersonApi = new PersonApi();
        myPersonApi.getStudents()
            .then(studentList => {
                if (studentList.length) {
                    setStudents(studentList);
                }
            });
            
        myPersonApi.getInstructors()
            .then(staffList => {
                if (staffList.length) {
                    setStaff(staffList);
                }
            });
        
        const myActivityTypeApi = new ActivityTypeApi();
        myActivityTypeApi.getActivityTypes()
            .then(activityList => {
                if (activityList.length) {
                    setContactTypes(activityList)
                }
            });
    }, []);

    useEffect(() => {
        if(selectedStudents.length > studentNotes.length) {
            const tempNotes = [...studentNotes];
            while(selectedStudents.length > tempNotes.length) {
                tempNotes.push("");
            }
            setStudentNotes(tempNotes);
        }
    }, [selectedStudents, studentNotes, setStudentNotes]);

    const handleSubmit = async () => {
        setButtonDisabled(true);
        setDisplayAlert(false);
        //If all fields are properly filled out
        if(contactType && primaryStaff && date && time && length && selectedStudents.length > 0 && contactName !== "" && contactNotes !== "") {
            const NoteTypeID = 1;   //All notes will have a note type id of 1
            const NoteModifiedByPersonID = currentUser.PersonID;
            const dateTimeStamp = dateAndTimeToDatabase(date, time);
            let notes = contactNotes.replaceAll('"', "'");

            const Activity = {
                ActivityNM: contactName, 
                ActivityTypeID : contactType.ActivityTypeID, 
                ActivityDTS : dateTimeStamp,
                LengthNBR : length,
                ActivityNote : { NoteTypeID, NoteTXT: notes, NoteModifiedByPersonID }
            }
            
            const FullActivityData = {
                CoreActivity : Activity,
                StudentNotes : studentNotes,
                SelectedStudents : selectedStudents,
                PrimaryStaff : primaryStaff,
                OtherStaff : otherStaff,
                CurrentUserID : currentUser.PersonID
            }

            myActivityApi.addActivityRobust(FullActivityData)
                .then(createdActivity => {
                    //If something went wrong, let the user know.
                    if(createdActivity.error) {
                        //Display alert that the contact event was not added correctly.
                        setDisplayAlert(true);
                        setAlertMSG(`The contact was not added correctly. Cause: ${createdActivity.error}`);
                        setAlertStyle("alert alert-danger alert-dismissible");
                    } else {
                        //Final bit of data formatting to go into table
                        createdActivity.ActivityTypeNM = contactType.ActivityTypeNM;
                        createdActivity.ActivityNoteTXT = contactNotes;
                        //Add activity to list in proper order
                        const activitiesCopy = [...activities];
                        activitiesCopy.push(createdActivity);
                        setActivities(activitiesCopy.sort((a, b) => compareDateStrings(a.ActivityDTS, b.ActivityDTS)));
    
                        //Display alert that the contact event was successfully added.
                        setDisplayAlert(true);
                        setAlertMSG("The contact has been added successfully");
                        setAlertStyle("alert alert-info alert-dismissible");
    
                        //Clear out all fields
                        setContactType(null);
                        setContactName("");
                        setSelectedStudents([]);
                        setPrimaryStaff(null);
                        setOtherStaff([]);
                        setDate("");
                        setTime("");
                        setLength("");
                        setContactNotes("");
                        setStudentNotes([]);
                        setButtonDisabled(false);
                    }
                });
        } else {    //if any important values are not filled out
            //give the user an alert notifying them
            setAlertMSG("Please fill out the required fields.");
            setAlertStyle("alert alert-warning alert-dismissible");
            setDisplayAlert(true);
            setButtonDisabled(false);
        }
    }

    const alert = displayAlert ? (
        <div className={alertStyle} role="alert">
            {alertMSG}
            <button type="button" className="btn-close" onClick={() => setDisplayAlert(false)} aria-label="Close"></button>
        </div> )
        : null

    return (
        <>
            <div className="row justify-content-md-center mb-2">
                <div className="col-2"></div>
                <div className="col-8 title">
                    <h4>Add Contact</h4>
                </div>
                <div className="col-2"></div>
            </div>
            <div className="w-auto mx-4">
                <table className="table table-sm table-hover">
                    <thead className="table-light fixed-column-header">
                        <tr>
                            <th>CONTACT TYPE</th>
                            <th>CONTACT NAME</th>
                            <th>STUDENTS</th>
                            <th>PRIMARY STAFF</th>
                            <th>OTHER STAFF</th>
                            <th>DATE</th>
                            <th>TIME</th>
                            <th>LENGTH</th>
                            <th>CONTACT NOTES</th>
                            <th>SUBMIT</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <ObjectDropdown 
                                    items={contactTypes}
                                    itemNameKey="ActivityTypeNM"
                                    label="Type"
                                    selected={contactType}
                                    setSelected={setContactType}
                                    isBig={false}
                                />
                            </td>
                            <td>
                                <input 
                                    className="form-control" 
                                    type="text" 
                                    placeholder="Contact Name" 
                                    value={contactName} 
                                    onChange={({target}) => setContactName(target.value)}
                                />
                            </td>
                            <td>
                                <ObjectMultiSelect 
                                    items={students}
                                    itemPrimaryKey="PersonID"
                                    itemNameKey="FirstNM"
                                    itemNameSecondKey="LastNM"
                                    selected={selectedStudents}
                                    setSelected={setSelectedStudents}
                                    label="Student"
                                    isBig={false}
                                />
                            </td>
                            <td>
                                <ObjectDropdown 
                                    items={staff}
                                    itemNameKey="FirstNM"
                                    itemNameSecondKey="LastNM"
                                    label="Primary Staff"
                                    selected={primaryStaff}
                                    setSelected={setPrimaryStaff}
                                    isBig={false}
                                />
                            </td>
                            <td>
                                <ObjectMultiSelect 
                                    items={staff}
                                    itemPrimaryKey="PersonID"
                                    itemNameKey="FirstNM"
                                    itemNameSecondKey="LastNM"
                                    selected={otherStaff}
                                    setSelected={setOtherStaff}
                                    label="Other Staff"
                                    isBig={false}
                                />
                            </td>
                            <td>
                                <input 
                                    className="form-control" 
                                    type="date"
                                    value={date}
                                    onChange={({target}) => setDate(target.value)}
                                />
                            </td>
                            <td>
                                <input 
                                    className="form-control" 
                                    type="time"
                                    value={time}
                                    onChange={({target}) => setTime(target.value)}
                                />
                            </td>
                            <td>
                                <input 
                                    className="form-control" 
                                    type="number" 
                                    min="0"
                                    value={length}
                                    onChange={({target}) => setLength(target.value)}
                                />
                            </td>
                            <td>
                                <textarea 
                                    className="form-control" 
                                    rows="1" 
                                    value={contactNotes} 
                                    onChange={({target}) => setContactNotes(target.value)}
                                />
                            </td>
                            <td>
                                <button onClick={handleSubmit} className="btn btn-primary" disabled={buttonDisabled}>Submit</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <AddStudentNotes students={selectedStudents} studentNotes={studentNotes} setStudentNotes={setStudentNotes}/>
                {alert}
            </div>
        </>
    )
}
