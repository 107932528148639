import React from 'react';
import UserTableRow from './user-table-row';

export default function UserTable({users, setUsers, personTypes}) {

    const displayUserRows = users.map((user) => {
        return (
            <UserTableRow key={user.PersonID} user={user} personTypes={personTypes} users={users} setUsers={setUsers}/>
        )
    });

    return (
        <div className='table-60'>
            <table className="table table-sm table-hover">
                <thead className="table-light fixed-column-header">
                    <tr>
                        <th>FIRST NAME</th>
                        <th>LAST NAME</th>
                        <th>USER PRINCIPAL NAME</th>
                        <th>PERSON TYPE</th>
                        <th>ACTIVE</th>
                        <th>EDIT</th>
                    </tr>
                </thead>
                <tbody>
                    {displayUserRows}
                </tbody>
            </table>
        </div>
    )
}
