import React, {useEffect, useState} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AttendanceScoresApi } from "../../shared/services";
import { editIcon } from "../../assets/images/images";

export default function AttendanceScoreModal({isAdd, selected, setSelected, attendanceScores, setAttendanceScores}) {
    const [attendanceScoreDSC, setAttendanceScoreDSC] = useState("");
    const [active, setActive] = useState(0);
    const [modal, setModal] = useState(false);
    const [alertMSG, setAlertMSG] = useState("");
    const [displayAlert, setDisplayAlert] = useState(false);
    const [alertStyle, setAlertStyle] = useState("");
    const toggle = () => setModal(!modal);
    const myAttendanceScoresApi = new AttendanceScoresApi();

    useEffect(() => {
        if(!isAdd && selected) {
            setAttendanceScoreDSC(selected.AttendanceScoreDSC);
            setActive(selected.ActiveFLG);
        }
    }, [isAdd, selected]);

    const handleSubmit = async () => {
        setDisplayAlert(false);
        if(attendanceScoreDSC !== "") {
            const newAttendanceScore = await myAttendanceScoresApi.addAttendanceScore({
                AttendanceScoreDSC : attendanceScoreDSC
            });
            let attendanceScoresCopy = [...attendanceScores];
            attendanceScoresCopy.push(newAttendanceScore);
            setAttendanceScores(attendanceScoresCopy);
            setModal(false);
        } else {
            setAlertMSG("Please fill out all fields.");
            setAlertStyle("alert alert-warning alert-dismissible");
            setDisplayAlert(true);
        }
    }

    const handleEdit = async () => {
        setDisplayAlert(false);
        if(attendanceScoreDSC !== "") {
            const editedAttendanceScore = await myAttendanceScoresApi.editAttendanceScore({
                AttendanceScoreID: selected.AttendanceScoreID,
                AttendanceScoreDSC: attendanceScoreDSC,
                ActiveFLG : active
            });
            let attendanceScoresCopy = [...attendanceScores];
            attendanceScoresCopy.forEach((attScore, index) => {
                if(attScore.AttendanceScoreID === editedAttendanceScore.AttendanceScoreID) {
                    attendanceScoresCopy[index] = editedAttendanceScore;
                }
            });
            setAttendanceScores(attendanceScoresCopy);
            setSelected(editedAttendanceScore);
            setModal(false);
        } else {
            setAlertMSG("Please fill out all fields.");
            setAlertStyle("alert alert-warning alert-dismissible");
            setDisplayAlert(true);
        }
    }

    const alert = displayAlert ? (
        <div className={alertStyle} role="alert">
            {alertMSG}
            <button type="button" className="btn-close" onClick={() => setDisplayAlert(false)} aria-label="Close"></button>
        </div> )
        : null

    if(isAdd) {
        return (
            <>
                <button className="btn btn-primary" onClick={toggle}>Add Attendance Option</button>
                <Modal isOpen={modal}  toggle={toggle} fade={false}>
                    <ModalHeader>Add Attendance Option</ModalHeader>
                    <ModalBody>
                        {alert}
                        <label className="form-label">Attendance Option Name</label>
                        <input className="form-control" value={attendanceScoreDSC} onChange={({target}) => setAttendanceScoreDSC(target.value)}/>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-secondary" onClick={() => setModal(false)}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={handleSubmit}>Add Attendance Option</button>
                    </ModalFooter>
                </Modal>
            </>
        )
    } else {
        return (
            <>
                <button className="btn" onClick={toggle} disabled={selected ? false : true}>
                    <img className="edit" src={editIcon} alt="edit"/>
                </button>
                <Modal isOpen={modal}  toggle={toggle} fade={false}>
                    <ModalHeader>Edit Attendance Option</ModalHeader>
                    <ModalBody>
                        {alert}
                        <label className="form-label">Attendance Option Name</label>
                        <input className="form-control" value={attendanceScoreDSC} onChange={({target}) => setAttendanceScoreDSC(target.value)}/>
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" checked={active === 1 ? true : false} onChange={() => setActive(active === 1 ? 0 : 1)}/>
                            <label className="form-check-label">Active</label>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-secondary" onClick={() => setModal(false)}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={handleEdit}>Edit Attendance Option</button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}
