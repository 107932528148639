import React, { useState, useEffect } from 'react';
import { editIcon, saveIcon } from "../../assets/images/images";
import { arrayToStringList } from "../../utils/string-format";
import { ObjectMultiSelect } from "../../shared/components";


export default function ServiceCoordinatorTableRow({record, students, onEdit}) {
    const [editable, setEditable] = useState(false);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const {ServiceCoordinatorNM, ServiceCoordinatorPersonID, ListOfStudentsID} = record

    useEffect(() => {
        if (ListOfStudentsID && ListOfStudentsID !== "") {
            const arr = ListOfStudentsID.split(',');
            const newSelectedStudents = students.filter(s => arr.includes(s.PersonID.toString()))
            setSelectedStudents(newSelectedStudents);
        }
    }, [students, ListOfStudentsID])
    
    
    // const selectedStudentNames = selectedStudents.map((student, index) => `${student.FirstNM} ${student.LastNM}`).sort();

    const handleEdit = async () => {
        const updatedIDs = selectedStudents.map(s => s.PersonID);
        await onEdit(ServiceCoordinatorPersonID, updatedIDs);
        setEditable(false);
    }

    if(editable) {
        return (
            <tr>
                <td>{record.ServiceCoordinatorFirstNM} {record.ServiceCoordinatorLastNM}</td>
                <td>
                    <div className="w-25 mx-auto">
                        <ObjectMultiSelect 
                            items={students}
                            itemPrimaryKey="PersonID"
                            itemNameKey="FirstNM"
                            itemNameSecondKey="LastNM"
                            selected={selectedStudents}
                            setSelected={setSelectedStudents}
                            label="Student"
                            isBig={false}
                        />
                    </div>
                </td>
                <td>
                    <button className="btn" onClick={handleEdit}>
                        <img className="edit" alt="save" src={saveIcon}/>
                    </button>
                </td>
            </tr>
        )
    } else {
        return (
            <tr>
                <td>{ServiceCoordinatorNM}</td>
                <td>{arrayToStringList(selectedStudents.map(st => st.FirstNM + " " + st.LastNM))}</td>
                <td>
                    <button className="btn" onClick={() => setEditable(true)}>
                        <img className="edit" alt="edit" src={editIcon}/>
                    </button>
                </td>
            </tr>
        )
    }
}
