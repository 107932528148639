import React from 'react';

export default function SearchBar({onChange, placeholder}) {
    return (
        <input
            className="form-control"
            type="text"
            onChange={onChange}
            placeholder={placeholder}
        />
    );
}
