import React, {useEffect, useState} from 'react';
import { SectionsApi } from "../../shared/services";
import SectionTableFilters from "./section-table-filters";
import SectionsTable from "./sections-table";
import AddSectionForm from "./add-section-form";
import { compareDateStrings } from '../../utils/date-format';

const mySectionsApi = new SectionsApi();

export default function AdminSectionView() {
    const [sections, setSections] = useState([]);
    const [filteredSections, setFilteredSections] = useState([]);

    const getSections = () => {
        return mySectionsApi.getSections()
            .then(sectionList => {
                sectionList.sort((a,b) => {
                    return compareDateStrings(a.EndDTS, b.EndDTS);
                })
                setSections(sectionList);
                setFilteredSections(sectionList);
            });
    }

    useEffect(() => {
        getSections();
    }, []);

    return (
        <div>
            <h5 className="title">Add a Section</h5>
            <AddSectionForm setSections={setSections}/>
            <h5 className="title">Current Sections</h5>
            <SectionTableFilters sections={sections} setFilteredSections={setFilteredSections}/>
            <SectionsTable filteredSections={filteredSections} sections={sections} setSections={getSections}/>
        </div>
    )
}
