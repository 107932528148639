import React, {useEffect, useState} from 'react';
import StudentNoteItem from "./student-note-item";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export default function ActivityStudentNotes({selectedActivity, studentNotes, setStudentNotes }) {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    useEffect(() => {
        if(selectedActivity) {
            setStudentNotes(selectedActivity.StudentNotes);
        }
    }, [selectedActivity, setStudentNotes]);

    const displayStudentNotes = studentNotes.map((noteObj, index) => {
        return (
            <StudentNoteItem key={index} noteObj={noteObj} studentNotes={studentNotes} setStudentNotes={setStudentNotes}/>
        )
    });
    return (
        <>
            <button className="btn btn-primary" onClick={toggle}>View</button>
            <Modal isOpen={modal} toggle={toggle} fade={false}>
                <ModalHeader>
                    Student Notes
                </ModalHeader>
                <ModalBody>
                    {displayStudentNotes}
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-secondary" onClick={() => setModal(false)}>Close</button>
                </ModalFooter>
            </Modal>
        </>
    )
}
