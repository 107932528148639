/**
 * function arrayToStringList
 * @param {array} arr: an array of strings.
 * @returns a single string containing all of the array strings listed out, comma separated. 
 */
export const arrayToStringList = (arr) => {
    let stringList = arr.join(", ").replace(/,(?=[^,]*$)/, ' and');
    return stringList;
}

export const arrayToStringListNoDuplicates = (arr) => {
    let stringList = arr.filter(function(item, pos, self) {
        return self.indexOf(item) === pos;
    }).join(", ").replace(/,(?=[^,]*$)/, ' and');
    return stringList;
}

export const arrayToStringListNoAnd = (arr) => {
    let stringList = arr.join(`, `);
    return stringList;
}

export const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(String(email).toLowerCase());
}