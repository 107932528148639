import React, {useState} from 'react';
import { editIcon, saveIcon } from "../../assets/images/images";
import { TermsApi } from "../../shared/services";
import {databaseToDatePicker, pickerToTableFormat, pickerToDatabaseFormat} from "../../utils/date-format.js";


export default function TermTableRow({ term }) {
    const [editable, setEditable] = useState(false);
    const [startDate, setStartDate] = useState(databaseToDatePicker(term.StartDTS));  //Keeps track of selected start date for the term
    const [endDate, setEndDate] = useState(databaseToDatePicker(term.EndDTS));  //Keeps track of selected end date for the term

    const saveEdit = async () => {
        //Import service class to access terms backend
        const myTermsApi = new TermsApi();

        //Edit the term provided
        const editedTerm = await myTermsApi.editTerm({
            TermNM: term.TermNM,
            StartDTS: pickerToDatabaseFormat(startDate), 
            EndDTS: pickerToDatabaseFormat(endDate),
            TermID: term.TermID
        });
        term = editedTerm;
        setEditable(false);
    }

    // Creating the edit button
    const editButton = editable ? 
        <button className="btn" onClick={saveEdit}>
            <img className="edit" alt="edit" src={saveIcon}/>
        </button> : 
        <button className="btn" onClick={()  => setEditable(true)}>
            <img className="edit" alt="edit" src={editIcon}/>
        </button>

    
    if(editable) {
        return (
            <tr>
                <td>{term.TermNM}</td>
                <td>
                    <input id="start-date-picker" type="date" className="form-control" value={startDate} onChange={({target}) => setStartDate(target.value)}/>
                </td>
                <td>
                    <input id="end-date-picker"  type="date" className="form-control" value={endDate} onChange={({target}) => setEndDate(target.value)}/>
                </td>
                <td>{editButton}</td>
            </tr>
        )
    } else {
        return (
            <tr>
                <td>{term.TermNM}</td>
                <td>{pickerToTableFormat(startDate)}</td>
                <td>{pickerToTableFormat(endDate)}</td>
                <td>{editButton}</td>
            </tr>
        )
    }
}
