import React, { useEffect, useState } from 'react';
import { ObjectDropdown } from "../../shared/components";
import AttendanceScoreModal from "./attendance-score-modal";
import BehaviorScoreModal from "./behavior-score-modal";
import EngagementScoreModal from "./engagement-score-modal";
import InstructionNoteModal from "./instruction-note-modal";
import OutcomeModal from "./outcome-modal";
import { AttendanceScoresApi, BehaviorScoresApi, EngagementScoresApi, NoteTypeApi, OutcomeApi} from "../../shared/services";

export default function ManageTitlesTable() {
    const [attendanceScores, setAttendanceScores] = useState([]);
    const [attendanceScore, setAttendanceScore] = useState();
    const [behaviorScores, setBehaviorScores] = useState([]);
    const [behaviorScore, setBehaviorScore] = useState()
    const [engagementScores, setEngagementScores] = useState([]);
    const [engagementScore, setEngagementScore] = useState();
    const [instructionNoteTypes, setInstructionNoteTypes] = useState([]);
    const [instructionNoteType, setInstructionNoteType] = useState();
    const [outcomes, setOutcomes] = useState([]);
    const [outcome, setOutcome] = useState();

    useEffect(() => {
        const myAttendanceScoresApi = new AttendanceScoresApi();
        myAttendanceScoresApi.getAttendanceScores()
        .then(attendanceScoresList => setAttendanceScores(attendanceScoresList));
        const myBehaviorScoresApi = new BehaviorScoresApi();
        myBehaviorScoresApi.getBehaviorScores()
        .then(behaviorScoresList => setBehaviorScores(behaviorScoresList));
        const myEngagementScoresApi = new EngagementScoresApi();
        myEngagementScoresApi.getEngagementScores()
        .then(engagementScoresList => setEngagementScores(engagementScoresList));
        const myNoteTypeApi = new NoteTypeApi();
        myNoteTypeApi.getNoteTypes()
        .then(noteTypeList => {
            const attendanceNotes = noteTypeList.filter((noteType, index ) => noteType.InstructionNoteFLG === 1);
            setInstructionNoteTypes(attendanceNotes);
        });
        const myOutcomeApi = new OutcomeApi();
        myOutcomeApi.getOutcomes()
        .then(outcomeList => setOutcomes(outcomeList));
    }, []);

    return (
        <table className="table table-sm table-hover mx-2">
            <thead className="table-light">
                <tr>
                    <th>TITLES</th>
                    <th className="col-sm-2">CURRENT OPTIONS</th>
                    <th>ADD OPTION</th>
                    <th>EDIT SELECTED</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Instruction</td>
                    <td>
                        <ObjectDropdown 
                            items={instructionNoteTypes}
                            itemNameKey="NoteTypeNM"
                            selected={instructionNoteType}
                            setSelected={setInstructionNoteType}
                            label="Instruction"
                            isBig={false}
                        />
                    </td>
                    <td>
                        <InstructionNoteModal isAdd={true} noteTypes={instructionNoteTypes} setNoteTypes={setInstructionNoteTypes} />
                    </td>
                    <td>
                        <InstructionNoteModal 
                            isAdd={false} 
                            selected={instructionNoteType} 
                            setSelected={setInstructionNoteType}
                            noteTypes={instructionNoteTypes} 
                            setNoteTypes={setInstructionNoteTypes} 
                        />
                    </td>
                </tr>
                <tr>
                    <td>Attendance</td>
                    <td>
                        <ObjectDropdown 
                            items={attendanceScores}
                            itemNameKey="AttendanceScoreDSC"
                            selected={attendanceScore}
                            setSelected={setAttendanceScore}
                            label="Attendance"
                            isBig={false}
                        />
                    </td>
                    <td>
                        <AttendanceScoreModal isAdd={true} attendanceScores={attendanceScores} setAttendanceScores={setAttendanceScores}/>
                    </td>
                    <td>
                        <AttendanceScoreModal 
                            isAdd={false} 
                            selected={attendanceScore} 
                            setSelected={setAttendanceScore} 
                            attendanceScores={attendanceScores} 
                            setAttendanceScores={setAttendanceScores}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Engagement</td>
                    <td>
                        <ObjectDropdown 
                            items={engagementScores}
                            itemNameKey="EngagementScoreDSC"
                            selected={engagementScore}
                            setSelected={setEngagementScore}
                            label="Engagement"
                            isBig={false}
                        />
                    </td>
                    <td>
                        <EngagementScoreModal isAdd={true} engagementScores={engagementScores} setEngagementScores={setEngagementScores}/>
                    </td>
                    <td>
                        <EngagementScoreModal 
                            isAdd={false} 
                            selected={engagementScore} 
                            setSelected={setEngagementScore}
                            engagementScores={engagementScores} 
                            setEngagementScores={setEngagementScores}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Behavior</td>
                    <td>
                        <ObjectDropdown 
                            items={behaviorScores}
                            itemNameKey="BehaviorScoreDSC"
                            selected={behaviorScore}
                            setSelected={setBehaviorScore}
                            label="Behavior"
                            isBig={false}
                        />
                    </td>
                    <td>
                        <BehaviorScoreModal isAdd={true} behaviorScores={behaviorScores} setBehaviorScores={setBehaviorScores}/>
                    </td>
                    <td>
                        <BehaviorScoreModal 
                            isAdd={false} 
                            selected={behaviorScore}
                            setSelected={setBehaviorScore}
                            behaviorScores={behaviorScores}
                            setBehaviorScores={setBehaviorScores}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Outcomes</td>
                    <td>
                        <ObjectDropdown 
                            items={outcomes}
                            itemNameKey="OutcomeNM"
                            selected={outcome}
                            setSelected={setOutcome}
                            label="Outcomes"
                            isBig={false}
                        />
                    </td>
                    <td>
                        <OutcomeModal isAdd={true} outcomes={outcomes} setOutcomes={setOutcomes}/>
                    </td>
                    <td>
                        <OutcomeModal 
                            isAdd={false} 
                            selected={outcome} 
                            setSelected={setOutcome} 
                            outcomes={outcomes} 
                            setOutcomes={setOutcomes}
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    )
}
