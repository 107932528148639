import React, {useEffect, useState} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { BehaviorScoresApi } from "../../shared/services";
import { editIcon } from "../../assets/images/images";


export default function BehaviorScoreModal({isAdd, selected, setSelected, behaviorScores, setBehaviorScores}) {
    const [behaviorScoreDSC, setBehaviorScoreDSC] = useState("");
    const [active, setActive] = useState(0);
    const [modal, setModal] = useState(false);
    const [alertMSG, setAlertMSG] = useState("");
    const [displayAlert, setDisplayAlert] = useState(false);
    const [alertStyle, setAlertStyle] = useState("");
    const toggle = () => setModal(!modal);
    const myBehaviorScoresAPI = new BehaviorScoresApi();

    useEffect(() => {
        if(!isAdd && selected) {
            setBehaviorScoreDSC(selected.BehaviorScoreDSC);
            setActive(selected.ActiveFLG);
        }
    }, [isAdd, selected]);

    const handleSubmit = async () => {
        setDisplayAlert(false);
        if(behaviorScoreDSC !== "") {
            const newBehaviorScore = await myBehaviorScoresAPI.addBehaviorScore({
                BehaviorScoreDSC: behaviorScoreDSC
            });
            let behaviorScoresCopy = [...behaviorScores];
            behaviorScoresCopy.push(newBehaviorScore);
            setBehaviorScores(behaviorScoresCopy);
            setModal(false);
        } else {
            setAlertMSG("Please fill out all fields.");
            setAlertStyle("alert alert-warning alert-dismissible");
            setDisplayAlert(true);
        }
    }

    const handleEdit = async () => {
        setDisplayAlert(false);
        if(behaviorScoreDSC !== "") {
            const editedBehaviorScore = await myBehaviorScoresAPI.editBehaviorScore({
                BehaviorScoreID: selected.BehaviorScoreID,
                BehaviorScoreDSC: behaviorScoreDSC,
                ActiveFLG : active
            });
            let behaviorScoresCopy = [...behaviorScores];
            behaviorScoresCopy.forEach((behavior, index) => {
                if(behavior.BehaviorScoreID === editedBehaviorScore.BehaviorScoreID) {
                    behaviorScoresCopy[index] = editedBehaviorScore
                }
            });
            setBehaviorScores(behaviorScoresCopy);
            setSelected(editedBehaviorScore);
            setModal(false);
        } else {
            setAlertMSG("Please fill out all fields.");
            setAlertStyle("alert alert-warning alert-dismissible");
            setDisplayAlert(true);
        }
    }

    const alert = displayAlert ? (
        <div className={alertStyle} role="alert">
            {alertMSG}
            <button type="button" className="btn-close" onClick={() => setDisplayAlert(false)} aria-label="Close"></button>
        </div> )
        : null

    if(isAdd) {
        return (
            <>
                <button className="btn btn-primary" onClick={toggle}>Add Behavior Score</button>
                <Modal isOpen={modal}  toggle={toggle} fade={false}>
                    <ModalHeader>Add Behavior Score</ModalHeader>
                    <ModalBody>
                        {alert}
                        <label className="form-label">Behavior Score Name</label>
                        <input className="form-control" value={behaviorScoreDSC} onChange={({target}) => setBehaviorScoreDSC(target.value)}/>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-secondary" onClick={() => setModal(false)}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={handleSubmit}>Add Behavior Score</button>
                    </ModalFooter>
                </Modal>
            </>
        )
    } else {
        return (
            <>
                <button className="btn" onClick={toggle} disabled={selected ? false : true}>
                    <img className="edit" src={editIcon} alt="edit"/>
                </button>
                <Modal isOpen={modal}  toggle={toggle} fade={false}>
                    <ModalHeader>Edit Behavior Score</ModalHeader>
                    <ModalBody>
                        {alert}
                        <label className="form-label">Behavior Score Name</label>
                        <input className="form-control" value={behaviorScoreDSC} onChange={({target}) => setBehaviorScoreDSC(target.value)}/>
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" checked={active === 1 ? true : false} onChange={() => setActive(active === 1 ? 0 : 1)}/>
                            <label className="form-check-label">Active</label>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-secondary" onClick={() => setModal(false)}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={handleEdit}>Edit Behavior Score</button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}
