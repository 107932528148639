import { getIDToken } from "../../utils/ms-graph-api-call";
const { REACT_APP_API_URL} = process.env;
const headers = {'Content-Type' : 'application/json'};

export default class ClassApi {
    /**
     * method getClasses
     * @returns a promise of an array of all classes
     */
    async getClasses() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/classes`,{
            headers: headers
        })
        .then(response => response.json());
    }

    /**
     * method getOneOnOnes
     * @returns a promise of an array of all classes that are type 1 on 1
     */
    async getOneOnOnes() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/classes/one-on-one`, {
            headers: headers
        })
        .then(response => response.json());
    }

    /**
     * method addClass
     * @param {object} classObject: includes the following keys- ClassNM, ProgramID, 
     * OutcomeID, ActiveFLG, RequireProgressFLG, ClassTypeID
     * @returns a promise of the newly created classObject
     */
    async addClass(classObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/classes`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(classObject)
        }).then(response => response.json());
    }

    /**
     * method addClass
     * @param {object} classObject: please include the following keys regardless
     * of whether that value will be changed- ClassID (of the class to edit), 
     * ClassNM, ProgramID, OutcomeID, ActiveFLG, RequireProgressFLG, ClassTypeID
     * @returns a promise of the recently updated classObject
     */
    async editClass(classObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;
        
        return fetch(`${REACT_APP_API_URL}/api/classes`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(classObject)
        }).then(response => response.json());
    }
}