import React from 'react';

export default function UnassignedStudents({unassignedStudents}) {
    const studentList = unassignedStudents.map((student, index) => {
        return (
            <li key={index} className="list-group-item">{student.StudentNM}</li>
        )
    });
    return (
        <ul className="list-group mx-2">
            <li className="list-group-item">
                <h6 className="title">Students Without a Service Coordinator</h6>
            </li>
            {studentList}
        </ul>
    )
}
