import { getIDToken } from "../../utils/ms-graph-api-call";
const { REACT_APP_API_URL} = process.env;
const headers = { 'Content-Type' : 'application/json' };
export default class OutcomeApi {
    /**
     * class method getOutcomes
     * @returns a promise of an array of all outcomes
     */
    async getOutcomes() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/outcome`, {
            headers: headers
        }).then(response => response.json());
    }

    /**
     * method addOutcome
     * @param {object} outcomeObject: includes the following values- OutcomeDSC, OutcomeNM, ProgramID
     * @returns the newly created outcome
     */
    async addOutcome(outcomeObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/outcome`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(outcomeObject)
        }).then(response => response.json());
    }

    /**
     * method editOutcome
     * @param {object} outcomeObject: includes the following values- OutcomeID, OutcomeDSC, OutcomeNM, ProgramID, ActiveFLG
     * @returns the edited outcome
     */
    async editOutcome(outcomeObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;
        
        return fetch(`${REACT_APP_API_URL}/api/outcome`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(outcomeObject)
        }).then(response => response.json());
    }
    
}