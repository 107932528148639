import React, { useEffect, useState } from 'react';
import { AttendanceApi, DashboardApi } from "../../shared/services";
import StudentMapRow from "./student-map-row";
import { compareDateStrings, dateIsBetween } from '../../utils/date-format';
import CsvDownloader from 'react-csv-downloader';
import "./dashboard.css";

export default function StudentMapTable({ selectedStudents, selectedTerms, selectedPrograms, selectedStaff, selectedClasses, startDate, endDate, tableLoading, setTableLoading, reviewedAsc }) {
    const [studentMapData, setStudentMapData] = useState([]);
    const [editable, setEditable] = useState(false);
    const [noRows, setNoRows] = useState(false);

    const myAttendanceApi = new AttendanceApi();

    useEffect(() => {
        setTableLoading(true);
        setNoRows(false);
        const TermIDs = selectedTerms ? selectedTerms.map(term => term.TermID) : null;
        const PersonIDs = selectedStudents ? selectedStudents.map(student => student.PersonID) : null;
        const StaffPersonIDs = selectedStaff ? selectedStaff.map(staff => staff.PersonID) : null;
        const ProgramIDs = selectedPrograms ? selectedPrograms.map(program => program.ProgramID) : null;
        const ClassIDs = selectedClasses ? selectedClasses.map(classItem => classItem.ClassID) : null;

        const myDashboardApi = new DashboardApi();

        myDashboardApi.getStudentMapData(TermIDs, PersonIDs, ProgramIDs, ClassIDs, StaffPersonIDs)
            .then(mapData => {
                if (startDate && endDate) {
                    mapData = mapData.filter((item) => {
                        return dateIsBetween(new Date(item.AttendanceDTS), new Date(startDate), new Date(endDate))
                    });
                }
                mapData.sort((a, b) => {
                    return compareDateStrings(a.AttendanceDTS, b.AttendanceDTS);
                });

                setTableLoading(false);
                setNoRows(mapData.length === 0);
                setStudentMapData(mapData);
            });
    }, [selectedStudents, selectedTerms, selectedStaff, selectedPrograms, selectedClasses, startDate, endDate, setTableLoading]);

    const filterReview = function(x) {
        if (reviewedAsc.reviewFilter === "not reviewed") {
            return (x.ReviewedFLG === 0) ? x : null
        } else if (reviewedAsc.reviewFilter === "reviewed") {
            return (x.ReviewedFLG === 1) ? x : null
        }
        return x;
    };

    const handleSave = () => {
        const reviewedRecords = studentMapData.map(r => ({AttendanceID: r.AttendanceID, ReviewedFLG: r.ReviewedFLG}))
        myAttendanceApi.editReviewed(reviewedRecords)
        setEditable(false);
    }

    const setMapRecords = (cr) => {
        setStudentMapData(
            studentMapData.map(crs =>
                crs.AttendanceID === cr.AttendanceID ? {...cr} : crs
        ))
    }

    const displayRows = studentMapData.filter(filterReview).map((mapRecord) => {
        return (
            <StudentMapRow key={mapRecord.AttendanceID} mapRecord={mapRecord} setMapRecords={setMapRecords} handleSave={handleSave} editable={editable} setEditable={setEditable} />
        );
    });

    const CSVheaders = [
        { displayName: "Student", id: "StudentNM" },
        { displayName: "Date", id: "AttendanceDTS" },
        { displayName: "Class", id: "ClassNM" },
        { displayName: "Attendance", id: "AttendanceScoreDSC" },
        { displayName: "Behavior", id: "BehaviorScoreDSC" },
        { displayName: "Engagement", id: "EngagementScoreDSC" },
        { displayName: "Attendance Notes", id: "AttendanceNotes" },
        { displayName: "Progress Notes", id: "ProgressNotes" },
        { displayName: "Instruction Notes", id: "ListOfNoteTXT" },
        { displayName: "Documented By", id: "CreatedBy"}
    ];

    return (
        <>
            <div className="download-container mx-1">
                <CsvDownloader columns={CSVheaders} datas={studentMapData} filename="student_map" separator="," wrapColumnChar='"' bom={false} disabled={studentMapData === []}>
                    <button className="btn btn-primary" disabled={studentMapData === []}>Download Table Data</button>
                </CsvDownloader>
            </div>

            <div className='table-60'>
                <table className="table table-sm table-hover mx-0 mt-2">
                    <thead className="table-light fixed-column-header">
                        <tr>
                            <th>STUDENT</th>
                            <th>DOCUMENTED BY</th>
                            <th>DATE</th>
                            <th>CLASS</th>
                            <th>ATTENDANCE</th>
                            <th>BEHAVIOR</th>
                            <th>ENGAGEMENT</th>
                            <th>ATTENDANCE & BEHAVIOR NOTES</th>
                            <th>ENGAGEMENT & PROGRESS NOTES</th>
                            <th>DIRECT INSTRUCTION NOTES</th>
                            <th>REVIEWED</th>
                            <th>EDIT</th>
                        </tr>
                    </thead>
                    {tableLoading ? (<tbody />) : (
                        <tbody>
                            {displayRows}
                        </tbody>
                    )}
                </table>
                {tableLoading ? (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                        </div>
                    </div>
                ) : ""}
                {(noRows) ? (
                    <div className="d-flex justify-content-center">
                        No results found.
                    </div>) : null}
            </div>
        </>
    );
}
