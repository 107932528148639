import React from 'react';
import InstructorAttendanceRow from "./instructor-attendance-row";
import InstructionNotesRow from "./instruction-notes-row";

/**
 * React function component InstructorAttendance
 * @param attendanceData: a state variable to store all form data filled out for instructor attendance
 * @param setAttendanceData: a state hook function to update attendanceData
 * @param instructors: an array of all instructors assigned to this section,usually just one.
 * @param date: the date the section attendance took place. A state string representing the selected date.
 * @returns a table of all instructors with fields to take attendance in.
 */
export default function InstructorAttendance({ attendanceData, setAttendanceData, instructors, date, noteData, setNoteData }) {
    const attendanceScores = [
        {
            AttendanceScoreID: 1,
            AttendanceScoreDSC: "Present",
            ActiveFLG: 1
        },
        {
            AttendanceScoreID: 6,
            AttendanceScoreDSC: "Absent",
            ActiveFLG: 1
        },
        {
            AttendanceScoreID: 7, 
            AttendanceScoreDSC: "No classes held",
            ActiveFLG: 1
        },
    ]

    const instructorTableRows = instructors.map((instructor, index) => {
        return (
            <InstructorAttendanceRow
                key={index}
                index={index}
                instructor={instructor}
                attendanceData={attendanceData}
                setAttendanceData={setAttendanceData}
                attendanceScores={attendanceScores} date={date} />
        )
    });

    const tableRows = noteData.map((note, index) => {
        return (
            <InstructionNotesRow
                key={index}
                noteData={[note, index]}
                setNoteData={setNoteData}
                date={date}
            />
        )
    });

    return (
        <div id="teacher-attendance" role="tabpanel" aria-labelledby="instructor-attendance-tab">
            <div className='table-40 mt-2'>
                <div>
                    <table className="table table-sm table-hover">
                        <thead className="table-light fixed-column-header">
                            <tr>
                                <th scope="col table-header">DATE</th>
                                <th scope="col table-header">INSTRUCTOR</th>
                                <th scope="col table-header">ATTENDANCE</th>
                                <th scope="col table-header">ATTENDANCE NOTES</th>
                            </tr>
                        </thead>
                        <tbody>
                            {instructorTableRows}
                        </tbody>
                    </table>
                </div>
                <div className='mt-3'>
                    <table className="table table-sm table-hover">
                        <thead className="table-light fixed-column-header">
                            <tr>
                                <th scope="col table-header">DATE</th>
                                <th scope="col table-header">INSTRUCTION TYPE</th>
                                <th scope="col table-header">NOTES</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableRows}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
