import React, {useState, useEffect} from 'react';
import './drop-down.css';    //CSS critical for dropdown's functionality

/**
 * React functional component Dropdown
 * @param id: The unique dropdown identifier. 
 * @param items: An array of string options in the dropdown.
 * @param onSelect: A function that handles when a new option is selected. Allows management of multiple dropdowns.
 * @param label: a string that displays on the dropdown.
 * @param isBig: a boolean describing whether the styling of the dropdown is big or not
 * @param initialValue: an initial value to pre-select
 * @returns a dropdown component
 */
export default function Dropdown({ id, items, onSelect, label, isBig, initialValue}) {
    const [selected, setSelected] = useState(initialValue ? initialValue : null);

    useEffect(() => {
        setSelected(initialValue);
    }, [initialValue])

    const handleSelect = (id, value) => {
        setSelected(value);
        onSelect(id, value);
    }
    //Generates all dropdown items
    const dropdownItems = items.map((item, index) => {
        return (
            <button type="button" key={index} value={item} className="dropdown-item" 
                onClick={(event) => handleSelect(id, event.target.value)}
            >
                {item}
            </button>
        )
    });

    const displaySelected = selected ? selected : label;

    const style = isBig ? "btn btn-outline-dark dropdown-toggle" 
        : "btn btn-outline-dark dropdown-toggle btn-sm";

    return (
        <div className="dropdown">
            <button className={ style }
                type="button" id="dropdownMenuButton" data-toggle="dropdown" 
                aria-haspopup="true" aria-expanded="false"
            >
                {displaySelected}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {dropdownItems}
            </div>
        </div>
    )
}
