import React, {useEffect, useState} from 'react';
import { ObjectDropdown, ObjectMultiSelect } from "../../shared/components";
import { PersonApi, ProgramApi } from "../../shared/services";

export default function StudentMapFilter(props) {
    const [staff, setStaff] = useState([])
    const {
        selectedStudents,
        setSelectedStudents,
        selectedStaff,
        setSelectedStaff,
        selectedTerms,
        setSelectedTerms,
        terms,
        selectedPrograms,
        setSelectedPrograms,
        selectedClasses,
        setSelectedClasses,
        classes,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        tableLoading,
        reviewedAscending,
        setReviewedAscending
    } = props;
    const [students, setStudents] = useState([]);
    const [programs, setPrograms] = useState([]);

    useEffect(() => {
        const myPersonApi = new PersonApi();
        myPersonApi.getStudents()
            .then(studentList => {
                setStudents(studentList);
            });
        
        myPersonApi.getAllNonStudents()
            .then(staffList => {
                setStaff(staffList);
            })

        const myProgramApi = new ProgramApi();
        myProgramApi.getPrograms()
            .then(programList => setPrograms(programList));
    }, []);

    return (
        <div className="row">
            <div className="col">
                <ObjectMultiSelect
                    items={students}
                    itemPrimaryKey="PersonID"
                    itemNameKey="FirstNM"
                    itemNameSecondKey="LastNM"
                    selected={selectedStudents}
                    setSelected={setSelectedStudents}
                    label="Student"
                    isBig={true}
                    disabled={tableLoading}
                />
            </div>
            <div className="col">
                <ObjectMultiSelect
                    items={staff}
                    itemPrimaryKey="PersonID"
                    itemNameKey="FirstNM"
                    itemNameSecondKey="LastNM"
                    selected={selectedStaff}
                    setSelected={setSelectedStaff}
                    label="Staff"
                    isBig={true}
                    disabled={tableLoading}
                />
            </div>
            <div className="col">
                <ObjectMultiSelect
                    items={terms}
                    itemPrimaryKey="TermID"
                    itemNameKey="TermNM"
                    selected={selectedTerms}
                    setSelected={setSelectedTerms}
                    label="Term"
                    isBig={true}
                    disabled={tableLoading}
                />
            </div>
            <div className="col">
                <ObjectMultiSelect
                    items={programs}
                    itemPrimaryKey="ProgramID"
                    itemNameKey="ProgramNM"
                    selected={selectedPrograms}
                    setSelected={setSelectedPrograms}
                    label="Program"
                    isBig={true}
                    disabled={tableLoading}
                />
            </div>
            <div className="col">
                <ObjectMultiSelect
                    items={classes}
                    itemPrimaryKey="ClassID"
                    itemNameKey="ClassNM"
                    selected={selectedClasses}
                    setSelected={setSelectedClasses}
                    label="Class"
                    isBig={true}
                    disabled={tableLoading}
                />
            </div>
            <div className="col">
                <ObjectDropdown
                    label=""
                    items={[{label: "Any Review", reviewFilter: "any"}, {label: "Reviewed", reviewFilter: "reviewed"}, {label: "Not Reviewed", reviewFilter: "not reviewed"}]}
                    itemNameKey="label"
                    dataKey="reviewFilter"
                    setSelected={setReviewedAscending}
                    selected={reviewedAscending}
                    displayWithLabel
                />
            </div>
            <div className="col">
                <label className="form-label">Start Date</label>
                <input
                    className="form-control"
                    type="date"
                    value={startDate}
                    onChange={({target}) => setStartDate(target.value)}
                    disabled={tableLoading}
                />
            </div>
            <div className="col">
                <label className="form-label">End Date</label>
                <input
                    className="form-control"
                    type="date"
                    value={endDate}
                    onChange={({target}) => setEndDate(target.value)}
                    disabled={tableLoading}
                />
            </div>
        </div>
    )
}
