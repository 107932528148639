import React, {useEffect, useState} from 'react';
import { ActivityTypeApi, PersonApi } from "../../shared/services";
import StudentContactTable from './student-contact-table';
import StudentContactFilter from './student-contact-filter';

export default function StudentContactMap() {
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [students, setStudents] = useState([]);
    const [selectedPrimaryStaff, setSelectedPrimaryStaff] = useState([]);
    const [selectedContactTypes, setSelectedContactType] = useState([]);
    const [staff, setStaff] = useState([]);
    const [contactTypes, setContactTypes] = useState([])
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [reviewedAscending, setReviewedAscending] = useState({label:"Any Review", reviewFilter: "any"});
    const [searchText, setSearchText] = useState(null);

    useEffect(() => {
        const myPersonApi = new PersonApi();
        const myActivityTypeApi = new ActivityTypeApi();
        myPersonApi.getStudents()
            .then(studentList => {
                setStudents(studentList);
            });

        myPersonApi.getAllNonStudents()
            .then(staffList => {
                setStaff(staffList);
            })
        myActivityTypeApi.getActivityTypes()
            .then(activityTypes => {
                console.log(activityTypes)
                setContactTypes(activityTypes);
            })
    }, []);
    return (
        <div>
            <StudentContactFilter
                staff={staff}
                students={students}
                selectedStudents={selectedStudents}
                selectedPrimaryStaff={selectedPrimaryStaff}
                selectedContactTypes={selectedContactTypes}
                setSelectedStudents={setSelectedStudents}
                setSelectedPrimaryStaff={setSelectedPrimaryStaff}
                setSelectedContactTypes={setSelectedContactType}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                reviewedAscending={reviewedAscending}
                setReviewedAscending={setReviewedAscending}
                setSearchText={setSearchText}
                contactTypes={contactTypes}
            />
            <StudentContactTable
                selectedStudents={selectedStudents}
                selectedPrimaryStaff={selectedPrimaryStaff}
                selectedContactTypes={selectedContactTypes}
                startDate={startDate}
                endDate={endDate}
                reviewedAscending={reviewedAscending}
                searchText={searchText}
            />
        </div>
    )
}
