import { getIDToken } from "../../utils/ms-graph-api-call";
const { REACT_APP_API_URL} = process.env;
const headers = {'Content-Type' : 'application/json'};

export default class ActivityDetailApi {
    /**
     * method getActivityDetailsByActivityID
     * @param {number} ActivityID: the id of the activity to get details (people who attendend) for
     * @returns a promise of an array of all activity details connected to the Activity with the requested ID
     */
    async getActivityDetailsByActivityID(ActivityID) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/activity-detail/details-by-activity-id/${ActivityID}`, {
            headers: headers
        })
        .then(response => response.json());
    }
    /**
     * method addActivityDetail
     * @param {object} detailsObject: an object with the ActivityID this detail is connected to as well as
     * the PersonID and PersonType of the instructor or student this detail is describing. If this detail is
     * describing a student, please also include the NoteID for the student note tied to this ActivityDetail.
     * @returns 
     */
    async addActivityDetail(detailsObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/activity-detail`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(detailsObject)
        }).then(response => response.json());
    }

    /**
     * method editActivityDetail
     * @param {object} detailsObject: an object with the ActivityDetailID to edit and the values to update.
     * Please include the following values: PersonID and PersonTypeID of the person to change this detail to.
     * @returns a promise of the edited activity detail.
     */
    async editActivityDetail(detailsObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/activity-detail`, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(detailsObject)
        }).then(response => response.json());
    }

    /**
     * method reviewActivityDetail
     * @param {object} detailsObject: an object with the ActivityDetailID to edit and the ReviewedFLG to update.
     * @returns a promise of the edited activity detail.
     */
    async reviewActivityDetail(detailsObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/activity-detail/review`, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(detailsObject)
        }).then(response => response.json());
    }

    /**
     * method removeActivityDetail
     * @param {number} ActivityDetailID: the id number of the activity detail to remove from the database
     * @returns a message stating the item has been deleted.
     */
    async removeActivityDetail(ActivityDetailID) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;
        
        return fetch(`${REACT_APP_API_URL}/api/activity-detail/${ActivityDetailID}`, {
            method: "DELETE",
            headers: headers
        });
    }
}