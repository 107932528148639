import { getIDToken } from "../../utils/ms-graph-api-call";
const { REACT_APP_API_URL} = process.env;
const headers = {'Content-Type' : 'application/json'};

export default class DashboardApi {
    async getStudentMapData(TermIDs, PersonIDs, ProgramIDs, ClassIDs, StaffPersonIDs) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/dashboard`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({TermIDs, PersonIDs, ProgramIDs, ClassIDs, StaffPersonIDs})
        }).then(response => response.json());
    }

    async getStudentContactData(PersonIDs, PrimaryStaffPersonIDs, ContactTypeIDs, searchText) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/dashboard/contacts`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({PersonIDs, PrimaryStaffPersonIDs, ContactTypeIDs, searchText})
        }).then(response => response.json());
    }

    async getStudentLevelPercentages(PersonID) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/dashboard/percentages?PersonID=${PersonID}`, {
            headers: headers
        }).then(response => response.json());
    }

    async getScorecardAttendancePercentages(PersonIDs, TermIDs, ProgramIDs, ClassIDs, StartDate, EndDate) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/dashboard/attendance/percentages`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({PersonIDs, TermIDs, ProgramIDs, ClassIDs, StartDate, EndDate})
        }).then(response => response.json());
    }

    async getScorecardAttendanceOccurences(PersonIDs, TermIDs, ProgramIDs, ClassIDs, StartDate, EndDate) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/dashboard/attendance/occurences`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({PersonIDs, TermIDs, ProgramIDs, ClassIDs, StartDate, EndDate})
        }).then(response => response.json());
    }

    async getScorecardBehaviorPercentages(PersonIDs, TermIDs, ProgramIDs, ClassIDs, StartDate, EndDate) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/dashboard/behavior/percentages`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({PersonIDs, TermIDs, ProgramIDs, ClassIDs, StartDate, EndDate})
        }).then(response => response.json());
    }

    async getScorecardBehaviorOccurences(PersonIDs, TermIDs, ProgramIDs, ClassIDs, StartDate, EndDate) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/dashboard/behavior/occurences`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({PersonIDs, TermIDs, ProgramIDs, ClassIDs, StartDate, EndDate})
        }).then(response => response.json());
    }

    async getScorecardEngagementPercentages(PersonIDs, TermIDs, ProgramIDs, ClassIDs, StartDate, EndDate) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/dashboard/engagement/percentages`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({PersonIDs, TermIDs, ProgramIDs, ClassIDs, StartDate, EndDate})
        }).then(response => response.json());
    }

    async getScorecardEngagementOccurences(PersonIDs, TermIDs, ProgramIDs, ClassIDs, StartDate, EndDate) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/dashboard/engagement/occurences`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({PersonIDs, TermIDs, ProgramIDs, ClassIDs, StartDate, EndDate})
        }).then(response => response.json());
    }

    async getServiceHours(PersonIDs, ClassIDs, StartDate, EndDate) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/dashboard/service-hours`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({PersonIDs, ClassIDs, StartDate, EndDate})
        }).then(response => response.json());
    }

    //Get a list of all terms related to the listed students.
    //Param PersonIDs: an array of all student PersonIDs to match the terms of
    async getStudentTerms(PersonIDs) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/dashboard/terms/student`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({PersonIDs})
        }).then(response => response.json());
    }

    //Get a list of all classes related to the listed students.
    //Param PersonIDs: an array of all student PersonIDs to match the classes of
    async getStudentClasses(PersonIDs) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/dashboard/classes/student`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({PersonIDs})
        }).then(response => response.json());
    }

    async getCurrentTerm() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/dashboard/terms/current`, {
            headers: headers,
        }).then(response => response.json());
    }

}