import React from 'react';
import { dbTimeStampToTable, databaseToTableFormat } from "../../utils/date-format";

export default function SectionsTableRow({section, handleSelect}) {

    const displaySchedule = () => {
        if(section) {
            let scheduleString = "";
            if(section.MondayStartTS !== section.MondayEndTS) {
                scheduleString = scheduleString
                    .concat("Monday: ", dbTimeStampToTable(section.MondayStartTS), " to ", dbTimeStampToTable(section.MondayEndTS), " ");
            }
            if(section.TuesdayStartTS !== section.TuesdayEndTS) {
                scheduleString = scheduleString
                    .concat("Tuesday: ", dbTimeStampToTable(section.TuesdayStartTS), " to ", dbTimeStampToTable(section.TuesdayEndTS), " ");
            }
            if(section.WednesdayStartTS !== section.WednesdayEndTS) {
                scheduleString = scheduleString
                    .concat("Wednesday: ", dbTimeStampToTable(section.WednesdayStartTS), " to ", dbTimeStampToTable(section.WednesdayEndTS), " ");
            }
            if(section.ThursdayStartTS !== section.ThursdayEndTS) {
                scheduleString = scheduleString
                    .concat("Thursday: ", dbTimeStampToTable(section.ThursdayStartTS), " to ", dbTimeStampToTable(section.ThursdayEndTS), " ");
            }
            if(section.FridayStartTS !== section.FridayEndTS) {
                scheduleString = scheduleString
                    .concat("Friday: ", dbTimeStampToTable(section.FridayStartTS), " to ", dbTimeStampToTable(section.FridayEndTS), " ");
            }
            return scheduleString;
        } else {
            return "";
        }
    }

    return (
        <tr>
            <td>{section.TermNM}</td>
            <td>{section.ClassTypeNM}</td>
            <td>
                <button className="btn btn-link" onClick={() => handleSelect(section)}>
                    {section.ClassNM} {section.SectionNM}
                </button>
            </td>
            <td>{displaySchedule()}</td>
            <td>{section.LastAddedDTS === null ? "--" : databaseToTableFormat(section.LastAddedDTS)}</td>
        </tr>
    )
}
