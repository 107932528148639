import React, {useEffect, useState} from 'react';
import { DashboardApi } from "../../shared/services";
import structureLevelData from "../dashboard/structure-level-data";

export default function LevelsPercentageTable({levels, student}) {
    const [levelPercentages, setLevelPercentages] = useState([]);

    useEffect(() => {
        if(student) {
            const myDashboardApi = new DashboardApi();
            myDashboardApi.getStudentLevelPercentages(student.PersonID)
                .then(percentagesList => setLevelPercentages(structureLevelData(percentagesList)));
        }
    }, [student, levels]);

    const displayRows = levelPercentages.map((outcomePercentages, index) => {
        return (
            <tr key={index}>
                <td>{outcomePercentages.OutcomeNM}</td>
                <td>{outcomePercentages.Level1 ? outcomePercentages.Level1 : 0}</td>
                <td>{outcomePercentages.Level2 ? outcomePercentages.Level2 : 0}</td>
                <td>{outcomePercentages.Level3 ? outcomePercentages.Level3 : 0}</td>
                <td>{outcomePercentages.Level4 ? outcomePercentages.Level4 : 0}</td>
            </tr>
        )
    });

    return (
        <div className="mt-2 mx-5">
            <br/>
            <table className="table table-sm table-hover mx-0 mt-3">
                <thead className="table-light">
                    <tr>
                        <th>OUTCOME</th>
                        <th>LEVEL 1</th>
                        <th>LEVEL 2</th>
                        <th>LEVEL 3</th>
                        <th>LEVEL 4</th>
                    </tr>
                </thead>
                <tbody>
                    {displayRows}
                </tbody>
            </table>
        </div>
    )
}
