import React, {useEffect, useState} from 'react';
import { DashboardApi } from "../../shared/services";

export default function StudentScorecardTables({student, selectedStudents, term, selectedTerms, program, selectedPrograms, selectedClass, selectedClasses, startDate, endDate}) {
    const [attendancePercentages, setAttendancePercentages] = useState();
    const [attendanceOccurences, setAttendanceOccurences] = useState([]);
    const [behaviorPercentages, setBehaviorPercentages] = useState();
    const [behaviorOccurences, setBehaviorOccurences] = useState([]);
    const [engagementPercentages, setEngagementPercentages] = useState();
    const [engagementOccurences, setEngagementOccurences] = useState([]);
    const [serviceHours, setServiceHours] = useState([]);

    useEffect(() => {
        const PersonIDs = selectedStudents ? selectedStudents.map(student => student.PersonID) : null;
        const TermIDs = selectedTerms ? selectedTerms.map(term => term.TermID) : null;
        const ProgramIDs = selectedPrograms ? selectedPrograms.map(program => program.ProgramID) : null;
        const ClassIDs = selectedClasses ? selectedClasses.map(classItem => classItem.ClassID) : null;
        const StartDate = startDate ? startDate : null;
        const EndDate = endDate ? endDate : null;

        const myDashboardApi = new DashboardApi();
        myDashboardApi.getScorecardAttendancePercentages(PersonIDs, TermIDs, ProgramIDs, ClassIDs, StartDate, EndDate)
            .then(attPercent => setAttendancePercentages(attPercent));

        myDashboardApi.getScorecardAttendanceOccurences(PersonIDs, TermIDs, ProgramIDs, ClassIDs, StartDate, EndDate)
            .then(attOcc => {
                setAttendanceOccurences(attOcc);
            });
        
        myDashboardApi.getScorecardBehaviorPercentages(PersonIDs, TermIDs, ProgramIDs, ClassIDs, StartDate, EndDate)
            .then(bPer => setBehaviorPercentages(bPer));
        
        myDashboardApi.getScorecardBehaviorOccurences(PersonIDs, TermIDs, ProgramIDs, ClassIDs, StartDate, EndDate)
            .then(bOcc => {
                setBehaviorOccurences(bOcc);
            });

        myDashboardApi.getScorecardEngagementPercentages(PersonIDs, TermIDs, ProgramIDs, ClassIDs, StartDate, EndDate)
            .then(ePer => setEngagementPercentages(ePer));

        myDashboardApi.getScorecardEngagementOccurences(PersonIDs, TermIDs, ProgramIDs, ClassIDs, StartDate, EndDate)
            .then(eOcc => {
                setEngagementOccurences(eOcc);
            });

        myDashboardApi.getServiceHours(PersonIDs, ClassIDs, StartDate, EndDate)
            .then(serviceHoursList => {
                if(serviceHoursList.findIndex(shours => shours.ProgramNM === "Live") === -1) {
                    serviceHoursList.push({ProgramID: 1, ProgramNM: "Live", ServiceHours: 0});
                }
                if(serviceHoursList.findIndex(shours => shours.ProgramNM === "Work") === -1) {
                    serviceHoursList.push({ProgramID: 2, ProgramNM: "Work", ServiceHours: 0});
                }
                if(serviceHoursList.findIndex(shours => shours.ProgramNM === "Thrive") === -1) {
                    serviceHoursList.push({ProgramID: 3, ProgramNM: "Thrive", ServiceHours: 0});
                }
                if(serviceHoursList.findIndex(shours => shours.ProgramNM === "Clinical Support") === -1) {
                    serviceHoursList.push({ProgramID: 4, ProgramNM: "Clinical Support", ServiceHours: 0});
                }
                if(serviceHoursList.findIndex(shours => shours.ProgramNM === "Service Coordination") === -1) {
                    serviceHoursList.push({ProgramID: 5, ProgramNM: "Service Coordination", ServiceHours: 0});
                }
                setServiceHours(serviceHoursList.sort((a,b) => a.ProgramID - b.ProgramID));
            });
    }, [selectedStudents, selectedTerms, selectedPrograms, selectedClasses, startDate, endDate]);

    const serviceHourRows = serviceHours.map((service, index) => {
        return (
            <tr key={index}>
                <td>{service.ProgramNM}</td>
                <td>{service.ServiceHours ? service.ServiceHours : 0}</td>
                <td>{service.ScheduledHours ? service.ScheduledHours : 0}</td>
            </tr>
        );
    });

    return (
        <table className="table mx-0 mt-2">
            <thead className="table-light">
                <tr>
                    <th colSpan="4">STUDENT SCORECARD</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <table className="table table-sm table-hover">
                            <thead className="table-light">
                                <tr>
                                    <th colSpan="3">DIRECT SERVICE HOURS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>DEPARTMENT</th>
                                    <th>ACTUAL HOURS</th>
                                    <th>SCHEDULED HOURS</th>
                                </tr>
                                {serviceHourRows}
                            </tbody>
                        </table>
                    </td>
                    <td>
                        <table className="table table-sm table-hover">
                            <thead className="table-light">
                                <tr>
                                    <th colSpan="2">ATTENDANCE</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th colSpan="2">ATTENDANCE %</th>
                                </tr>
                                <tr>
                                    <th>PRESENT VS ABSENT %</th>
                                    <td>{attendancePercentages && attendancePercentages.PresentAbsentPrecentage ? attendancePercentages.PresentAbsentPrecentage.toFixed(2) : "" }%</td>
                                </tr>
                                <tr>
                                    <th>LATE %</th>
                                    <td>{attendancePercentages && attendancePercentages.LatePercentage ? attendancePercentages.LatePercentage.toFixed(2) : "0" }%</td>
                                </tr>
                                <tr>
                                    <td colSpan="2"><br/></td>
                                </tr>
                                <tr>
                                    <th>ATTENDANCE TYPE</th>
                                    <th># OF OCCURENCES</th>
                                </tr>
                                <tr>
                                    <td>{attendanceOccurences?.Score1?.AttendanceScoreDSC}</td>
                                    <td>{attendanceOccurences?.Score1?.Occurences}</td>
                                </tr>
                                <tr>
                                    <td>{attendanceOccurences?.Score2?.AttendanceScoreDSC}</td>
                                    <td>{attendanceOccurences?.Score2?.Occurences}</td>
                                </tr>
                                <tr>
                                    <td>{attendanceOccurences?.Score3?.AttendanceScoreDSC}</td>
                                    <td>{attendanceOccurences?.Score3?.Occurences}</td>
                                </tr>
                                <tr>
                                    <td>{attendanceOccurences?.Score6?.AttendanceScoreDSC}</td>
                                    <td>{attendanceOccurences?.Score6?.Occurences}</td>
                                </tr>
                                <tr>
                                    <td>{attendanceOccurences?.Score7?.AttendanceScoreDSC}</td>
                                    <td>{attendanceOccurences?.Score7?.Occurences}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td>
                        <table className="table table-sm table-hover">
                            <thead className="table-light">
                                <tr>
                                    <th colSpan="2">BEHAVIOR</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th colSpan="2">BEHAVIOR %</th>
                                </tr>
                                <tr>
                                    <th>AVERAGE SCORE</th>
                                    <td>{behaviorPercentages && behaviorPercentages.AverageScore ? behaviorPercentages.AverageScore.toFixed(2) : ""}</td>
                                </tr>
                                <tr>
                                    <th>AVERAGE %</th>
                                    <td>{behaviorPercentages && behaviorPercentages.BehaviorPercentage ? behaviorPercentages.BehaviorPercentage.toFixed(2) : ""}%</td>
                                </tr>
                                <tr>
                                    <td colSpan="2"><br/></td>
                                </tr>
                                <tr>
                                    <th>SCORE TYPE</th>
                                    <th># OF OCCURENCES</th>
                                </tr>
                                <tr>
                                    <td>{behaviorOccurences?.Score4?.BehaviorScoreDSC || 4}</td>
                                    <td>{behaviorOccurences?.Score4?.Occurences}</td>
                                </tr>
                                <tr>
                                    <td>{behaviorOccurences?.Score3?.BehaviorScoreDSC || 3}</td>
                                    <td>{behaviorOccurences?.Score3?.Occurences}</td>
                                </tr>
                                <tr>
                                    <td>{behaviorOccurences?.Score2?.BehaviorScoreDSC || 2}</td>
                                    <td>{behaviorOccurences?.Score2?.Occurences}</td>
                                </tr>
                                <tr>
                                    <td>{behaviorOccurences?.Score1?.BehaviorScoreDSC || 1}</td>
                                    <td>{behaviorOccurences?.Score1?.Occurences}</td>
                                </tr>
                                <tr>
                                    <td>{behaviorOccurences?.ScoreUnrecorded?.BehaviorScoreDSC || "Unrecorded"}</td>
                                    <td>{behaviorOccurences?.ScoreUnrecorded?.Occurences}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td>
                        <table className="table table-sm table-hover">
                            <thead className="table-light">
                                <tr>
                                    <th colSpan="2">ENGAGEMENT</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th colSpan="2">ENGAGEMENT %</th>
                                </tr>
                                <tr>
                                    <th>AVERAGE SCORE</th>
                                    <td>{engagementPercentages && engagementPercentages.AverageScore ? engagementPercentages.AverageScore.toFixed(2) : ""}</td>
                                </tr>
                                <tr>
                                    <th>AVERAGE %</th>
                                    <td>{engagementPercentages && engagementPercentages.EngagementPercentage ? engagementPercentages.EngagementPercentage.toFixed(2) : ""}%</td>
                                </tr>
                                <tr>
                                    <td colSpan="2"><br/></td>
                                </tr>
                                <tr>
                                    <th>SCORE TYPE</th>
                                    <th># OF OCCURENCES</th>
                                </tr>
                                <tr>
                                    <td>{engagementOccurences?.Score4?.EngagementScoreDSC || 4}</td>
                                    <td>{engagementOccurences?.Score4?.Occurences}</td>
                                </tr>
                                <tr>
                                    <td>{engagementOccurences?.Score3?.EngagementScoreDSC || 3}</td>
                                    <td>{engagementOccurences?.Score3?.Occurences}</td>
                                </tr>
                                <tr>
                                    <td>{engagementOccurences?.Score2?.EngagementScoreDSC || 2}</td>
                                    <td>{engagementOccurences?.Score2?.Occurences}</td>
                                </tr>
                                <tr>
                                    <td>{engagementOccurences?.Score1?.EngagementScoreDSC || 1}</td>
                                    <td>{engagementOccurences?.Score1?.Occurences}</td>
                                </tr>
                                <tr>
                                    <td>{engagementOccurences?.ScoreUnrecorded?.EngagementScoreDSC || "Unrecorded"}</td>
                                    <td>{engagementOccurences?.ScoreUnrecorded?.Occurences}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}
