import { getIDToken } from "../../utils/ms-graph-api-call";
const { REACT_APP_API_URL} = process.env;
const headers = {'Content-Type' : 'application/json'};

export default class SectionRollApi {
    async getStudentsBySection(section) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/section-roll/studentsBySection/${section}`, {
            headers: headers
        }).then(response => response.json());
    }

    async getInstructorsBySection(section) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/section-roll/instructorsBySection/${section}`, {
            headers: headers
        }).then(response => response.json());
    }

    /**
     * method addPersonToSection
     * @param {object} sectionRollObject: please include the following values- ClassID (the class of the section), 
     * SectionID (the section the section roll is connected to), PersonID (the person to add to the section roll) 
     * @returns the person added to the section roll.
     */
    async addPersonToSection(sectionRollObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/section-roll`, {
            method: 'POST',
            headers,
            body: JSON.stringify(sectionRollObject)
        }).then(response => response.json());
    }

    /**
     * 
     * @param {object} sectionRollObject: please include the PersonID of the instructor getting assigned the class
     * and the SectionRollID to change the PersonID of. 
     * @returns the updated SectionRoll with the corresponding SectionRollID. 
     */
    async editInstructor(sectionRollObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/section-roll`, {
            method: 'PUT',
            headers,
            body: JSON.stringify(sectionRollObject)
        }).then(response => response.json());
    }

    /**
     * method removePersonFromSection
     * @param {*} sectionRollID: the id of the section roll record to remove
     * @returns an object with a message stating which section roll has been deleted.
     */
    async removePersonFromSection(sectionRollID) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;
        
        return fetch(`${REACT_APP_API_URL}/api/section-roll/${sectionRollID}`, {
            method: "DELETE",
            headers
        }).then(response => response.json());
    }
}