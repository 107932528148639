import React from 'react';
import LevelsTableRow from "./levels-table-row";


export default function LevelsTable({levels, setLevels}) {

    const tableRows = levels.map((level, index) => {
        return (
            <LevelsTableRow key={level.StudentLevelID} level={level} index ={index} levels={levels} setLevels={setLevels} />
        )
    });

    return (
        <div className='table-80 mx-4 mt-2'>
            <table className="table table-sm table-hover ">
                <thead className="table-light fixed-column-header">
                    <tr>
                        <th>STUDENT</th>
                        <th>PROGRAM</th>
                        <th>OUTCOME</th>
                        <th>LEVEL NAME</th>
                        <th>STEP DESCRIPTION</th>
                        <th>IN PROGRESS</th>
                        <th>COMPLETED</th>
                        <th>N/A</th>
                        <th>COMPLETED DATE</th>
                        <th>REVIEWED</th>
                        <th>EDIT</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </table>
        </div>
    )
}
