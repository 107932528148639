import React, {useEffect, useState} from 'react';
import ActivitiesRow from "./activities-row";
import { ActivityTypeApi, PersonApi } from "../../shared/services";

export default function ActivitiesTable({filteredActivities}) {
    const [students, setStudents] = useState([]);
    const [staff, setStaff] = useState([]);
    const [contactTypes, setContactTypes] = useState([]);

    useEffect(() => {
        const myPersonApi = new PersonApi();
        myPersonApi.getStudents()
            .then(studentList => {
                if (studentList.length) {
                    setStudents(studentList);
                }
            });

        myPersonApi.getInstructors()
            .then(staffList => {
                if (staffList.length) {
                    setStaff(staffList);
                }
            });

        const myActivityTypeApi = new ActivityTypeApi();
        myActivityTypeApi.getActivityTypes()
            .then(activityList => {
                if (activityList) {
                    setContactTypes(activityList)
                }
            });
    }, []);

    const tableRows = filteredActivities.map((activity, index) => {
        return (
            <ActivitiesRow 
                key={activity.ActivityID} 
                activity={activity} 
                students={students} 
                staff={staff} 
                activityTypes={contactTypes}
            />
        )
    });

    return (
        <>
            <div className="row justify-content-md-center">
                <div className="col-2"></div>
                <div className="col-8 title">
                    <h4>Contact History</h4>
                </div>
                <div className="col-2"></div>
            </div>
            <div className="table-60 w-auto mx-4">
                <table className="table table-sm table-hover">
                    <thead className="table-light fixed-column-header">
                        <tr>
                            <th>TITLE</th>
                            <th>LENGTH</th>
                            <th>TYPE</th>
                            <th>DATE</th>
                            <th>TIME</th>
                            <th>STUDENTS</th>
                            <th>PRIMARY STAFF</th>
                            <th>OTHER STAFF</th>
                            <th>NOTES</th>
                            <th>STUDENT NOTES</th>
                            <th>EDIT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </table>
            </div>
        </>
    )
}
