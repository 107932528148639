import React, {useEffect, useState} from 'react';
import { AttendanceApi } from "../../shared/services";
import SectionHistoryRow from "./section-history-row";

export default function SectionHistoryTab({section}) {
    const [editable] = useState(false);
    const [sectionHistory, setSectionHistory] = useState([])

    useEffect(() => {
        const attendanceAPI = new AttendanceApi();

        attendanceAPI.getSectionHistory(section.SectionID)
            .then(res => {
                setSectionHistory(res);
            });
    }, [section]);


    const displaySectionHistoryRows = sectionHistory.map((row, index) => {
        return (
            <SectionHistoryRow 
                key={row.AttendanceDTS}
                row={{...row, index, editable: false}} 
                setSectionHistory={setSectionHistory}
            />
        )
    });
    
    const displayDeleteColumn = editable ? <th>DELETE</th> : null;

    return (
        <>
            <br/>
            <div className='table-60'>
                <table className="table table-sm table-hover">
                    <thead className="table-light fixed-column-header">
                        <tr>
                            <th>DATE</th>
                            <th>COURSE LENGTH</th>
                            <th>SECTION NAME</th>
                            <th>STUDENTS</th>
                            <th>INSTRUCTORS</th>
                            <th>EDIT</th>
                            {displayDeleteColumn}
                        </tr>
                    </thead>
                    <tbody>
                        {displaySectionHistoryRows}
                    </tbody>
                </table>
            </div>
            
        </>
    )
}
