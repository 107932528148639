import React, {useState} from 'react';
import { PersonApi } from "../../shared/services";
import { ObjectDropdown } from "../../shared/components";
import { validateEmail } from '../../utils/string-format';

export default function AddUser({personTypes, users, setUsers}) {
    const [firstNM, setFirstNM] = useState("");
    const [lastNM, setLastNM] = useState("");
    const [emailTXT, setEmailTXT] = useState("");
    const [personType, setPersonType] = useState(null);
    const [displayAlert, setDisplayAlert] = useState(false);
    const [alertMSG, setAlertMSG] = useState("");
    const [alertStyle, setAlertStyle] = useState("");

    const handleSubmit = () => {
        const myPersonApi = new PersonApi();
        if(firstNM !== "" && lastNM !== "" && emailTXT !== "" && validateEmail(emailTXT) && personType) {
            myPersonApi.addEmployee({
                FirstNM: firstNM,
                LastNM: lastNM,
                EmailTXT: emailTXT,
                PersonTypeID: personType.PersonTypeID
            }).then(newPerson => {
                const tempUsers = [...users];
                tempUsers.push(newPerson);
                setUsers(tempUsers);
                setAlertStyle("alert alert-info alert-dismissible");
                setAlertMSG(`New user ${newPerson.FirstNM} ${newPerson.LastNM} has been added.`);
                setDisplayAlert(true);
            });
            setFirstNM("");
            setLastNM("");
            setEmailTXT("");
            setPersonType(null);
        } else {
            setAlertStyle("alert alert-warning alert-dismissible");
            setAlertMSG(`Please fill out all fields and ensure that the User Principal Name is in Email Format`);
            setDisplayAlert(true);
        }
    }

    const alert = displayAlert ? (
        <div className={alertStyle} role="alert">
            {alertMSG}
            <button type="button" className="btn-close" onClick={() => setDisplayAlert(false)} aria-label="Close"></button>
        </div> )
        : null

    return (
        <> 
            {alert}
            <table className="table table-sm table-hover">
                <thead className="table-light">
                    <tr>
                        <th>FIRST NAME</th>
                        <th>LAST NAME</th>
                        <th>USER PRINCIPAL NAME</th>
                        <th>PERSON TYPE</th>
                        <th>SUBMIT</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <input className="form-control" value={firstNM} onChange={({target}) => setFirstNM(target.value)}/>
                        </td>
                        <td>
                            <input className="form-control" value={lastNM} onChange={({target}) => setLastNM(target.value)}/>
                        </td>
                        <td>
                            <input type="email" className="form-control" value={emailTXT} onChange={({target}) => setEmailTXT(target.value)}/>
                        </td>
                        <td>
                            <ObjectDropdown
                                items={personTypes}
                                itemNameKey="PersonTypeDSC"
                                selected={personType}
                                setSelected={setPersonType}
                                label="Role"
                                isBig={false}
                            />
                        </td>
                        <td>
                            <button className="btn btn-primary" onClick={handleSubmit}>
                                Submit
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}
