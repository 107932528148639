import React from 'react';

export default function AddStudentNotes({ students, studentNotes, setStudentNotes }) {

    const handleChange = (event) => {
        const tempStudentNotes = [...studentNotes];
        tempStudentNotes[event.target.id] = event.target.value;
        setStudentNotes(tempStudentNotes);
    }

    const studentNoteFields = students.map((student, index) => {
        return (
            <div key={student.PersonID} className="col-2">
                <label className="form-label">{student.FirstNM + " " + student.LastNM + " note"}</label>
                <input id={index} className="form-control" type="text" onChange={handleChange}/>
            </div>
        )
    });

    return (
        <div className="row justify-content-md-center mb-2">
            {studentNoteFields}
        </div>
    )
}
