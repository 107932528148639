import React from 'react';
import TermTableRow from "./term-table-row";

export default function TermTable({ filteredTerms }) {

    const tableRows = filteredTerms.map((term, index) => {
        return (
            <TermTableRow key={term.TermID} term={term} index={index} />
        )
    });

    return (
        <div className='table-60 mx-0 mt-2'>
            <table className="table table-sm table-hover">
                <thead className="table-light fixed-column-header">
                    <tr>
                        <th>TERM</th>
                        <th>START DATE</th>
                        <th>END DATE</th>
                        <th>EDIT</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </table>
        </div>


    )
}
