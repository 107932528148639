import React, {useState} from 'react';
import StudentHistoryTab from "../student-history-tab/student-history-tab";
import InstructorHistoryTab from '../instructor-history-tab/instructor-history-tab';
import InstructionHistoryTab from "../instruction-history-tab/instruction-history-tab";
import SectionHistoryTab from "../section-history-tab/section-history-tab";

export default function AttendanceHistory({section}) {
    const [tab, setTab] = useState("section-history");
    const tabToDisplay = tab === "section-history" ? <SectionHistoryTab section={section}/>
        : tab === "student-history" ? <StudentHistoryTab section={section}/>
        : tab === "instructor-history" ? <InstructorHistoryTab section={section}/>
        : <InstructionHistoryTab section={section}/>

    const activeTab = "nav-link adm-link active";
    const passiveTab = "nav-link adm-link";

    return (
        <div className="attendance-tabs">
            <br/>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className={tab === "section-history" ? activeTab : passiveTab} id="section-history-tab" onClick={() => setTab("section-history")}>
                        SECTION HISTORY
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={tab === "student-history" ? activeTab : passiveTab} id="student-history-tab" onClick={() => setTab("student-history")}>
                        STUDENT HISTORY
                    </button>
                </li>
                <li>
                    <button className={tab === "instructor-history" ? activeTab : passiveTab} id="instructor-history-tab" onClick={() => setTab("instructor-history")}>
                        INSTRUCTOR HISTORY
                    </button>
                </li>
                <li>
                    <button className={tab === "instruction-history" ? activeTab : passiveTab} id="instructor-history-tab" onClick={() => setTab("instruction-history")}>
                        DIRECT INSTRUCTION NOTES HISTORY
                    </button>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                {tabToDisplay}
            </div>
        </div>
    )
}
