import React, { useEffect, useState } from 'react';
import { Dropdown, ObjectDropdown, ObjectMultiSelect } from "../../shared/components";
import { SectionsApi, SectionScheduleApi, SectionRollApi, TermsApi } from "../../shared/services";
import {compareDateStrings, dateIsBetween, pickerToDatabaseFormat, dbTimeStampToPicker} from "../../utils/date-format.js";

export default function AddTermForm({terms, setTerms, sections}) {
    const TERM_TYPE = ['Spring', 'Work - Spring', 'Summer', 'Work - Summer', 'Fall', 'Work - Fall', 'Winter', 'Work - Winter'];
    const [termType, setTermType] = useState('');  // Holds the value of the selected term type
    const [startDate, setStartDate] = useState('');  // Keeps track of selected start date for the term
    const [endDate, setEndDate] = useState('');  // Keeps track of selected end date for the term
    const [copiedTerm, setCopiedTerm] = useState(null); // Keeps track of what term, if any, this term will inherit sections from
    const [copiedSections, setCopiedSections] = useState([]); // Keeps track of what sections are to be copied
    const [termSections, setTermSections] = useState([]); // Keeps track of the inherited sections
    const [displayAlert, setDisplayAlert] = useState(false);
    const [alertMSG, setAlertMSG] = useState("");
    const [alertStyle, setAlertStyle] = useState("");

    useEffect(() => {
        const myTermsApi = new TermsApi();
        myTermsApi.getTerms().then(termList => {
            termList.sort((a, b) => compareDateStrings(a.EndDTS, b.EndDTS));
            setTerms(termList);
        });
    }, [startDate, setTerms]);

    useEffect(() => {
        if(copiedTerm) {
            setTermSections(sections.filter(section => section.TermID === copiedTerm.TermID));
            setCopiedSections([]);
        }
    }, [sections, copiedTerm])

    // Handles selecting something from the term types dropdown. Dropdown expects both a key and value params.
    const setOption = (key, value) => {
        setTermType(value);
    }

    // Function to make sure the terms are not duplicated (making sure term does not fall within the date range of another term)
    const checkValidTerm = (termID = null) => {
        const tempStart = new Date(pickerToDatabaseFormat(startDate));
        const tempEnd = new Date(pickerToDatabaseFormat(endDate));
        var isValid = true;
        terms.forEach(tempTerm => {
            //Don't compare a term to itself
            if (tempTerm.TermID === termID) {
                return;
            }
            if ((dateIsBetween(tempStart, new Date(tempTerm.StartDTS), new Date(tempTerm.EndDTS))) || (dateIsBetween(tempEnd, new Date(tempTerm.StartDTS), new Date(tempTerm.EndDTS)))) {
                isValid = false;
            }
        });
        return isValid;
    }

    const copySections = async (newTerm) => {
        const mySectionsApi = new SectionsApi();
        const mySectionScheduleApi = new SectionScheduleApi();
        const mySectionRollApi = new SectionRollApi();
        for(let section of copiedSections) {
            const oldSectionSchedule = await mySectionScheduleApi.getSectionSchedule(section.SectionScheduleID);
            let tempSchedule = {};
            //Setup the schedule for the new section using the old one
            tempSchedule.FrequencyID = oldSectionSchedule.FrequencyID;
            tempSchedule.MondayStartTS = oldSectionSchedule.MondayStartTS ? dbTimeStampToPicker(oldSectionSchedule.MondayStartTS) : null;
            tempSchedule.TuesdayStartTS = oldSectionSchedule.TuesdayStartTS ? dbTimeStampToPicker(oldSectionSchedule.TuesdayStartTS) : null;
            tempSchedule.WednesdayStartTS = oldSectionSchedule.WednesdayStartTS ? dbTimeStampToPicker(oldSectionSchedule.WednesdayStartTS) : null;
            tempSchedule.ThursdayStartTS = oldSectionSchedule.ThursdayStartTS ? dbTimeStampToPicker(oldSectionSchedule.ThursdayStartTS) : null;
            tempSchedule.FridayStartTS = oldSectionSchedule.FridayStartTS ? dbTimeStampToPicker(oldSectionSchedule.FridayStartTS) : null;
            tempSchedule.MondayEndTS = oldSectionSchedule.MondayEndTS ? dbTimeStampToPicker(oldSectionSchedule.MondayEndTS) : null;
            tempSchedule.TuesdayEndTS = oldSectionSchedule.TuesdayEndTS ? dbTimeStampToPicker(oldSectionSchedule.TuesdayEndTS) : null;
            tempSchedule.WednesdayEndTS = oldSectionSchedule.WednesdayEndTS ? dbTimeStampToPicker(oldSectionSchedule.WednesdayEndTS) : null;
            tempSchedule.ThursdayEndTS = oldSectionSchedule.ThursdayEndTS ? dbTimeStampToPicker(oldSectionSchedule.ThursdayEndTS) : null;
            tempSchedule.FridayEndTS = oldSectionSchedule.FridayEndTS ? dbTimeStampToPicker(oldSectionSchedule.FridayEndTS) : null;
            //Don't copy the start and end dates from the old section. Get it from the new term instead
            tempSchedule.SectionStartDTS = newTerm.StartDTS;
            tempSchedule.SectionEndDTS = newTerm.EndDTS;
            const newSchedule = await mySectionScheduleApi.addSectionSchedule(tempSchedule);
            mySectionsApi.addSection({
                SectionNM: section.SectionNM,
                ClassID: section.ClassID,
                SectionScheduleID : newSchedule.SectionScheduleID,
                SectionStaffID : section.SectionStaffID,
                TermID : newTerm.TermID
            }).then(newSection => {
                //Add the primary teacher to the new section's roll
                mySectionRollApi.addPersonToSection({
                    ClassID: newSection.ClassID,
                    SectionID: newSection.SectionID,
                    PersonID: newSection.SectionStaffID
                });
                //if 1 on 1, also add student to the new section's roll
                if(section.ClassTypeID === 2) {
                    mySectionRollApi.getStudentsBySection(section.SectionID)
                        .then(studentRoll => {
                            const student = studentRoll[0];
                            mySectionRollApi.addPersonToSection({
                                ClassID: newSection.ClassID,
                                SectionID: newSection.SectionID,
                                PersonID: student.PersonID
                            });
                        })
                }
            });
        }
    }

    // Function that is called when a user presses the "Add New Term" button
    const handleSubmit = async () => {
        setDisplayAlert(false);
        // If all the information has been filled out
        if (termType !== "" && startDate !== "" && endDate !== '') { 
            // Checking to make sure neither of the dates fall within another term
            const validTerm = checkValidTerm(); 
            if (!validTerm) {
                setAlertMSG('A term including one or more of the dates provided has already been created. Please choose a different date range');
                setAlertStyle("alert alert-warning alert-dismissible");
                setDisplayAlert(true);
            //Checking if end date comes before start date
            } else if(compareDateStrings(startDate, endDate) === -1) {
                setAlertMSG("End date comes before start date. Please ensure start date is before end date");
                setAlertStyle("alert alert-warning alert-dismissible");
                setDisplayAlert(true);
            } else {
                // Term name format is the term type and then the year
                const date = new Date(pickerToDatabaseFormat(startDate));
                const termNM = termType + ' ' + date.getFullYear();
                const myTermsApi = new TermsApi();
                
                // Call the api to add the term
                myTermsApi.addTerm({TermNM: termNM, StartDTS: pickerToDatabaseFormat(startDate), EndDTS: pickerToDatabaseFormat(endDate)})
                .then(newTerm => {
                    if(copiedTerm && copiedSections.length > 0) {
                    copySections(newTerm);
                    }
                });
                // Set the start and end date back to nothing again
                setStartDate("");
                setEndDate("");
                const updatedTerms = await myTermsApi.getTerms();
                setTerms(updatedTerms);

                //Alert the user of successful addition of Term
                setAlertMSG("The new term has been created");
                setAlertStyle("alert alert-primary alert-dismissible");
                setDisplayAlert(true);
            }

        } else {
            setAlertMSG("Please fill out the Term Type, Start Date, and End Date.");
            setAlertStyle("alert alert-warning alert-dismissible");
            setDisplayAlert(true);
        }
    };

    const alert = displayAlert ? <div className={alertStyle} role="alert">
            {alertMSG}
            <button type="button" className="btn-close" onClick={() => setDisplayAlert(false)} aria-label="Close"></button>
        </div> : null;

    return (
        <>
            {alert}
            <table className="table table-sm table-hover mx-0 mt-2">
                <thead className="table-light">
                    <tr>
                        <th>TERM TYPE</th>
                        <th>START DATE</th>
                        <th>END DATE</th>
                        <th>TERM TO COPY (OPTIONAL)</th>
                        <th>INCLUDED SECTIONS (OPTIONAL)</th>
                        <th>SAVE</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <Dropdown id="term-dropdown" items={TERM_TYPE} label="Select Term" onSelect={setOption}/>
                        </td>
                        <td>
                            <input id="start-date-picker" type="date" className="form-control" value={startDate} onChange={({target}) => setStartDate(target.value)}/>
                        </td>
                        <td>
                            <input id="end-date-picker"  type="date" className="form-control" value={endDate} onChange={({target}) => setEndDate(target.value)}/>
                        </td>
                        <td>
                            <ObjectDropdown 
                                items={terms}
                                itemNameKey="TermNM"
                                label="Term"
                                selected={copiedTerm}
                                setSelected={setCopiedTerm}
                                isBig={false}
                            />
                        </td>
                        <td>
                            {copiedTerm ? 
                                <ObjectMultiSelect
                                    items={termSections}
                                    itemPrimaryKey="SectionID"
                                    itemNameSecondKey="SectionNM"
                                    itemNameKey="ClassNM"
                                    selected={copiedSections}
                                    setSelected={setCopiedSections}
                                    label="Section"
                                    isBig={true}
                                />
                                : ""
                            }
                        </td>
                        <td>
                            <button className="btn btn-primary" onClick={handleSubmit}>
                                Add New Term
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}
