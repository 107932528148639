import React, {useEffect, useState} from 'react';
import InstructionHistoryRow from "./instruction-history-row";
import { InstructionNoteApi } from "../../shared/services";

export default function InstructionHistoryTab({section}) {
    const [instructionData, setInstructionData] = useState([]);

    //This useEffect runs everytime section state is updated
    useEffect(() => {
        const myInstructinNoteApi = new InstructionNoteApi();
        myInstructinNoteApi.getInstructionNotesBySection(section.SectionID)
            .then(InstructionNotesList => {
                setInstructionData(InstructionNotesList);
            });
    }, [section]);

    const instructionHistoryRows = instructionData.map((instruction) => {
        return (
            <InstructionHistoryRow key={instruction.NoteID} instruction={instruction}/>
        )
    })
    return (
        <div className="tab-pane fade show active" id="history" role="tabpanel" aria-labelledby="history-tab">
            <br />
            <div className='table-60'>
                <table className="table table-sm table-hover">
                    <thead className="table-light fixed-column-header">
                        <tr>
                            <th scope="col table-header">DATE</th>
                            <th scope="col table-header">NOTE TYPE</th>
                            <th scope="col table-header">NOTE</th>
                            <th scope="col table-header">EDIT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {instructionHistoryRows}
                    </tbody>
                </table>
            </div>
            
        </div>
    )
}
