import {getIDToken} from "../../utils/ms-graph-api-call";
const { REACT_APP_API_URL} = process.env;
const headers = {'Content-Type' : 'application/json'};

export default class PersonApi {
    /**
     * method getPersonByEmail
     * @param {string} email 
     * @returns a promise of a person object with matching email address or no one if that email address doesn't exist.
     */
    async getPersonByEmail(email) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/person/email/${email}`, {
            headers: headers
        }).then(response => response.json());
    }

    /**
     * method getStudents
     * @returns a promise of an array of all students that are active
     */
    async getStudents() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/person/students`, {
            headers: headers
        }).then(response => response.json());
    }

    //Return a promise of an arry of all students, active or inactive.
    async getAllStudents() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/person/all-students`, {
            headers: headers
        }).then(response => response.json());
    }

    //Return a promise of an array of all people who are not students.
    async getAllNonStudents() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/person/all-non-students`, {
            headers: headers
        }).then(response => response.json());
    }
    
    /**
     * method getInstructors
     * @returns a promise of an array of all instructors (primary and secondary staff) that are active
     */
    async getInstructors() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/person/instructors`, {
            headers: headers
        })
        .then(response => response.json());
    }

    async getServiceCoordinators() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/person/service-coordinator`, {
            headers: headers
        }).then(response => response.json());
    }

    async addStudent(studentObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/person/student`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(studentObject)
        }).then(response => response.json());
    }

    async addEmployee(employeeObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/person/employee`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(employeeObject)
        }).then(response => response.json());
    }

    async editStudent(studentObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/person/student`, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(studentObject)
        }).then(response => response.json());
    }

    async editEmployee(employeeObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;
        
        return fetch(`${REACT_APP_API_URL}/api/person/employee`, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(employeeObject)
        }).then(response => response.json());
    }
}