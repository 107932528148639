import React from 'react';
import LevelsTableRow from "./levels-table-row";

export default function LevelsTable({levels, setLevels, outcomes, programs}) {
    const tableRows = levels ? levels.map((level, index) => {
        return (
            <LevelsTableRow 
                key={index} 
                level={level} 
                outcomes={outcomes} 
                programs={programs} 
                levels={levels} 
                setLevels={setLevels}
            />
        )
    }) : null;

    return (
        <div className='table-60'>
            <table className="table table-sm table-hover mx-0 mt-2">
                <thead className="table-light fixed-column-header">
                    <tr>
                        <th>PROGRAM</th>
                        <th>OUTCOME</th>
                        <th>LEVEL NAME</th>
                        <th>STEP DESCRIPTION</th>
                        <th>EDIT</th>
                        <th>ARCHIVE</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </table>
        </div>
    )
}
