import React, { useEffect, useState } from 'react';
import { editIcon, saveIcon } from "../../assets/images/images";
import { Dropdown, ObjectDropdown } from "../../shared/components";
import { OutcomeLevelApi } from "../../shared/services";

export default function LevelsTableRow({level, outcomes, programs, levels, setLevels}) {
    const [editable, setEditable] = useState(false);
    const [levelOutcome, setLevelOutcome] = useState({OutcomeNM : level.OutcomeNM, OutcomeID : level.OutcomeID});
    const [levelProgram, setLevelProgram] = useState({ProgramNM : level.ProgramNM, ProgramID : level.ProgramID});
    const levelNames = ["Level 1", "Level 2", "Level 3", "Level 4"];
    const [levelNM, setLevelNM] = useState(level.LevelNM);
    const [levelDSC, setLevelDSC] = useState(level.LevelDSC);
    const [displayAlert, setDisplayAlert] = useState(false);
    const [alertMSG, setAlertMSG] = useState("");
    const [alertStyle, setAlertStyle] = useState("");

    useEffect(() => {
        setLevelOutcome({OutcomeNM : level.OutcomeNM, OutcomeID : level.OutcomeID});
        setLevelProgram({ProgramNM : level.ProgramNM, ProgramID : level.ProgramID});
        setLevelNM(level.LevelNM);
        setLevelDSC(level.LevelDSC);
    }, [level]);

    const handleEdit = () => {
        //If the level description is cleared out as an empty string, prevent the edit from working
        if(levelDSC === "") {
            setAlertMSG("Please do not leave the description field empty.");
            setAlertStyle("alert alert-warning alert-dismissible");
            setDisplayAlert(true);
        } else {
            const myOutcomeLevelApi = new OutcomeLevelApi();
            myOutcomeLevelApi.editOutcomeLevel({
                OutcomeLevelID: level.OutcomeLevelID,
                ProgramID: levelProgram.ProgramID,
                OutcomeID: levelOutcome.OutcomeID,
                LevelNM: levelNM,
                LevelDSC: levelDSC
            });
            setAlertMSG("Level has been successfully updated.");
            setAlertStyle("alert alert-info alert-dismissible");
            setDisplayAlert(true);
            setEditable(false);
        }
    }

    const handleArchive = (archive) => {
        const myOutcomeLevelApi = new OutcomeLevelApi();
        myOutcomeLevelApi.archiveOutcomeLevel({
            OutcomeLevelID: level.OutcomeLevelID,
            ArchivedFLG: archive
        }).then(archivedOutcomeLevel => {
            console.log(archivedOutcomeLevel);
            const levelsCopy = [...levels];
            levelsCopy.forEach((levelCopy, index) => {
                if(levelCopy.OutcomeLevelID === archivedOutcomeLevel.OutcomeLevelID) {
                    levelsCopy[index].ArchivedFLG = archivedOutcomeLevel.ArchivedFLG;
                }
            });
            setLevels(levelsCopy);
        });
    }

    const onSelect = (key, value) => {
        setLevelNM(value);
    }

    const alert = displayAlert ? (
        <tr>
            <td colSpan={5}>
                <div className={alertStyle} role="alert">
                    {alertMSG}
                    <button type="button" className="btn-close" onClick={() => setDisplayAlert(false)} aria-label="Close"></button>
                </div>
            </td>
        </tr>)
        : null

    const archiveButton = level.ArchivedFLG ? (
        <button className="btn btn-danger" onClick={() => handleArchive(0)}>
            Restore Level
        </button>
    )
    : (
        <button className="btn btn-danger" onClick={() => handleArchive(1)}>
            Archive Level
        </button>
    )
        
    if(editable) {
        return (
            <>
                {alert}
                <tr>
                    <td>
                    <ObjectDropdown 
                        id="program-dropdown"
                        items={programs}
                        itemNameKey='ProgramNM'
                        selected={levelProgram}
                        setSelected={setLevelProgram}
                        label="Program"
                        isBig={false}
                    />
                    </td>
                    <td>
                        <ObjectDropdown 
                            id="outcome-dropdown"
                            items={outcomes}
                            itemNameKey='OutcomeNM'
                            selected={levelOutcome}
                            setSelected={setLevelOutcome}
                            label="Outcome"
                            isBig={false}
                        />
                    </td>
                    <td>  
                        <Dropdown
                            id="level-name-dropdown"
                            items={levelNames}
                            onSelect={onSelect}
                            label="Level Name"
                            isBig={false}
                            initialValue={levelNM}
                        />
                    </td>
                    <td> <input
                            type="text" 
                            className="form-control" 
                            value={levelDSC} 
                            onChange={({target}) => setLevelDSC(target.value)} 
                        />
                    </td>
                    <td>
                    <button className="btn" onClick={handleEdit}>
                        <img className="edit" alt="edit" src={saveIcon}/>
                    </button>
                    </td>
                    <td>
                        {archiveButton}
                    </td>
                </tr>
            </>
        )
    } else {
        return (
            <>
                {alert}
                <tr>
                    <td>{levelProgram.ProgramNM}</td>
                    <td>{levelOutcome.OutcomeNM}</td>
                    <td>{levelNM}</td>
                    <td>{levelDSC}</td>
                    <td>
                    <button className="btn" onClick={() => setEditable(true)}>
                        <img className="edit" alt="edit" src={editIcon}/>
                    </button>
                    </td>
                    <td>{level.ArchivedFLG === true ? "Archived" : "Active"}</td>
                </tr>
            </>
        )
    }
}
