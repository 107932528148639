import React from 'react';
import { ObjectMultiSelect, ObjectDropdown } from "../../shared/components";
import SearchInput from '../../shared/components/search-input/search-input';

export default function StudentContactFilter({selectedStudents, setSelectedStudents, students, selectedPrimaryStaff, setSelectedPrimaryStaff, staff, contactTypes, selectedContactTypes, setSelectedContactTypes, setSearchText, startDate, setStartDate, endDate, setEndDate, reviewedAscending, setReviewedAscending}) {
    
    return (
        <div className="row">
            <div className="col">
                <ObjectMultiSelect
                    items={students}
                    itemPrimaryKey="PersonID"
                    itemNameKey="FirstNM"
                    itemNameSecondKey="LastNM"
                    selected={selectedStudents}
                    setSelected={setSelectedStudents}
                    label="Student"
                    isBig={true}
                    selectAll={true}
                />
            </div>
            <div className="col">
                <ObjectMultiSelect
                    items={staff}
                    itemPrimaryKey="PersonID"
                    itemNameKey="FirstNM"
                    itemNameSecondKey="LastNM"
                    selected={selectedPrimaryStaff}
                    setSelected={setSelectedPrimaryStaff}
                    label="Staff"
                    isBig={true}
                    selectAll={true}
                />
            </div>
            <div className="col">
                <ObjectMultiSelect
                    items={contactTypes}
                    itemPrimaryKey="ActivityTypeID"
                    itemNameKey="ActivityTypeNM"
                    selected={selectedContactTypes}
                    setSelected={setSelectedContactTypes}
                    label="Contact Type"
                    isBig={true}
                    selectAll={true}
                />
            </div>
            <div className='col'>
                <SearchInput placeholder={"Contact Titles"} onSearch={setSearchText} />
            </div>
            <div className="col">
                <ObjectDropdown
                    label=""
                    items={[{label: "Any Review", reviewFilter: "any"}, {label: "Reviewed", reviewFilter: "reviewed"}, {label: "Not Reviewed", reviewFilter: "not reviewed"}]}
                    itemNameKey="label"
                    dataKey="reviewFilter"
                    setSelected={setReviewedAscending}
                    selected={reviewedAscending}
                    displayWithLabel
                />
            </div>
            <div className="col">
                <label className="form-label">Start Date</label>
                <input
                    className="form-control"
                    type="date"
                    value={startDate}
                    onChange={({target}) => setStartDate(target.value)}
                />
            </div>
            <div className="col">
                <label className="form-label">End Date</label>
                <input
                    className="form-control"
                    type="date"
                    value={endDate}
                    onChange={({target}) => setEndDate(target.value)}
                />
            </div>
        </div>
    )
}
