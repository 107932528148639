import React from 'react';
import { pickerToTableFormat } from "../../utils/date-format";

export default function InstructionNotesRow({noteData, setNoteData, date}) {
    const [note, noteIndex] = noteData;
    const handleChange = (value) => {
        setNoteData((old) => {
            old[noteIndex].NoteTXT = value;
            return [...old];
        })
    }
    return (
        <tr>
            <td>{pickerToTableFormat(date)}</td>
            <td>{note.NoteTypeNM}</td>
            <td>
                <textarea 
                    className="form-control" 
                    id="student-notes" 
                    rows="1"
                    value={note.NoteTXT}
                    onChange={({target}) => handleChange(target.value)}
                />
            </td>
        </tr>
    )
}
