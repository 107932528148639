import React, {useEffect, useState} from 'react';
import AddStudent from "./add-student";
import StudentTable from "./student-table";
import { PersonApi } from "../../shared/services";

export default function AdminStudentView() {
    const [students, setStudents] = useState([]);
    const [activeFilter, setActiveFilter]  = useState(true);

    useEffect(() => {
        const myPersonApi = new PersonApi();
        if(activeFilter) {
            myPersonApi.getStudents()
                .then(studentList => {
                    setStudents(studentList);
                });
        } else {
            myPersonApi.getAllStudents()
                .then(studentList => {
                    setStudents(studentList);
                });
        }
    }, [activeFilter]);

    return (
        <div>
            <h5 className="title">Add a Student</h5>
            <AddStudent students={students} setStudents={setStudents}/>
            <h5 className="title">Edit Students</h5>
            <div className="form-check form-switch mx-4">
                <input className="form-check-input" type="checkbox" checked={activeFilter} onChange={() => setActiveFilter(!activeFilter)}/>
                <label className="form-check-label">Active Students</label>
            </div>
            <StudentTable students={students} setStudents={setStudents}/>
        </div>
    )
}
