import React, {useState} from 'react';
import { editIcon, saveIcon } from "../../assets/images/images";
import { NewlineText } from '../../shared/components';

export default function StudentMapRow({mapRecord, setMapRecords, handleSave, editable, setEditable}) {
    const [record, setRecord] = useState(mapRecord);

    const setRecordReviewed = () => {
        if (record.ReviewedFLG === 0) {
            setRecord({...record, ReviewedFLG: 1})
            setMapRecords({...record, ReviewedFLG: 1})
        } else {
            setRecord({...record, ReviewedFLG: 0})
            setMapRecords({...record, ReviewedFLG: 0})
        }
    }

    const editButton = editable ? <button className="btn" onClick={handleSave}>
            <img src={saveIcon} alt="edit" className="edit" />
        </button> 
        :<button className="btn"  onClick={() => setEditable(true)}>
            <img src={editIcon} alt="edit" className="edit" />
        </button>

    return (
        <tr>
            <td>{mapRecord.StudentNM}</td>
            <td>{mapRecord.CreatedBy}</td>
            <td>{mapRecord.AttendanceDTSPretty}</td>
            <td>{mapRecord.ClassNM}</td>
            <td>{mapRecord.AttendanceScoreDSC}</td>
            <td>{mapRecord.BehaviorScoreDSC}</td>
            <td>{mapRecord.EngagementScoreDSC}</td>
            <td className="text-start">
                <NewlineText text={mapRecord.AttendanceNotes}/>
            </td>
            <td className="text-start">
                <NewlineText text={mapRecord.ProgressNotes}/>
            </td>
            <td className="text-start">
                <NewlineText text={mapRecord.ListOfNoteTXT}/>
            </td>
            <td><input type="checkbox" checked={mapRecord.ReviewedFLG === 1} disabled={!editable} onClick={setRecordReviewed}/></td>
            <td>{editButton}</td>
        </tr>
    )
}
