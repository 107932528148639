import { getIDToken } from "../../utils/ms-graph-api-call";
const { REACT_APP_API_URL} = process.env;
const headers = { 'Content-Type' : 'application/json' };

export default class OutcomeLevelApi {
    /**
     * method getOutcomeLevels
     * @returns a promised array of all levels
     */
    async getOutcomeLevels() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/outcome-level`, {
            headers: headers
        }).then(response => response.json());
    }

    async getOutcomeLevelsByProgramAndOutcome(ProgramID, OutcomeID) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        let fetchEndpoint = `${REACT_APP_API_URL}/api/outcome-level/filtered`;
        if(ProgramID && OutcomeID) {
            fetchEndpoint = fetchEndpoint.concat(`?ProgramID=${ProgramID}&OutcomeID=${OutcomeID}`);
        } else if(ProgramID && !OutcomeID) {
            fetchEndpoint = fetchEndpoint.concat(`?ProgramID=${ProgramID}`);
        } else if(!ProgramID && OutcomeID) {
            fetchEndpoint = fetchEndpoint.concat(`?OutcomeID=${OutcomeID}`);
        }
        return fetch(fetchEndpoint, {
            headers: headers
        }).then(response => response.json());
    }

    /**
     * method addOutcomeLevel
     * @param {object} levelObj: an object containing the following values- ProgramID (the program the level is tied to), 
     * OutcomeID (the outcome the level is tied to), LevelNM (typically "Level 1", "Level 2", etc where multiple outcome levels
     * can share the same level name), and LevelDSC (a description of the sub-level task each student needs to complete to pass the level). 
     * @returns the newly created OutcomeLevel with the OutcomeLevelID.
     */
    async addOutcomeLevel(levelObj) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/outcome-level`, {
            method: "POST",
            headers,
            body: JSON.stringify(levelObj)
        }).then(response => response.json());
    }

    /**
     * method editOutcomeLevel
     * @param {object} levelObj: an object containing the following values- the OutcomeLevelID of the outcome level to edit,
     * LevelNM (typically "Level 1", "Level 2", etc where multiple outcome levels
     * can share the same level name), and LevelDSC (a description of the level task each student needs to complete to pass the level).
     * @returns 
     */
    async editOutcomeLevel(levelObj) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;
        
        return fetch(`${REACT_APP_API_URL}/api/outcome-level`, {
            method: "PUT",
            headers,
            body: JSON.stringify(levelObj)
        }).then(response => response.json());
    }

    async archiveOutcomeLevel(levelObj) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;
        
        return fetch(`${REACT_APP_API_URL}/api/outcome-level/archive`, {
            method: "PUT",
            headers,
            body: JSON.stringify(levelObj)
        }).then(response => response.json());
    }
}