import { getIDToken } from "../../utils/ms-graph-api-call";
const {REACT_APP_API_URL} = process.env;
const headers = { 'Content-Type' : 'application/json' };

export default class StudentServiceCoordinatorApi {
    /**
     * Method getStudentServiceCoordinatorRecords
     * @returns an array of all student service coordinator records
     */
    async getStudentServiceCoordinatorRecords() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/service-coordinator`, {
            headers: headers
        }).then(response => response.json());
    }

    /**
     * Method getUnassignedStudents
     * @returns an array of all students unassigned to a service coordinator
     */
     async getUnassignedStudents() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/service-coordinator/unassigned`, {
            headers: headers
        }).then(response => response.json());
    }

    /**
     * Method updateServiceCoordinatorStudents
     * @returns a success message
     */
    async updateServiceCoordinatorStudents(id, studentIDs) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;
        return fetch(`${REACT_APP_API_URL}/api/service-coordinator/${id}`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify({studentIDs})
        }).then(response => response.json());
    }
}