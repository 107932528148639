import React, {useEffect, useState} from 'react';
import StudentSectionTableRow from "./student-section-table-row";
import { PersonApi } from '../../shared/services';

export default function StudentSectionTable({filteredSections}) {
    const [students, setStudents] = useState([]);
    
    useEffect(() => {
        const myPersonApi = new PersonApi();
        myPersonApi.getStudents()
            .then(allStudents => {
                setStudents(allStudents);
            })
    }, []);

    const displaySectionRows = filteredSections.map((section) => {
        return (
            <StudentSectionTableRow key={section.SectionID} section={section} students={students} setStudents={setStudents}/>
        )
    });

    return (
        <div className='table-60'>
        <table className="table table-sm table-hover mx-2 mt-4">
            <thead className="table-light fixed-column-header">
                <tr>
                    <th>TERM</th>
                    <th>SECTION</th>
                    <th>SCHEDULE</th>
                    <th>STUDENTS</th>
                    <th>EDIT STUDENTS</th>
                </tr>
            </thead>
            <tbody>
                {displaySectionRows}
            </tbody>
        </table>
        </div>
        
    )
}