import React, { useEffect, useState } from 'react';
import { FrequencyApi, PersonApi } from "../../shared/services";
import SectionsTableRow from "./sections-table-row";

export default function SectionsTable({ filteredSections, sections, setSections }) {
    const [frequencies, setFrequencies] = useState([]);
    const [staff, setStaff] = useState([]);

    useEffect(() => {
        const myFrequencyApi = new FrequencyApi();
        myFrequencyApi.getFrequencies()
            .then(frequencyList => setFrequencies(frequencyList));

        const myPersonApi = new PersonApi();
        myPersonApi.getInstructors()
            .then(instructorList => {
                setStaff(instructorList);
            });
    }, []);

    const displaySectionRows = filteredSections.map((section, index) => {
        return (
            <SectionsTableRow
                key={section.SectionID}
                section={section}
                sections={sections}
                setSections={setSections}
                frequencies={frequencies}
                staff={staff}
            />
        )
    })

    return (
        <div className='table-60 mx-2'>
            <table className="table table-sm table-hover">
                <thead className="table-light fixed-column-header">
                    <tr>
                        <th>TERM</th>
                        <th>OUTCOME</th>
                        <th>CLASS</th>
                        <th>SECTION</th>
                        <th>PRIMARY STAFF</th>
                        <th>OTHER STAFF</th>
                        <th>EDIT</th>
                        <th>SCHEDULE</th>
                        <th>DELETE</th>
                    </tr>
                </thead>
                <tbody>
                    {displaySectionRows}
                </tbody>
            </table>
        </div>

    )
}
