import React from 'react';
import ClassTableRow from "./class-table-row";

export default function ClassTable({filteredClasses, classTypes, programs, outcomes}) {

    const tableRows = filteredClasses ? filteredClasses.map((classItem) => {
        return (
            <ClassTableRow key={classItem.ClassID} _class={classItem} classTypes={classTypes} programs={programs} outcomes={outcomes}/>
        )
    }) : null;

    return (
        <div className='table-60 mx-0 mt-2'>
            <table className="table table-sm table-hover ">
                <thead className="table-light fixed-column-header">
                    <tr>
                        <th>CLASS NAME</th>
                        <th>CLASS TYPE</th>
                        <th>PROGRAM</th> 
                        <th>OUTCOME</th>
                        <th>REQUIRES PROGRESS</th>
                        <th>ACTIVE</th>
                        <th>EDIT</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </table>
        </div>

    )
}