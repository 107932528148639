import React from 'react';

import StudentTableRow from "./student-table-row";

export default function StudentTable({ students, setStudents }) {

    const studentRows = students.map((student, index) => {
        return (
            <StudentTableRow key={student.PersonID} student={student} students={students} setStudents={setStudents} />
        )
    })
    return (
        <div className='table-60'>
            <table className="table table-sm table-hover">
                <thead className="table-light fixed-column-header">
                    <tr>
                        <th>FIRST NAME</th>
                        <th>LAST NAME</th>
                        <th>ACTIVE</th>
                        <th>EDIT</th>
                    </tr>
                </thead>
                <tbody>
                    {studentRows}
                </tbody>

            </table>
        </div>

    )
}
