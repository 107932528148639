import { getIDToken } from "../../utils/ms-graph-api-call";
const { REACT_APP_API_URL} = process.env;
const headers = { 'Content-Type' : 'application/json' };

export default class StudentLevelApi {
    //Returns all levels for all students.
    async getAllStudentLevels() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/student-level`, {
            headers: headers
        }).then(response => response.json());
    }

    /**
     * method getLevelsByStudentProgramAndOutcome
     * @param {Number} PersonID: the student's PersonID.
     * @param {Number} ProgramID: the ID of the Program.
     * @param {NUmber} OutcomeID: the ID of the Outcome.
     * @returns all student levels for a specified student in a promised array.
     */
    async getLevelsByStudentProgramAndOutcome(PersonID, ProgramID, OutcomeID ) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        let apiEndpoint = `${REACT_APP_API_URL}/api/student-level/filtered?`

        if(PersonID && ProgramID && OutcomeID) {
            apiEndpoint = apiEndpoint.concat(`PersonID=${PersonID}&ProgramID=${ProgramID}&OutcomeID=${OutcomeID}`);
        } else if(PersonID && ProgramID && !OutcomeID) {
            apiEndpoint = apiEndpoint.concat(`PersonID=${PersonID}&ProgramID=${ProgramID}`);
        } else if(PersonID && !ProgramID && OutcomeID) {
            apiEndpoint = apiEndpoint.concat(`PersonID=${PersonID}&OutcomeID=${OutcomeID}`);
        } else if(PersonID && !ProgramID && !OutcomeID) {
            apiEndpoint = apiEndpoint.concat(`PersonID=${PersonID}`);
        } else if(!PersonID && ProgramID && OutcomeID) {
            apiEndpoint = apiEndpoint.concat(`ProgramID=${ProgramID}&OutcomeID=${OutcomeID}`);
        } else if(!PersonID && !ProgramID && OutcomeID) {
            apiEndpoint = apiEndpoint.concat(`OutcomeID=${OutcomeID}`);
        } else if(!PersonID && ProgramID && !OutcomeID) {
            apiEndpoint = apiEndpoint.concat(`ProgramID=${ProgramID}`);
        }
        return fetch(apiEndpoint, {
            headers: headers
        }).then(response => response.json());
    }

    /**
     * method addStudentLevel
     * @param {object} levelObj: an object that includes the PersonID of the student and
     * the OutcomeLevelID the Student Level is tracking progress of.
     * @returns the newly created student level
     */
    async addStudentLevel(levelObj) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/student-level`, {
            method: "POST",
            headers,
            body: JSON.stringify(levelObj)
        }).then(response => response.json());
    }

    /**
     * method editStudentLevel
     * @param {object} levelObj: an object that includes the StudentLevelID to edit, CompletedFLG (0 or 1, indicates whether the
     * student has completed this level step), NotApplicableFLG (0 or 1, indicates that the level step isn't applicable to that student),
     * InProgressFLG (0 or 1, indicates that the student is currently working on that level step), ReviewedFLG (0 or 1, indicates that
     * the service coordinator or admin checked off the student level), and the CompletedDTS (the date time stamp the student has 
     * completed this level step). Leave CompletedDTS null unless the student just completed the level step.
     * @returns 
     */
    async editStudentLevel(levelObj) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;
        
        return fetch(`${REACT_APP_API_URL}/api/student-level`, {
            method: "PUT",
            headers,
            body: JSON.stringify(levelObj)
        }).then(response => response.json());
    }
}