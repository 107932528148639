import React, {useEffect, useState} from 'react';
import ActivityStudentNotes from "./activity-student-notes";
import {arrayToStringList} from "../../utils/string-format";
import {databaseToDatePicker, dbTimeToPicker, pickerToTableFormat, dateAndTimeToDatabase, pickerTimeToTable} from "../../utils/date-format";
import {editIcon, saveIcon, deleteIcon} from "../../assets/images/images";
import { Dropdown, ObjectDropdown, MultiSelect } from "../../shared/components";
import { ActivityApi, ActivityDetailApi, NoteApi } from "../../shared/services";
import { useAuth } from "../../context/auth-context";

export default function ActivitiesRow({activity, students, staff, activityTypes}) {
    const [activityNM, setActivityNM] = useState(activity.ActivityNM);
    const [lengthNBR, setLengthNBR] = useState(activity.LengthNBR);
    const [activityType, setActivityType] = useState(activity.ActivityTypeNM);
    const [date, setDate] = useState(databaseToDatePicker(activity.ActivityDTS));
    const [time, setTime] = useState(dbTimeToPicker(activity.ActivityDTS));
    const [selectedStudents, setSelectedStudents] = useState(activity.Students);
    const [selectedPrimary, setSelectedPrimary] = useState(activity.PrimaryStaff);
    const [selectedOtherStaff, setSelectedOtherStaff] = useState(activity.Staff);
    const [activityNote, setActivityNote] = useState(activity.ActivityNoteTXT);
    const [studentNotes, setStudentNotes] = useState([]);
    const [editable, setEditable] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false)

    const { currentUser } = useAuth(); 

    //Mapping out the students and staff connected to the activity row.
    const studentNames = selectedStudents.map((student, index) => student.FirstNM + " " + student.LastNM);
    const staffNames = selectedOtherStaff.map((instructor, index) => instructor.FirstNM + " " + instructor.LastNM);

    //Mapping arrays to simple strings for dropdowns and muliselects to consume.
    const activityTypeStrings = activityTypes.map((type, index) => type.ActivityTypeNM);
    const studentNameOptions = students.map((student, index) => student.FirstNM + " " + student.LastNM);
    const staffNameOptions = staff.map((instructor, index) => instructor.FirstNM + " " + instructor.LastNM);

    useEffect(() => {
        setActivityNM(activity.ActivityNM);
        setLengthNBR(activity.LengthNBR);
        setActivityType(activity.ActivityTypeNM);
        setDate(databaseToDatePicker(activity.ActivityDTS))
        setTime(dbTimeToPicker(activity.ActivityDTS));
        setSelectedStudents(activity.Students);
        setSelectedPrimary(activity.PrimaryStaff);
        setSelectedOtherStaff(activity.Staff);
        setActivityNote(activity.ActivityNoteTXT);
    }, [activity]);

    const onSelect = (key, value) => {
        if(key === "activity-type") {
            setActivityType(value);
        } else if(key === "other-staff") {
            const selStaff = staff.filter(instructor => {
                if(value.indexOf(instructor.FirstNM + " " + instructor.LastNM) === -1) {
                    return false;
                } else {
                    return true;
                }
            });
            setSelectedOtherStaff(selStaff);
        } else if(key === "students") {
            const selStudents = students.filter(student => {
                if(value.indexOf(student.FirstNM + " " + student.LastNM) === -1) {
                    return false;
                } else {
                    return true;
                }
            });
            setSelectedStudents(selStudents);
        }
    }

    const handleDelete = () => {
        const myActivityApi = new ActivityApi();
        if (window.confirm("Are you sure you would like to delete this Contact?")) {
            myActivityApi.deleteActivity(activity.ActivityID);
            setEditable(false)
            setIsDeleted(true)
        }
    }

    const updateActivity = async () => {
        //Update the Activity
        const myActivityApi = new ActivityApi();
        const selectedActivityType = activityTypes.filter(ty => ty.ActivityTypeNM === activityType)[0];
        myActivityApi.editActivity({
            ActivityID: activity.ActivityID,
            ActivityNM: activityNM,
            LengthNBR: lengthNBR,
            ActivityTypeID: selectedActivityType.ActivityTypeID,
            ActivityDTS: dateAndTimeToDatabase(date, time),
        });
        //Update the Activity Note
        const myNoteApi = new NoteApi();
        myNoteApi.editNote({
            NoteID: activity.ActivityNoteID,
            NoteTXT: activityNote.replaceAll('"', "'"),
            NoteModifiedByPersonID: currentUser.PersonID
        });
        //Update the ActivityDetails for PrimaryStaff
        const myActivityDetailApi = new ActivityDetailApi();
        myActivityDetailApi.editActivityDetail({
            ActivityDetailID: activity.PrimaryStaff.ActivityDetailID,
            PersonID: selectedPrimary.PersonID,
            PersonTypeID: 2
        });

        //Identify all students and staff added to details and create activity details for each
        const addedStudents = selectedStudents.filter((stu, index) => {
            const ID = stu.PersonID;
            return activity.Students.findIndex(activityStu => activityStu.PersonID === ID) === -1;
        });
        const addedStaff = selectedOtherStaff.filter((sta, index) => {
            const ID = sta.PersonID;
            return activity.Staff.findIndex(activitySta => activitySta.PersonID === ID) === -1;
        });

        let studentNotesCopy  = [...studentNotes];
        for (let addStu of addedStudents) {
            //each new student needs a note as well.
            const studentNote = await myNoteApi.addNote({
                NoteTypeID: 1, 
                NoteTXT: "", 
                NoteModifiedByPersonID: currentUser.PersonID
            });
            myActivityDetailApi.addActivityDetail({
                ActivityID: activity.ActivityID,
                PersonID: addStu.PersonID,
                PersonTypeID: 1,    //Student is person type of 1
                StudentNoteID: studentNote.NoteID
            });
            studentNotesCopy.push({
                StudentNM: addStu.FirstNM.concat(" ", addStu.LastNM),
                StudentNoteID: studentNote.NoteID,
                StudentNoteTXT: studentNote.NoteTXT
            });
        }

        for (let addSta of addedStaff) {
            myActivityDetailApi.addActivityDetail({
                ActivityID: activity.ActivityID,
                PersonID: addSta.PersonID,
                PersonTypeID: 3,    //Other staff is person type of 3
                StudentNoteID: null //Staff do not have notes
            });
        }
        
        //Identify all students and staff removed from details and delete their activity details
        const removedStudents = activity.Students.filter((activityStu, index) => {
            const ID = activityStu.PersonID;
            return selectedStudents.findIndex(stu => stu.PersonID === ID) === -1;
        });
        const removedStaff = activity.Staff.filter((activitySta, index) => {
            const ID = activitySta.PersonID;
            return selectedOtherStaff.findIndex(sta => sta.PersonID === ID) === -1;
        });
        
        //Each student removed must also have their Note deleted
        for (let remStudent of removedStudents) {
            myNoteApi.deleteNote(remStudent.NoteID);
            studentNotesCopy = studentNotesCopy.filter((stuNote) => {
                return stuNote.StudentNoteID === remStudent.NoteID ? false : true;
            });
            myActivityDetailApi.removeActivityDetail(remStudent.ActivityDetailID);
        }
        for (let remStaff of removedStaff) {
            await myActivityDetailApi.removeActivityDetail(remStaff.ActivityDetailID);
        }
        setStudentNotes(studentNotesCopy);
        setEditable(false);
    }

    if(editable) {
        return (
            <tr>
                <td>
                    <input 
                        className="form-control" 
                        value={activityNM} 
                        onChange={({target}) => setActivityNM(target.value)}
                    />
                </td>
                <td>
                    <input 
                        className="form-control" 
                        type="number" min={0} 
                        value={lengthNBR} 
                        onChange={({target}) => setLengthNBR(target.value)}
                    />
                </td>
                <td>
                    <Dropdown 
                        id="activity-type"
                        items={activityTypeStrings} 
                        onSelect={onSelect}
                        label="Contact Type"
                        isBig={false}
                        initialValue={activityType}
                    />
                </td>
                <td>
                    <input 
                        className="form-control"
                        type="date" 
                        value={date} 
                        onChange={({target}) => setDate(target.value)}
                    />
                </td>
                <td>
                    <input 
                        className="form-control"
                        type="time" 
                        value={time} 
                        onChange={({target}) => setTime(target.value)}
                    />
                </td>
                <td>
                    <MultiSelect 
                        id="students"
                        items={studentNameOptions}
                        onSelect={onSelect}
                        label="Students"
                        isBig={false}
                        selectedItems={studentNames}
                    />
                </td>
                <td>
                    <ObjectDropdown 
                        items={staff}
                        itemNameKey="FirstNM"
                        itemNameSecondKey="LastNM"
                        selected={selectedPrimary}
                        setSelected={setSelectedPrimary}
                        label="Primary Staff"
                        isBig={false}
                    />
                </td>
                <td>
                    <MultiSelect 
                        id="other-staff"
                        items={staffNameOptions}
                        onSelect={onSelect}
                        label="Other Staff"
                        isBig={false}
                        selectedItems={staffNames}
                    />
                </td>
                <td>
                    <textarea 
                        className="form-control" 
                        rows={2} 
                        value={activityNote} 
                        onChange={({target}) => setActivityNote(target.value)}
                    />
                </td>
                <td>
                    <ActivityStudentNotes 
                        selectedActivity={activity} 
                        studentNotes={studentNotes} 
                        setStudentNotes={setStudentNotes}
                    />
                </td>
                <td>
                    <button className="btn" onClick={updateActivity}>
                        <img className="edit" alt="edit" src={saveIcon}/>
                    </button>
                    <button className="btn" onClick={handleDelete}>
                        <img src={deleteIcon} alt="delete" className="delete" />
                    </button>
                </td>
            </tr>
        )
    } else {
        return isDeleted ? "" : (
            <tr>
                <td>{activityNM}</td>
                <td>{lengthNBR}</td>
                <td>{activityType}</td>
                <td>{pickerToTableFormat(date)}</td>
                <td>{pickerTimeToTable(time)}</td>
                <td>{arrayToStringList(studentNames)}</td>
                <td>{selectedPrimary ? selectedPrimary.FirstNM + " " + selectedPrimary.LastNM : "Missing"}</td>
                <td>{arrayToStringList(staffNames)}</td>
                <td>{activityNote}</td>
                <td>
                    <ActivityStudentNotes 
                        selectedActivity={activity}
                        studentNotes={studentNotes} 
                        setStudentNotes={setStudentNotes}
                    />
                </td>
                <td>
                    <button className="btn" onClick={() => setEditable(true)}>
                        <img className="edit" alt="edit" src={editIcon}/>
                    </button>
                </td>
            </tr>
        )
    }
}
