import { getIDToken } from "../../utils/ms-graph-api-call";
const { REACT_APP_API_URL} = process.env;
const headers = {'Content-Type' : 'application/json'};

export default class TermsApi {
    /**
     * method getTerms
     * @returns a promise of an array of terms
     */
    async getTerms() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;
        
        return fetch(`${REACT_APP_API_URL}/api/terms`, {
            headers: headers
        })
        .then(response => response.json());
    }

    /**
     * method addTerm
     * @param {object} termObject: an object describing the new term to add.
     * Please include the following columns: TermNM(string), StartDTS(date string), EndDTS(date string) 
     * @returns a promise of the newly created term objec with the values from the param termObject plus
     * the newly created TermID.
     */
    async addTerm(termObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/terms`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(termObject)
        }).then(response => response.json());
    }

    /**
     * method editTerm
     * @param {object} termObject: an object describing the terms updated values.
     * Please include the following columns: TermID(number), TermNM(string), StartDTS(date string),
     * EndDTS(date string)
     * @returns a promise of the recently updated termObject
     */
    async editTerm(termObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/terms`, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(termObject)
        }).then(response => response.json());
    }

}