import React, { useEffect, useState } from 'react';
import { AttendanceApi, AttendanceScoresApi, BehaviorScoresApi, EngagementScoresApi } from "../../shared/services";
import StudentHistoryRow from "./student-history-row";
import {compareDateStrings} from "../../utils/date-format";

export default function StudentHistoryTab({ section }) {
    const [attendanceData, setAttendanceData] = useState([]);
    const [attendanceValues, setAttendanceValues] = useState([]);
    const [behaviorValues, setBehaviorValues] = useState([]);
    const [engagementValues, setEngagementValues] = useState([]);

    //This useEffect runs when StudentHistoryTab mounts
    useEffect(() => {
        const myAttendanceScoresApi = new AttendanceScoresApi();
        myAttendanceScoresApi.getAttendanceScores()
        .then(attendanceScoresList => {
            setAttendanceValues(attendanceScoresList.filter((score) => score.ActiveFLG === 1));
        });

        const myBehaviorScoresApi = new BehaviorScoresApi();
        myBehaviorScoresApi.getBehaviorScores()
        .then(behaviorScoresList => {
            setBehaviorValues(behaviorScoresList.filter((score) => score.ActiveFLG === 1));
        });

        const myEngagementScoresApi = new EngagementScoresApi();
        myEngagementScoresApi.getEngagementScores()
            .then(engagementScoresList => {
                setEngagementValues(engagementScoresList.filter((score) => score.ActiveFLG === 1));
            });
    }, []);

    //This useEffect runs when section state is updated
    useEffect(() => {
        const myAttendanceApi = new AttendanceApi();
        myAttendanceApi.getStudentAttendanceBySection(section.SectionID)
        .then(attendanceList => {
            setAttendanceData(attendanceList.sort((a,b) => {
                //First, compare dates
                const dateComparison = compareDateStrings(a.AttendanceDTS, b.AttendanceDTS);
                if(dateComparison !== 0) {  //If the dates are the same, then sort by student first name
                    return dateComparison;
                }
                return true;
            }));
        });
    }, [section])

    const attendanceRows = attendanceData.map((attendanceRecord, index) => {
        return (
            <StudentHistoryRow key={attendanceRecord.AttendanceID} index={index} attendanceData={attendanceData} setAttendanceData={setAttendanceData} 
                attendanceRecord={attendanceRecord} section={attendanceRecord} attendanceValues={attendanceValues} 
                behaviorValues={behaviorValues} engagementValues={engagementValues}/>
        )
    });
    return (
        <div className="tab-pane fade show active" id="history" role="tabpanel" aria-labelledby="history-tab">
            <br />
            <div className='table-60'>
                <table className="table table-sm table-hover">
                    <thead className="table-light fixed-column-header">
                        <tr>
                            <th scope="col table-header">DATE</th>
                            <th scope="col table-header">STUDENT</th>
                            <th scope="col table-header">ATTENDANCE</th>
                            <th scope="col table-header">BEHAVIOR</th>
                            <th scope="col table-header">ENGAGEMENT</th>
                            <th scope="col table-header">ATTENDANCE & BEHAVIOR NOTES</th>
                            <th scope="col table-header">ENGAGEMENT & PROGRESS NOTES</th>
                            <th scope="col table-header">EDIT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {attendanceRows}
                    </tbody>
                </table>
            </div>
            
        </div>
    )
}
