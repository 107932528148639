import React, { useEffect, useState } from 'react';
import { ObjectDropdown } from "../../shared/components";
import SectionsTable from "./sections-table";
import SectionAttendance from "../section-attendance";
import { useAuth } from "../../context/auth-context";
import { ClassTypesApi, SectionsApi, TermsApi } from "../../shared/services";
import { compareDateStrings, dateIsCurrent } from "../../utils/date-format";

//React functional component AttendanceViewComponent
export default function AttendanceViewComponent() {
    const { currentUser, isAdmin } = useAuth();    //The currently logged in user from the Database

    const [classType, setClassType] = useState(null);   //The currently selected classType. Starts as null
    const [classTypes, setClassTypes] = useState([]);
    const [term, setTerm] = useState(null); //Which term is currently selected, start as null since we need to fetch the terms
    const [terms, setTerms] = useState([]); //Start as an empty array, will be filled once fetched
    const [sections, setSections] = useState([]);   //All classes that meet the classType, userID, and term. 
    const [currentSections, setCurrentSections] = useState([]); //All sections that meet classType, userID, and term as well as currently running.
    const [showAttendanceForm, setAttendanceForm] = useState(false);
    const [section, setSection] = useState(null);   //The currently selected section to take attendance in. Starts as null.
    const [showCurrent, setShowCurrent] = useState(true);

    useEffect(() => {
        const myClassTypesApi = new ClassTypesApi();
        myClassTypesApi.getClassTypes()
            .then(classTypesList => {
                if (classTypesList.length) {
                    setClassTypes(classTypesList);
                }
            });
        const myTermsApi = new TermsApi();
        myTermsApi.getTerms()
            .then(termList => {
                if (termList.length) {
                    termList.sort((a, b) => compareDateStrings(a.EndDTS, b.EndDTS));
                    setTerms(termList);
                }
            });
    }, []);

    //This useEffect is ran everytime the state of term, classType, and currentUser are updated
    useEffect(() => {
        //When that filter is null, it isn't used. That is why instructorID gets a value of null when an admin is logged in.
        const termID = term ? term.TermID : null;
        const classTypeID = classType ? classType.ClassTypeID : null;
        //We want to filter down sections by the person logged in only if they are not a
        const instructorID = currentUser && isAdmin() ? null : currentUser ? currentUser.PersonID : null;
        const mySectionsApi = new SectionsApi();
        //If the user isn't an admin, use the getSectionsByTermStaffAndType function.
        if(currentUser && instructorID) {   //Nothing should show up if no user is logged in, so check that first
            mySectionsApi.getSectionsByTermStaffAndType(termID, instructorID, classTypeID)
                .then(sectionsList => {
                    sectionsList.sort((a,b) => {
                        return compareDateStrings(a.SectionEndDTS, b.SectionEndDTS);
                    })
                    setSections(sectionsList);
                    const filteredSections = sectionsList.filter((sec) => dateIsCurrent(new Date(), new Date(sec.SectionStartDTS), new Date(sec.SectionEndDTS)));
                    setCurrentSections(filteredSections);
                });
        //If the user is an admin, use the getSectionsByTermAndType
        } else if(currentUser) {
            mySectionsApi.getSectionsByTermAndType(termID, classTypeID)
            .then(sectionsList => {
                if (sectionsList.length) {
                    sectionsList.sort((a,b) => {
                        return compareDateStrings(a.SectionEndDTS, b.SectionEndDTS);
                    })
                    setSections(sectionsList);
                    const filteredSections = sectionsList.filter((sec) => dateIsCurrent(new Date(), new Date(sec.SectionStartDTS), new Date(sec.SectionEndDTS)));
                    setCurrentSections(filteredSections);
                }
                
            });
        }
    }, [term, classType, currentUser, isAdmin]);

    //Returns the Attendance page with a simple greeting, two selectors for course type and term, and a list of courses that match the selectors.
    if(!showAttendanceForm) {
        return (
            <div className="container-fluid div-attendance">
                <br/>
                <div className="row">
                    <div className="col-2">
                        <ObjectDropdown 
                            items={classTypes}
                            itemNameKey="ClassTypeNM"
                            selected={classType}
                            setSelected={setClassType}
                            label="Class Type"
                            isBig={true}
                            selectAll={true}
                        />
                    </div>
                    <div className="col-2">
                        <ObjectDropdown 
                            items={terms}
                            itemNameKey="TermNM"
                            selected={term}
                            setSelected={setTerm}
                            label="Term"
                            isBig={true}
                            selectAll={true}
                        />
                    </div>
                    <div className="col-3">
                        <button className="btn btn-primary" onClick={() => setShowCurrent(!showCurrent)}>
                            {showCurrent ? "Show All Sections" : "Show Current Sections"}
                        </button>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <SectionsTable 
                        sections={sections} 
                        currentSections={currentSections} 
                        showCurrent={showCurrent}
                        setSection={setSection} 
                        setSectionView={setAttendanceForm}
                     />
                </div>
            </div>
        );
    } else {    //If a class section is selected, display the takeAttendance form
        return (
            <SectionAttendance section={section} setAttendanceForm={setAttendanceForm}/>
        );
    }
    
}
