import {graphConfig, loginRequest} from "../config/auth-config"; //Allows us to use the token created when a user logs in
import {msalInstance, msalDashboardsInstance} from "./msal-instance"; //Allows us to hook into the connection instance
import { LogApi } from "../shared/services";
const { REACT_APP_POWER_BI_SERVICE_ACCOUNT} = process.env;

//Function callMsGraph
//returns graph data of the currently logged in user or throws an error if there is no logged in user.
export async function callMsGraph() {
    const account = msalInstance.getActiveAccount();    //Grab the logged in user
    if (!account) { //Throws error if no user is logged in and set as the active account.
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    //Grab the token
    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,    //{scopes: ["User.Read"]]}
        account: account
    });

    //setup request header and add token to header
    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;
    headers.append("Authorization", bearer);

    //setup fetch options for a get request and add header with token
    const options = {
        method: "GET",
        headers: headers
    };

    //GraphMeEndpoint is the fetch URI for getting the graph data for the logged in user.
    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())  //Make the data a readable object
        .catch(error => console.log(error));    //Console log any errors thrown
}

export async function getIDToken() {
    const account = msalInstance.getActiveAccount();    //Grab the logged in user
    if (!account) { //Throws error if no user is logged in and set as the active account.
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,    //{scopes: ["User.Read"]]}
        account: account
    });

    return response.idToken;
}


export async function getAccessToken() {
    const myLogApi = new LogApi();
    return msalDashboardsInstance.acquireTokenSilent({
            scopes: ["https://analysis.windows.net/powerbi/api/Report.Read.All"],
            account: REACT_APP_POWER_BI_SERVICE_ACCOUNT
        }).then(response => {
            myLogApi.logToServer({message: response});
            return response.accessToken;
        })
        .catch(error => {
            console.log("Get access token error: ", error);
            myLogApi.logToServer({message: error});
        });
}