import React, {useEffect, useState} from 'react';
import { SectionsApi, TermsApi } from "../../shared/services";
import TermTableFilters from "./term-table-filters";
import TermTable from "./term-table";
import AddTermForm from "./add-term-form";
import { compareDateStrings } from '../../utils/date-format';

export default function AdminTermView() {
    const [terms, setTerms] = useState([]);
    const [filteredTerms, setFilteredTerms] = useState([]);
    const [sections, setSections] = useState([]);

    async function getSections() {
        try {
            const myTermsApi = new TermsApi();
            const termList = await myTermsApi.getTerms()
            termList.sort((a, b) => compareDateStrings(a.EndDTS, b.EndDTS));
            setTerms(termList);
            setFilteredTerms(termList);

            const mySectionsApi = new SectionsApi();
            const sectionList = await mySectionsApi.getSections();
            sectionList.sort((a, b) => a.SectionNM.localeCompare(b.SectionNM));
            // classSections = sectionList.map(section => section.ClassNM)
            setSections(sectionList);
        } catch (e) {
            console.log(e);
        }
      }

    useEffect(() => {
        getSections();
    }, []);

    return (
        <div>
            <h5 className="title">Add New Term</h5>
            <AddTermForm setTerms={setTerms} terms={terms} sections={sections}></AddTermForm>
            <h5 className="title">Current Terms</h5>
            <TermTableFilters terms={terms} setFilteredTerms={setFilteredTerms}/>
            <TermTable filteredTerms={filteredTerms}/>
        </div>
    )
}
