import React, {useEffect, useState} from 'react';
import {ObjectDropdown, SearchBar} from "../../shared/components";

export default function FilterTable({levels, setFilteredLevels, students, student, setStudent, programs, program, setProgram, outcomes, outcome, setOutcome}) {
    const [searchStr, setSearchStr] = useState("");

    useEffect(() => {
        //Filtering to include any terms whose fields contain the search text
        if(searchStr !== "") {
            const tempStr = searchStr.toLowerCase();
            let filtered = levels.filter((level) => {
                return ((level.LevelNM && level.LevelNM.toLowerCase().includes(tempStr)) ||
                        (level.LevelDSC && level.LevelDSC.toLowerCase().includes(tempStr))); 
            });
            setFilteredLevels(filtered);
        } else {
            setFilteredLevels(levels);
        }
    }, [levels, searchStr, setFilteredLevels]);


    return (
        <div className="row mx-3 my-2">
            <div className="col">
                <ObjectDropdown 
                    id="student-dropdown"
                    items={students}
                    itemNameKey='FirstNM'
                    itemNameSecondKey='LastNM'
                    selected={student}
                    setSelected={setStudent}
                    label="Student"
                    isBig={true}
                />
            </div>
            <div className="col">
                <ObjectDropdown 
                    id="program-dropdown"
                    items={programs}
                    itemNameKey='ProgramNM'
                    selected={program}
                    setSelected={setProgram}
                    label="Program"
                    isBig={true}
                    selectAll={true}
                />
            </div>
            <div className="col">
                <ObjectDropdown 
                    id="outcome-dropdown" 
                    items={outcomes}
                    itemNameKey="OutcomeNM"
                    selected={outcome}
                    setSelected={setOutcome}
                    label="Outcome"
                    isBig={true}
                    selectAll={true}
                />
            </div>
            <div className="col-6">
                <div className="col">
                    <SearchBar 
                        onChange={({target}) => setSearchStr(target.value)}
                        placeholder="Search"
                    />
                </div>
            </div>
        </div>
    )
}
