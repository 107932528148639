import { getIDToken } from "../../utils/ms-graph-api-call";
const {REACT_APP_API_URL} = process.env;
const headers = {'Content-Type' : 'application/json'};

export default class InstructionNoteApi {
    /**
     * function getInstructionNotesBySection
     * @param section: the SectionID of the section to get the InstructionNotes of 
     * @returns a promise of an array of Instruction Note objects.
     */
    async getInstructionNotesBySection(section) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/instruction-notes/section/${section}`, {
            headers: headers
        }).then(response => response.json());
    }

    /**
     * function addInstructionNote
     * @param instructionNotes: an object that contains the SectionID and the NoteID's for
     * the 5 InstructionNote types: AssessAdjustNoteID, TaskAnalysisNoteID, ModeledPracticeNoteID,
     * GuidedPracticeNoteID and IndependentPracticeNoteID.
     * @returns a promise of an object representing the newly added instructionNote entry. Includes all
     * values included in the param instructionNotes plus the primary key: SectionInstructionNoteID.
     */
    async addInstructionNote(instructionNotes) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/instruction-notes`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(instructionNotes)
        }).then(response => response.json());
    }

    async editInstructionNoteDate(instructionNoteObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/instruction-notes/date`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(instructionNoteObject)
        }).then(response => response.json());
    }

    async deleteInstructionNote(SectionInstructionNoteID) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/instruction-notes/${SectionInstructionNoteID}`, {
            method: 'DELETE',
            headers: headers
        }).then(response => response.json());
    }
}