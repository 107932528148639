import React, { useState } from 'react';
import { NoteApi } from "../../shared/services";
import { useAuth } from "../../context/auth-context";
import { editIcon, saveIcon } from "../../assets/images/images";
import { databaseToTableFormat } from "../../utils/date-format";
import { NewlineText } from '../../shared/components';

export default function InstructionHistoryRow({instruction}) {
    const [editable, setEditable] = useState(false);
    const [note, setNote] = useState(instruction)
    const { currentUser } = useAuth();

    const editHistoryRow = () => {
        const myNoteApi = new NoteApi();

        myNoteApi.editNote({
            NoteID: note.NoteID,
            NoteTXT: note.NoteTXT,
            NoteModifiedByPerson: currentUser.PersonID
        });

        setEditable(false);
    }

    if(editable) {
        return (
            <tr>
                <td>{databaseToTableFormat(note.SectionNoteDTS)}</td>
                <td>
                    {note.NoteTypeNM}
                </td> 
                <td>
                    <textarea 
                        className="form-control"
                        id="task-analysis"
                        rows="1"
                        value={note.NoteTXT}
                        onChange={({target}) => setNote(prev => ({...prev, NoteTXT: target.value}))}
                    />
                </td>
                <td>
                    <button type="button" className="btn" onClick={editHistoryRow}>
                        <img className="edit" alt="edit" src={saveIcon}/>
                    </button>
                </td>
            </tr>
        )
    } else {
        return (
            <tr>
                <td>{databaseToTableFormat(note.SectionNoteDTS)}</td>
                <td>
                    <NewlineText text={note.NoteTypeNM}/>
                </td> 
                <td>
                    <NewlineText text={note.NoteTXT}/>
                </td>
                <td>
                    <button type="button" className="btn" onClick={() => setEditable(true)}>
                        <img className="edit" alt="edit" src={editIcon}/>
                    </button>
                </td>
            </tr>
        )
    }
    
}
