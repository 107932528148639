import React, {useState} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { SectionsApi } from '../../shared/services';

export default function DeleteSectionModal({section, editable, sections, setSections}) {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const handleDelete = () => {
        const mySectionsApi = new SectionsApi();
        mySectionsApi.deleteSection(section.SectionID).then(_ => {
            setSections();

            toggle();
        });
    }

    return (
        <>
            <button className="btn btn-danger" disabled={!editable} onClick={toggle}>
                Delete Section
            </button>
            <Modal isOpen={modal} toggle={toggle} fade={false}>
                <ModalHeader>
                    Deleting Section
                </ModalHeader>
                <ModalBody>
                    <p>Are you sure you want to delete this section? This action cannot be undone.</p>
                    <button type="button" className="btn btn-danger" onClick={handleDelete}>Delete Section</button>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-secondary" onClick={() => setModal(false)}>Cancel</button>
                </ModalFooter>
            </Modal>
        </>
    )
}
