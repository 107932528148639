import React, {useEffect, useState} from 'react';
import { SearchBar } from "../../shared/components";


export default function ClassTableFilters({classes, setFilteredClasses, classTypes, programs, outcomes}) {
    const [searchStr, setSearchStr] = useState("");

    useEffect(() => {
        const classTypeStrings = classTypes.map((cType => cType.ClassTypeNM));
        const programStrings = programs.map((pType => pType.ProgramNM));
        const outcomeStrings = outcomes.map((oType => oType.OutcomeNM));
        //Filtering to include any terms whose fields contain the search text
        if(searchStr !== "") {
            const tempStr = searchStr.toLowerCase();
            let filtered = classes.filter((_class) => {
                const foundInOutcome = _class.OutcomeID ? 
                    outcomeStrings[_class.OutcomeID - 1].toLowerCase().includes(tempStr) : false;
                return (classTypeStrings[_class.ClassTypeID - 1].toLowerCase().includes(tempStr) || 
                        programStrings[_class.ProgramID - 1].toLowerCase().includes(tempStr) || 
                        _class.ClassNM.toLowerCase().includes(tempStr) || foundInOutcome);
            });
            [].slice.call(filtered).sort((a, b) =>  a.ClassNM.localeCompare(b.ClassNM));
            setFilteredClasses(filtered);
        } else {
            [].slice.call(classes).sort((a, b) =>  a.ClassNM.localeCompare(b.ClassNM))
            setFilteredClasses(classes);
        }
    }, [searchStr, classes, classTypes, outcomes, programs, setFilteredClasses]);

    return (
        <div className="row">
            <div className="col-8"></div>
            <div className="col">
                <SearchBar 
                    onChange={({target}) => setSearchStr(target.value)}
                    placeholder="Search"
                />
            </div>
        </div>
    )
}
