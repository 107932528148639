import React, {useEffect, useState} from 'react';
import { AttendanceApi, AttendanceScoresApi } from "../../shared/services";
import InstructorHistoryRow from "./instructor-history-row";
import { compareDateStrings } from '../../utils/date-format';

export default function InstructorHistoryTab({ section }) {
    const [attendanceData, setAttendanceData] = useState([]);
    const [attendanceValues, setAttendanceValues] = useState([]);

    //This useEffect runs when InstructorHistoryTab is mounted
    useEffect(() => {
        const myAttendanceScoresApi = new AttendanceScoresApi();
        myAttendanceScoresApi.getAttendanceScores()
        .then(attendanceScoresList => {
            const filteredAttendanceScores = attendanceScoresList.filter( attendSc => {
                return attendSc.AttendanceScoreDSC === "Present" ||
                attendSc.AttendanceScoreDSC === "No classes held" ||
                attendSc.AttendanceScoreDSC === "Absent";
            });
            setAttendanceValues(filteredAttendanceScores);
        })
    }, []);

    //This useEffect runs when the state of section is updated
    useEffect(() => {
        const myAttendanceApi = new AttendanceApi();
        myAttendanceApi.getInstructorAttendanceBySection(section.SectionID)
        .then(attendanceList => {
            setAttendanceData(attendanceList.sort((a,b) => {
                return compareDateStrings(a.AttendanceDTS, b.AttendanceDTS);
            }));
        });
    }, [section]);

    const tempRows = attendanceData.map((attendanceRecord, index) => {
        return (
            <InstructorHistoryRow key={index} index={index} attendanceData={attendanceData} setAttendanceData={setAttendanceData}
                section={section} attendanceRecord={attendanceRecord} attendanceValues={attendanceValues}/>
        )
    });

    return (
        <div className="tab-pane fade show active" id="instructor-history" role="tabpanel" aria-labelledby="instructor-history-tab">
            <br />
            <div className='table-60'>
                <table className="table table-sm table-hover">
                    <thead className="table-light fixed-column-header">
                        <tr>
                            <th scope="col table-header">DATE</th>
                            <th scope="col table-header">INSTRUCTOR</th>
                            <th scope="col table-header">ATTENDANCE</th>
                            <th scope="col table-header">ATTENDANCE NOTES</th>
                            <th scope="col table-header">EDIT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tempRows}
                    </tbody>
                </table>
            </div>
            
        </div>
    )
}
