import React, {useState} from 'react';
import {databaseToDatePicker, dbTimeToPicker, pickerToTableFormat, pickerTimeToTable} from "../../utils/date-format";
import { editIcon, saveIcon } from "../../assets/images/images";
import { NewlineText } from '../../shared/components';


export default function StudentContactRow({contactRecord, setContactRecords, handleSave, editable, setEditable}) {
    const [record, setRecord] = useState(contactRecord);

    const editButton = editable ? 
        <button className="btn" onClick={handleSave}>
            <img src={saveIcon} alt="edit" className="edit" />
        </button> 
        :<button className="btn"  onClick={() => setEditable(true)}>
            <img src={editIcon} alt="edit" className="edit" />
        </button>

    const setRecordReviewed = () => {
        if (record.ReviewedFLG === 0) {
            setRecord({...record, ReviewedFLG: 1})
            setContactRecords({...record, ReviewedFLG: 1})
        } else {
            setRecord({...record, ReviewedFLG: 0})
            setContactRecords({...record, ReviewedFLG: 0})
        }
    }

    return (
        <tr>
            <td>{contactRecord.StudentNM}</td>
            <td>{contactRecord.PrimaryStaffNM}</td>
            <td>{contactRecord.ActivityNM}</td>
            <td>{contactRecord.ActivityTypeNM}</td>
            <td>{pickerToTableFormat(databaseToDatePicker(contactRecord.ActivityDTS))}</td>
            <td>{pickerTimeToTable(dbTimeToPicker(contactRecord.ActivityDTS))}</td>
            <td className="text-start">
                <NewlineText text={contactRecord.ActivityNote}/>
            </td>
            <td className="text-start">
                <NewlineText text={contactRecord.StudentNote}/>
            </td>
            <td><input type="checkbox" checked={contactRecord.ReviewedFLG === 1} disabled={!editable} onClick={setRecordReviewed}/></td>
            <td>{editButton}</td>
        </tr>
    )
}
