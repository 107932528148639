import React, {useEffect, useState} from 'react';
import { ClassApi, DashboardApi, TermsApi } from '../../shared/services';
import StudentMapTable from "./student-map-table";
import StudentMapFilter from "./student-map-filter";
import { compareDateStrings } from '../../utils/date-format';

export default function StudentMap({currentTerm}) {
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState([]);
    const [selectedTerms, setSelectedTerms] = useState(currentTerm);
    const [terms, setTerms] = useState([]);
    const [program, setProgram] = useState(null);
    const [selectedPrograms, setSelectedPrograms] = useState([]);
    const [selectedClasses, setSelectedClasses] = useState([]);
    const [classes, setClasses] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [tableLoading, setTableLoading] = useState(false)
    const [reviewedAscending, setReviewedAscending] = useState({label:"Any Review", reviewedFilter: "any"});

    useEffect(() => {
        let ignore = false;
        async function fetchData() {
            if(selectedStudents.length !== 0) {
                const myDashboardApi = new DashboardApi();
                const PersonIDs = selectedStudents ? selectedStudents.map(student => student.PersonID) : null;
                let termList = await myDashboardApi.getStudentTerms(PersonIDs)
                let classList = await myDashboardApi.getStudentClasses(PersonIDs)

                if (!ignore) {
                    setTerms(termList.sort((a,b) => {
                        return compareDateStrings(a.EndDTS, b.EndDTS);
                    }));

                    setClasses(classList);
                }

            } else {
                const myTermsApi = new TermsApi();
                let termList = await myTermsApi.getTerms()
                const myClassApi = new ClassApi();
                const classList = await myClassApi.getClasses()

                if (!ignore) {
                    setTerms(termList.sort((a,b) => {
                        return compareDateStrings(a.EndDTS, b.EndDTS);
                    }));
                    setClasses(classList);
                }
            }
        }

        fetchData();
        return () => { ignore = true; }

    }, [selectedStudents]);

    return (
        <div>
            <StudentMapFilter
                selectedStudents={selectedStudents}
                setSelectedStudents={setSelectedStudents}
                selectedStaff={selectedStaff}
                setSelectedStaff={setSelectedStaff}
                selectedTerms={selectedTerms}
                setSelectedTerms={setSelectedTerms}
                terms={terms}
                setTerms={setTerms}
                classes={classes}
                setClasses={setClasses}
                program={program}
                setProgram={setProgram}
                selectedPrograms={selectedPrograms}
                setSelectedPrograms={setSelectedPrograms}
                selectedClasses={selectedClasses}
                setSelectedClasses={setSelectedClasses}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                tableLoading={tableLoading}
                reviewedAscending={reviewedAscending}
                setReviewedAscending={setReviewedAscending}
            />
            <StudentMapTable
                selectedStudents={selectedStudents}
                selectedTerms={selectedTerms}
                program={program}
                selectedPrograms={selectedPrograms}
                selectedClasses={selectedClasses}
                selectedStaff={selectedStaff}
                startDate={startDate}
                endDate={endDate}
                tableLoading={tableLoading}
                setTableLoading={setTableLoading}
                reviewedAsc={reviewedAscending}
            />
        </div>
    )
}
