import React, {useState} from 'react';
import { editIcon, saveIcon } from '../../assets/images/images';
import { PersonApi } from "../../shared/services";

export default function StudentTableRow({student, students, setStudents}) {
    const [editable, setEditable] = useState(false);
    const [activeFLG, setactiveFLG] = useState(student.ActiveFLG);
    const [firstNM, setFirstNM] = useState(student.FirstNM);
    const [lastNM, setLastNM] = useState(student.LastNM);

    const handleEdit = async () => {
        const myPersonApi = new PersonApi();
        const editedStudent = await myPersonApi.editStudent({
            PersonID : student.PersonID,
            FirstNM : firstNM,
            LastNM : lastNM,
            ActiveFLG : activeFLG
        });
        const studentsCopy = [...students];
        studentsCopy.forEach((stu, index) => {
            if(editedStudent.PersonID === stu.PersonID) {
                studentsCopy[index] = editedStudent;
            }
        });
        setStudents(studentsCopy);
        setEditable(false);
    }

    if(editable) {
        return (
            <tr>
                <td>
                    <input 
                        className="form-control" 
                        type="text" 
                        value={firstNM} 
                        onChange={({target}) => setFirstNM(target.value)}
                    />
                </td>
                <td>
                    <input 
                        className="form-control" 
                        type="text" 
                        value={lastNM} 
                        onChange={({target}) => setLastNM(target.value)}
                    />
                </td>
                <td>
                    <input
                        className="form-check-input"
                        type="checkbox" 
                        disabled={!editable}
                        checked={activeFLG === 1 ? true : false}
                        onChange={() => setactiveFLG(activeFLG === 1 ? 0 : 1)}
                    />
                </td>
                <td>
                    <button className="btn" onClick={handleEdit}>
                        <img className="edit" src={saveIcon} alt="save"/>
                    </button>
                </td>
            </tr>
        )
    } else {
        return (
            <tr>
                <td>{firstNM}</td>
                <td>{lastNM}</td>
                <td>
                    <input
                        className="form-check-input"
                        type="checkbox" 
                        disabled={!editable}
                        checked={activeFLG === 1 ? true : false}
                        onChange={() => setactiveFLG(activeFLG === 1 ? 0 : 1)}
                    />
                </td>
                <td>
                    <button className="btn" onClick={() => setEditable(true)}>
                        <img className="edit" src={editIcon} alt="edit" />
                    </button>
                </td>
            </tr>
        ) 
    }
}
