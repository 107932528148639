import React, { useState } from 'react';
import { editIcon, saveIcon } from "../../assets/images/images";
import { Dropdown } from "../../shared/components";
import { ClassApi } from "../../shared/services";

export default function ClassTableRow({_class, classTypes, programs, outcomes}) {
    const [editable, setEditable] = useState(false);
    const [classType, setClassType] = useState(_class.ClassTypeID);  //Holds the value of the selected class type
    const [program, setProgram] = useState(_class.ProgramID);  //Keeps track of selected program for the class
    const [outcome, setOutcome] = useState(_class.OutcomeID);  //Keeps track of outcome for the class
    const [classNM, setClassNM] = useState(_class.ClassNM);
    const [active, setActive] = useState(_class.ActiveFLG);
    const [requiresProgress, setRequiresProgress] = useState(_class.RequireProgressFLG);

    //Create arrays of strings that each dropdown will use as options.
    const classTypeStrings = classTypes.map((cType => cType.ClassTypeNM));
    const programStrings = programs.map((pType => pType.ProgramNM));
    const outcomeStrings = outcomes.map((oType => oType.OutcomeNM));

    const saveEdit = async () => {
        //Import service class to access class backend
        const myClassApi = new ClassApi();

        //Edit the class provided
        const editedClass = await myClassApi.editClass({
            ProgramID: program, 
            OutcomeID: outcome, 
            ClassTypeID: classType,
            ClassID: _class.ClassID,
            ClassNM: classNM,
            ActiveFLG: active,
            RequireProgressFLG: requiresProgress
        });
        _class = editedClass;
        setEditable(false);
    }

    const setOption = (key, value) => {
        if (key === "class-dropdown") {
            const matchedClassType = classTypes.filter(classType => classType.ClassTypeNM === value)[0];
            setClassType(matchedClassType.ClassTypeID);
            _class.ClassTypeID = matchedClassType.ClassTypeID;
        } else if (key === "program-dropdown") {
            const matchedProgram = programs.filter(program => program.ProgramNM === value)[0];
            setProgram(matchedProgram.ProgramID);
            _class.ProgramID = matchedProgram.ProgramID;
        } else if (key === "outcome-dropdown") {
            const matchedOutcome = outcomes.filter(outcome => outcome.OutcomeNM === value)[0];
            setOutcome(matchedOutcome.OutcomeID);
            _class.OutcomeID = matchedOutcome.OutcomeID;
        }
    };

    // Creating the edit button
    const editButton = editable ? 
        <button className="btn" onClick={saveEdit}>
            <img className="edit" alt="edit" src={saveIcon}/>
        </button> : 
        <button className="btn" onClick={() => setEditable(true)}>
            <img className="edit" alt="edit" src={editIcon}/>
        </button>

    
    if(editable) {
        return (
            <tr>
                <td>
                    <input className="form-control" value={classNM} onChange={({target}) => setClassNM(target.value)}/>
                </td>
                <td>
                    <Dropdown 
                        id="class-dropdown" 
                        items={classTypeStrings} 
                        initialValue={classTypeStrings[classType - 1]} 
                        label="Class Type" 
                        onSelect={setOption} 
                        isBig={true}
                    />
                </td>
                <td>
                    <Dropdown 
                        id="program-dropdown" 
                        items={programStrings} 
                        initialValue={programStrings[program - 1]}
                        label="Program" 
                        onSelect={setOption} 
                        isBig={true}
                    />
                </td>
                <td>
                    <Dropdown 
                        id="outcome-dropdown" 
                        items={outcomeStrings} 
                        initialValue={outcomeStrings[outcome - 1]}
                        label="Outcome" 
                        onSelect={setOption} 
                        isBig={true}
                    />
                </td>
                <td>
                    <input type="checkbox"
                        className="form-check-input"
                        id="progress"
                        onChange={() => setRequiresProgress(requiresProgress === 1 ? 0 : 1)}
                        checked={requiresProgress === 1 ? true : false}
                        disabled={!editable}
                    />
                </td>
                <td>
                    <input type="checkbox" 
                        className="form-check-input" 
                        id="active" 
                        onChange={() => setActive(active === 1 ? 0 : 1)}
                        checked={active === 1 ? true : false}
                        disabled={!editable}
                    />
                </td>
                <td>{editButton}</td>
            </tr>
        )
    } else {
        return (
            <tr>
                <td>{classNM}</td>
                <td>{classTypeStrings[classType-1]}</td>
                <td>{programStrings[program-1]}</td>
                <td>{outcomeStrings[outcome-1]}</td>
                <td>
                    <input type="checkbox"
                        className="form-check-input"
                        id="progress"
                        checked={requiresProgress === 1 ? true : false}
                        disabled={!editable}
                    />
                </td>
                <td>
                    <input type="checkbox" 
                        className="form-check-input" 
                        id="active" 
                        checked={active}
                        disabled={!editable}
                    />
                </td>
                <td>{editButton}</td>
            </tr>
        )
    }
}
