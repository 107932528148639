import React, {useEffect, useState} from 'react';
import "./drop-down.css";
import "./multi-select.css";

export default function MultiSelect({ id, items, onSelect, label, isBig, selectedItems }) {
    const [selected, setSelected] = useState(selectedItems ? selectedItems : []);

    useEffect(() => {
        if(selectedItems) {
            setSelected(selectedItems);
        }
    }, [selectedItems]);

    const handleSelect = (id, value) => {
        let selectedCopy = [...selected];
        let index = selectedCopy.indexOf(value);
        if(index !== -1) {  //If the item was previously selected, unselect it
            selectedCopy.splice(index, 1);
        } else {    //If the item wasn't previously selected, select it
            selectedCopy.push(value);
        }
        setSelected(selectedCopy);
        onSelect(id, selectedCopy);
    }

    //Generates all dropdown items
    const dropdownItems = items.map((item, index) => {
        let dropdownItemStyle = "";
        let selectedIndex = selected.findIndex(clickedItem => clickedItem === item);
        if(selectedIndex !== -1) {
            dropdownItemStyle = "btn btn-dropdown-primary dropdown-item"
        } else {
            dropdownItemStyle = "btn btn-dropdown-selected dropdown-item"
        }
        return (
            <button type="button" key={index} value={item} className={dropdownItemStyle}
                onClick={(event) => handleSelect(id, event.target.value)}
            >
                {item}
            </button>
        )
    });

    const style = isBig ? "btn btn-outline-dark dropdown-toggle" 
        : "btn btn-outline-dark dropdown-toggle btn-sm";

    return (
        <div className="dropdown">
            <button className={ style }
                type="button" id="dropdownMenuButton" data-toggle="dropdown" 
                aria-haspopup="true" aria-expanded="false"
            >
                {selected.length} {label}(s) selected
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {dropdownItems}
            </div>
        </div>
    )
}
