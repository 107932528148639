import React, {useEffect, useState} from 'react';
import { PersonApi, PersonTypeApi } from '../../shared/services';
import UserTable from './user-table';
import AddUser from "./add-user";

export default function AdminUsersView() {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [personTypes, setPersonTypes] = useState([]);
    const [activeFilter, setActiveFilter]  = useState(true);

    useEffect(() => {
        const myPersonApi = new PersonApi();
        myPersonApi.getAllNonStudents()
            .then(userList => {
                setUsers(userList);
            });

        const myPersonTypeApi = new PersonTypeApi();
        myPersonTypeApi.getPersonTypes()
            .then(personTypeList => {
                setPersonTypes(personTypeList.filter((type) => type.PersonTypeID !== 1 && type.PersonTypeID !== 3));  //Removes students and the secondary staff.
            });
    }, []);

    useEffect(() => {
        setFilteredUsers(users.filter(ul => (ul.ActiveFLG === 1) === activeFilter))
    }, [activeFilter, users])

    return (
        <div>
            <h5 className="title">
                Add User
            </h5>
            <AddUser
                users={users} 
                setUsers={setUsers}
                personTypes={personTypes}
            />
            <h5 className="title">
                Edit Users
            </h5>
            <div className="form-check form-switch mx-4">
                <input className="form-check-input" type="checkbox" checked={activeFilter} onChange={() => setActiveFilter(!activeFilter)}/>
                <label className="form-check-label">Active Users</label>
            </div>
            <UserTable 
                users={filteredUsers} 
                setUsers={setUsers} 
                personTypes={personTypes}
            />
        </div>
    )
}
