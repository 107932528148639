import React, {useState} from 'react';
import { databaseToTableFormat } from "../../utils/date-format";
import { Dropdown, NewlineText } from "../../shared/components";
import { editIcon, saveIcon } from "../../assets/images/images";
import { AttendanceApi, NoteApi } from "../../shared/services";
import { useAuth } from "../../context/auth-context";

export default function InstructorHistoryRow({attendanceRecord, attendanceValues}) {
    const [editable, setEditable] = useState(false);
    const [notes, setNotes] = useState(attendanceRecord.AttendanceNoteTXT);
    const [attendanceScoreDSC, setAttendanceScoreDSC] = useState(attendanceRecord.AttendanceScoreDSC);
    const [attendanceScoreID, setAttendanceScoreID] = useState(attendanceRecord.AttendanceScoreID);
    const { currentUser } = useAuth();

    const editHistoryRow = () => {
        const myAttendanceApi = new AttendanceApi();
        const myNoteApi = new NoteApi();
        myNoteApi.editNote({
            NoteID: attendanceRecord.AttendanceNoteID,
            NoteTXT: notes,
            NoteModifiedByPersonID: currentUser.PersonID
        });
        myAttendanceApi.editAttendance({
            AttendanceID: attendanceRecord.AttendanceID,
            ClassID: attendanceRecord.ClassID,
            PersonID: attendanceRecord.InstructorID,
            AttendanceScoreID: attendanceScoreID,
        });
        setEditable(false);
    }

    /**
     * function setOption: used to manage the values of all dropdowns
     * @param key: the key of the dropdown the user interacted with. Won't be used in this case, but Dropdown needs it here to work.
     * @param value: the value that the user selected within the dropdown
     */
     const setOption = (key, value) => {
        const selectedAttendanceScore = attendanceValues.filter(attendanceSc => attendanceSc.AttendanceScoreDSC === value)[0];
        setAttendanceScoreID(selectedAttendanceScore.AttendanceScoreID);
        setAttendanceScoreDSC(selectedAttendanceScore.AttendanceScoreDSC);
    };

    const attendanceOptions = attendanceValues.map((option, index) => option.AttendanceScoreDSC);

    if(editable) {
        return (
            <tr>
                <td>{databaseToTableFormat(attendanceRecord.AttendanceDTS)}</td>
                <td>{attendanceRecord.InstructorFirstNM + " " + attendanceRecord.InstructorLastNM}</td>
                <td>
                    <Dropdown 
                        id="attendance-dropdown" 
                        items={attendanceOptions} 
                        label="Attendance" 
                        onSelect={setOption}
                        initialValue={attendanceScoreDSC}    
                    />
                </td>
                <td>
                    <textarea 
                        className="form-control" 
                        id="exampleFormControlTextarea1" 
                        rows="1"
                        value={notes}
                        onChange={({target}) => setNotes(target.value)}
                    />
                </td>
                <td>
                    <button type="button" className="btn" onClick={editHistoryRow}>
                        <img className="edit" alt="edit" src={saveIcon}/>
                    </button>
                </td>
            </tr>
        )
    } else {
        return (
            <tr>
                <td>{databaseToTableFormat(attendanceRecord.AttendanceDTS)}</td>
                <td>{attendanceRecord.InstructorFirstNM + " " + attendanceRecord.InstructorLastNM}</td>
                <td>{attendanceScoreDSC}</td>
                <td>
                    <NewlineText text={notes}/>
                </td>
                <td>
                    <button type="button" className="btn" onClick={() => setEditable(true)}>
                        <img className="edit" alt="edit" src={editIcon}/>
                    </button>
                </td>
            </tr>
        )
    }
    
}
