import React, {useEffect, useState} from 'react';
import ServiceCoordinatorTableRow from "./service-coordinator-table-row";
import { PersonApi, StudentServiceCoordinatorApi } from "../../shared/services";
import UnassignedStudents from "./unassigned-students";

export default function ServiceCoordinatorTable() {
    const [students, setStudents] = useState([]);
    const [serviceCoordRecords, setServiceCoordRecords] = useState([]);
    const [unassignedStudents, setUnassignedStudents] = useState([]);
    const [myStudentServiceCoordinatorApi] = useState(()=>new StudentServiceCoordinatorApi());

    useEffect(() => {
        async function fetchData() {
            const myPersonApi = new PersonApi();
            myPersonApi.getStudents()
                .then(studentList => {
                    setStudents(studentList);
                });

            myStudentServiceCoordinatorApi.getStudentServiceCoordinatorRecords()
                .then(coordinatorRecordList => {
                    setServiceCoordRecords(coordinatorRecordList)
                });

            myStudentServiceCoordinatorApi.getUnassignedStudents()
                .then(unassigned => {
                    setUnassignedStudents(unassigned)
                })
        }
        fetchData();
    }, [myStudentServiceCoordinatorApi]);

    const handleEdit = async (serviceCoordinatorPersonID, updatedIds) => {
        await myStudentServiceCoordinatorApi.updateServiceCoordinatorStudents(serviceCoordinatorPersonID, updatedIds);
        myStudentServiceCoordinatorApi.getUnassignedStudents()
            .then(unassigned => {
                setUnassignedStudents(unassigned)
            })
    }
    
    const coordinatorRows = serviceCoordRecords.map((record) => {
        return <ServiceCoordinatorTableRow key={record.ServiceCoordinatorPersonID} record={record} students={students} onEdit={handleEdit} />
    });

    return (
        <div className="row mt-2">
            <div className="col-4">
                <UnassignedStudents unassignedStudents={unassignedStudents}/>
            </div>
            <div className="col">
                <table className="table table-sm table-hover mx-2">
                    <thead className="table-light">
                        <tr>
                            <th>SERVICE COORDINATORS</th>
                            <th>STUDENTS</th>
                            <th>EDIT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {coordinatorRows}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
