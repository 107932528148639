import { getIDToken } from "../../utils/ms-graph-api-call";
const { REACT_APP_API_URL} = process.env;
const headers = {'Content-Type' : 'application/json'};

export default class SectionScheduleApi {
    /**
     * method getSectionSchedules
     * @returns a promise of an array of all section schedules
     */
    async getSectionSchedules() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/section-schedule`, {
            headers: headers
        }).then(response => response.json());
    }

    /**
     * method getSectionSchedule
     * @param {number} SectionScheduleID: the ID of the section schedule requested
     * @returns a promise of an array of all section schedules
     */
    async getSectionSchedule(SectionScheduleID) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/section-schedule/${SectionScheduleID}`,{
            headers: headers
        }).then(response => response.json());
    }

    /**
     * method addSectionSchedule
     * @param {*} sectionScheduleObj: Includes the following-  SectionID, FrequencyID, MondayStartTS, TuesdayStartTS, 
     * WednesdayStartTS, ThursdayStartTS, FridayStartTS, SaturdayStartTS,SundayStartTS, MondayEndTS, TuesdayEndTS, 
     * WednesdayEndTS, ThursdayEndTS, FridayEndTS, SaturdayEndTS, SundayEndTS
     * @returns the newly created section schedule
     */
    async addSectionSchedule(sectionScheduleObj) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/section-schedule`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(sectionScheduleObj)
        }).then(response => response.json());
    }
    
    /**
     * method addSectionSchedule
     * @param {*} sectionScheduleObj: Includes the following-  SectionScheduleID, FrequencyID, MondayStartTS, TuesdayStartTS, 
     * WednesdayStartTS, ThursdayStartTS, FridayStartTS, SaturdayStartTS,SundayStartTS, MondayEndTS, TuesdayEndTS, 
     * WednesdayEndTS, ThursdayEndTS, FridayEndTS, SaturdayEndTS, SundayEndTS
     * @returns the updated section schedule
     */
    async editSectionSchedule(sectionScheduleObj) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;
        
        return fetch(`${REACT_APP_API_URL}/api/section-schedule`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(sectionScheduleObj)
        }).then(response => response.json());
    }
}