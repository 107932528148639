export default function structureLevelData(levelData) {
    const restructuredData = [];
    for(let levelDataItem of levelData) {
        //If the Outcome has been added to the restructuredData array, add the Level data to it
        const index = restructuredData.findIndex(item => item.OutcomeNM === levelDataItem.OutcomeNM);
        if(index !== -1) {
            const LEVELKEY = levelDataItem.LevelNM.replace(" ", "");
            restructuredData[index][LEVELKEY] = levelDataItem.LevelPercentage;
        //Otherwise create new object to track that Outcome
        } else {
            const dataItem = {OutcomeNM: levelDataItem.OutcomeNM};
            const LEVELKEY = levelDataItem.LevelNM.replace(" ", "");
            dataItem[LEVELKEY] = levelDataItem.LevelPercentage;
            restructuredData.push(dataItem);
        }
    }
    return restructuredData;
}