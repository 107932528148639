import React, { useEffect, useState } from 'react';
import StudentMap from "./student-map";
import StudentScorecard from "./student-scorecard";
import StudentContactMap from './student-contact-map';
import EmbeddedBi from './embedded-bi';
import { EmbedDashboardApi } from '../../shared/services/embed-dashboard.api';
import { DashboardApi } from '../../shared/services';
export default function DashboardViewComponent() {
    const [currentView, setCurrentView] = useState("student-map");
    const [accessToken, setAccessToken] = useState(null);
    const [currentTerm, setCurrentTerm] = useState([])

    useEffect(() => {
        const myEmbedDashboardApi = new EmbedDashboardApi();
        myEmbedDashboardApi.getAccessToken()
            .then(token => {
                setAccessToken(token.access_token);
            })
        const dashboardAPI = new DashboardApi();

        dashboardAPI.getCurrentTerm()
            .then(term => setCurrentTerm(term))
    }, []);

    const pageToDisplay = currentView === "student-map" ? <StudentMap currentTerm={currentTerm}/>
        : currentView === "scorecard" ? <StudentScorecard/>
        : currentView === "student-contact" ? <StudentContactMap/>
        : <EmbeddedBi accessToken={accessToken} />

    const activeTab = "nav-link adm-link active";
    const passiveTab = "nav-link adm-link ";

    return (<>
        {(currentTerm.length) ? (
            <div>
            <ul className="nav nav-tabs mt-2 mb-2" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className={currentView === "student-map" ? activeTab : passiveTab} onClick={() => setCurrentView("student-map")}>
                        STUDENT MAP
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={currentView === "student-contact" ? activeTab : passiveTab} onClick={() => setCurrentView("student-contact")}>
                        STUDENT CONTACT MAP
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={currentView === "scorecard" ? activeTab : passiveTab} onClick={() => setCurrentView("scorecard")}>
                        STUDENT SCORECARD
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={currentView === "embedded-dashboard" ? activeTab : passiveTab} onClick={() => setCurrentView("embedded-dashboard")}>
                        EMBEDDED DASHBOARD
                    </button>
                </li>
            </ul>
            <div>
                {pageToDisplay}
            </div>
        </div>
        ): ""}
    </>)
}
