//React and React Hooks.
import React, { useContext, useEffect, useState } from "react";
//Azure msal context. Allows us to get the value of inProgress
import { useMsal } from "@azure/msal-react";
//Allows us to check the value of inProgress
import { InteractionStatus } from "@azure/msal-browser";
//An API call to get logged in user information from a token
import { callMsGraph } from "../utils/ms-graph-api-call";
//API functions
import { PersonApi } from "../shared/services";

const AuthContext = React.createContext();  
const ADMIN_PERSON_TYPES = [ 6, 10 ]
const POWERBI_PERSON_TYPES = [ 5, 6, 8, 9 ]
//useAuth is a custom hook that will give access all items inside AuthProvider's value object
export function useAuth() {
    return useContext(AuthContext);
}
//React Functional Provider AuthProvider
//Param children: all React components that are children of AuthProvider
//Returns a React context provider for authentication context.
export function AuthProvider({ children }) {
    const { inProgress } = useMsal(); //Is MSAL working on something
    const [graphData, setGraphData] = useState(null);   //Where we store the Azure AD account information
    const [currentUser, setCurrentUser] = useState(null);

    //If inProgress (inside the dependency array) ever changes value, run this useEffect. 
    useEffect(() => {
        //If no interaction is in progress, then fetch the user graph data and update the state of graphData
        //If something goes wrong, console log the issue
        if(inProgress === InteractionStatus.None) {
            callMsGraph()
                .then(response => setGraphData(response))
                .catch(error => console.log(error.message));
        }
    }, [inProgress]);

    useEffect(() => {
        if(graphData) {
            const myPersonApi = new PersonApi();
            myPersonApi.getPersonByEmail(graphData.userPrincipalName)
                .then(response => {
                    setCurrentUser(response);

                    if (response.PersonTypeID === 1) {
                        window.location.href = "https://scenicviewtc.xantiedev.com";
                    }
                }).catch(error => console.log(error.message));
        }
    }, [graphData]);

    const isAdmin = () => {
        if(currentUser && ADMIN_PERSON_TYPES.includes(currentUser.PersonTypeID)) {
            return true;
        } else {
            return false;
        }
    }

    const isPowerBIAdmin = () => {
        if(currentUser && POWERBI_PERSON_TYPES.includes(currentUser.PersonTypeID)) {
            return true;
        } else {
            return false;
        }
    }

    //Place all functions and state variables a child of AuthProvider will need access to inside this value object
    const value = {
        currentUser, //The user data in the database fetched from our server
        isAdmin,
        isPowerBIAdmin
    }

    //Returns a context provider for Authentication. Any child of this component can access everything
    //inside value by using the custom useAuth hook.
    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

