import React, {useEffect, useState} from 'react';
import FilterTable from "./filter-table";
import LevelsTable from "./levels-table";
import LevelsPercentageTable from "./levels-percentage-table";
import { StudentLevelApi, PersonApi, ProgramApi, OutcomeApi } from "../../shared/services";

export default function LevelsViewComponent() {
    const [levels, setLevels] = useState([]);
    const [filteredLevels, setFilteredLevels] = useState([]);
    const [students, setStudents] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [outcomes, setOutcomes] = useState([]);
    const [student, setStudent] = useState();
    const [program, setProgram] = useState();
    const [outcome, setOutcome] = useState();

    useEffect(() => {
        const myPersonApi = new PersonApi();
        myPersonApi.getStudents()
            .then(studentList => {
                if (studentList.length) {
                    setStudents(studentList);
                    setStudent(studentList[0]); //Default to the first student
                }
            });
        
        const myProgramApi = new ProgramApi();
        myProgramApi.getPrograms()
            .then(programList => {
                if(programList.length) {
                    setPrograms(programList)
                }
            });
        
        const myOutcomeApi = new OutcomeApi();
        myOutcomeApi.getOutcomes()
            .then(outcomeList => {
                if (outcomeList.length) {
                    setOutcomes(outcomeList)
                }
            });
    }, []);

    useEffect(() => {
        const myStudentLevelApi = new StudentLevelApi();
        const PersonID = student ? student.PersonID : null;
        const ProgramID = program ? program.ProgramID : null;
        const OutcomeID = outcome ? outcome.OutcomeID : null;

        if(student) {
            myStudentLevelApi.getLevelsByStudentProgramAndOutcome(PersonID, ProgramID, OutcomeID)
                .then(levelsList => {
                    const sortedLevels = levelsList.sort((a,b) => {
                        //First sort by program
                        const programCompare = a.ProgramID - b.ProgramID;
                        //if both items have the same program, then sort by outcome
                        if(programCompare === 0) {  
                            const outcomeCompare = a.OutcomeID - b.OutcomeID;
                            //if both items also have the same outcome, sort by Level number
                            if(outcomeCompare === 0) {
                                return a.LevelNM > b.LevelNM ? 1 : -1;
                            } else {
                                return outcomeCompare;
                            }
                        } else {
                            return programCompare;
                        }
                    });
                    setLevels(sortedLevels)
                });
        }
    }, [student, program, outcome, setLevels]);

    return (
        <div className="d-flex flex-row">
            <div className="p-8">
                <FilterTable 
                    levels={levels} 
                    setFilteredLevels={setFilteredLevels}
                    students={students}
                    student={student}
                    setStudent={setStudent}
                    programs={programs}
                    program={program}
                    setProgram={setProgram}
                    outcomes={outcomes}
                    outcome={outcome}
                    setOutcome={setOutcome}
                />
                <LevelsTable 
                    levels={filteredLevels} 
                    setLevels={setLevels}
                />
            </div>
            <div className="p-1">
                <LevelsPercentageTable levels={levels} student={student}/>
            </div>
        </div>
    )
}
