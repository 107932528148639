import React, {useEffect, useState} from 'react';
import {ObjectDropdown, SearchBar} from "../../shared/components";

export default function FilterTable({ outcomes, programs, outcome, setOutcome, program, setProgram, levels, setFilteredLevels, activeFilter, setActiveFilter }) {
    const [searchStr, setSearchStr] = useState("");
    
    useEffect(() => {
        //Filtering to include any terms whose fields contain the search text
        if(searchStr !== "") {
            const tempStr = searchStr.toLowerCase();
            let filtered = levels.filter((level) => {
                return (level.LevelNM.toLowerCase().includes(tempStr) ||
                        level.LevelDSC.toLowerCase().includes(tempStr)); 
            });
            setFilteredLevels(filtered);
        } else {
            setFilteredLevels(levels);
        }
    }, [searchStr, levels, setFilteredLevels]);
    
    return (
        <div className="row mx-auto my-2">
            <div className='col-3'>
                <div className="form-check form-switch mx-4">
                    <input className="form-check-input" type="checkbox" checked={activeFilter} onChange={() => setActiveFilter(!activeFilter)}/>
                    <label className="form-check-label">Active Levels</label>
                </div>
            </div>
            <div className="col-2"></div>
            <div className="col">
                <ObjectDropdown 
                    id="program-dropdown"
                    items={programs}
                    itemNameKey='ProgramNM'
                    selected={program}
                    setSelected={setProgram}
                    label="Program"
                    isBig={true}
                    selectAll={true}
                />
            </div>
            <div className="col">
                <ObjectDropdown 
                    id="outcomes-dropdown"
                    items={outcomes}
                    itemNameKey='OutcomeNM'
                    selected={outcome}
                    setSelected={setOutcome}
                    label="Outcome"
                    isBig={true}
                    selectAll={true}
                />
            </div>
            <div className="col-4">
                <div className="row">
                    <div className="col-8"></div>
                        <div className="col">
                            <SearchBar 
                                onChange={({target}) => setSearchStr(target.value)}
                                placeholder="Search"
                            />
                    </div>
                </div>
            </div>
        </div>
    )
}
