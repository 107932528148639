import React from 'react';
import './drop-down.css';    //CSS critical for dropdown's functionality

/**
 * React functional component Dropdown
 * @param id: The unique dropdown identifier.
 * @param items: An array of objects to be the different options.
 * @param itemNameKey: the Key of the item name that will act as the name of the option.
 * @param itemNameSecondKey: another key in case the dropdown needs a first and last name of a person.
 * @param selected: the currently selected value
 * @param setSelected: a state change function to update the value of selected.
 * @param label: a string that displays on the dropdown.
 * @param isBig: a boolean describing whether the styling of the dropdown is big or not
 * @param selectAll: a boolean describing whether or not a Select All option is included
 * @returns a dropdown component
 */
export default function ObjectDropdown({ items, itemNameKey, itemNameSecondKey, dataKey, selected, setSelected, setSelectedWithDataKey, label, isBig, selectAll, displayWithLabel}) {

    const handleSelected = (index) => {
        if(setSelectedWithDataKey) {
            setSelectedWithDataKey(items[index], dataKey);
        } else {
            setSelected(items[index]);
        }
    }

    //Generates all dropdown items
    const dropdownItems = items.map((item, index) => {
        const optionString = itemNameSecondKey ? item[itemNameKey] + " " + item[itemNameSecondKey] : item[itemNameKey];
        return (
            <button type="button" key={index} value={index} className="dropdown-item"
                onClick={({target}) => handleSelected(target.value)}
            >
                {optionString}
            </button>
        )
    });
    const displaySelected = selected && itemNameSecondKey ?
        selected[itemNameKey] + " " + selected[itemNameSecondKey]
        : displayWithLabel ? label + " " + selected[itemNameKey]
        : selected ? selected[itemNameKey]
        : label;

    const style = isBig ?
        "btn btn-outline-dark dropdown-toggle"
        : "btn btn-outline-dark dropdown-toggle btn-sm";

    const selectAllOption = selectAll ?
        <button type="button" className="dropdown-item" onClick={() => setSelected(null)}>All {label}s</button>
        : null

    return (
        <div className="dropdown-container">
            <div className="dropdown">
                <button className={ style }
                    type="button" id="dropdownMenuButton" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false"
                >
                    {displaySelected}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {selectAllOption}
                    {dropdownItems}
                </div>
            </div>
        </div>

    )
}
