import React, { useState } from 'react';
import { Dropdown, ObjectDropdown } from "../../shared/components";
import { OutcomeLevelApi, PersonApi, StudentLevelApi } from "../../shared/services";

export default function AddLevel({ levels, setLevels, outcomes, programs }) {
    const [levelOutcome, setLevelOutcome] = useState();
    const [levelProgram, setLevelProgram] = useState();
    const levelNames = ["Level 1", "Level 2", "Level 3", "Level 4"]
    const [levelNM, setLevelNM] = useState();
    const [levelDSC, setLevelDSC] = useState("");
    const [displayAlert, setDisplayAlert] = useState(false);
    const [alertMSG, setAlertMSG] = useState("");
    const [alertStyle, setAlertStyle] = useState("");

    const onSelect = (key, value) => {
        setLevelNM(value);
    }

    const handleSubmit = async () => {
        setDisplayAlert(false);
        if(levelProgram && levelOutcome && levelNM && levelDSC) {
            const myOutcomeLevelApi = new OutcomeLevelApi();
            //First create the outcome level
            const newOutcomeLevel = await myOutcomeLevelApi.addOutcomeLevel({
                ProgramID : levelProgram.ProgramID,
                OutcomeID : levelOutcome.OutcomeID,
                LevelNM : levelNM,
                LevelDSC : levelDSC
            });
            newOutcomeLevel.ProgramNM = levelProgram.ProgramNM;
            newOutcomeLevel.OutcomeNM = levelOutcome.OutcomeNM;
            //Then create a student level connected to the above outcome level for each student
            const myPersonApi = new PersonApi();
            const myStudentLevelApi = new StudentLevelApi();
            const studentList = await myPersonApi.getStudents();
            for(let student of studentList) {
                myStudentLevelApi.addStudentLevel({
                    PersonID: student.PersonID,
                    OutcomeLevelID: newOutcomeLevel.OutcomeLevelID
                });
            }
            //Update list of levels to include newly created level
            let levelsCopy = [...levels];
            levelsCopy.push(newOutcomeLevel);
            //clear out add level fields
            setLevelProgram(null);
            setLevelOutcome(null);
            setLevelDSC("");
            //Alert user of success
            setLevels(levelsCopy);
            setAlertMSG("New Level has been created.");
            setAlertStyle("alert alert-info alert-dismissible");
            setDisplayAlert(true);
        } else {
            setAlertMSG("Please fill out all fields");
            setAlertStyle("alert alert-warning alert-dismissible");
            setDisplayAlert(true);
        }
    }

    const alert = displayAlert ? (
        <tr>
            <td colSpan={5}>
                <div className={alertStyle} role="alert">
                    {alertMSG}
                    <button type="button" className="btn-close" onClick={() => setDisplayAlert(false)} aria-label="Close"></button>
                </div>
            </td>
        </tr>)
        : null

    return (
        <table className="table table-sm table-hover mx-0 mt-2">
            <thead className="table-light">
                <tr>
                    <th>PROGRAM</th>
                    <th>OUTCOME</th>
                    <th>LEVEL NAME</th>
                    <th>STEP DESCRIPTION</th>
                    <th>ADD LEVEL</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>
                    <ObjectDropdown 
                        id="program-dropdown"
                        items={programs}
                        itemNameKey='ProgramNM'
                        selected={levelProgram}
                        setSelected={setLevelProgram}
                        label="Program"
                        isBig={false}
                    />
                    </td>
                    <td>
                        <ObjectDropdown 
                            id="outcome-dropdown"
                            items={outcomes}
                            itemNameKey='OutcomeNM'
                            selected={levelOutcome}
                            setSelected={setLevelOutcome}
                            label="Outcome"
                            isBig={false}
                        />
                    </td>
                    <td>  
                        <Dropdown
                            id="level-name-dropdown"
                            items={levelNames}
                            onSelect={onSelect}
                            label="Level Name"
                            isBig={false}
                            initialValue={levelNM}
                        />
                    </td>
                    <td> <input
                            type="text" 
                            className="form-control" 
                            value={levelDSC} 
                            onChange={({target}) => setLevelDSC(target.value)} 
                        />
                    </td>
                    <td>
                        <button className="btn btn-primary" onClick={handleSubmit}>Add Level</button>
                    </td>
                </tr>
                {alert}
            </tbody>
        </table>
    )
}
