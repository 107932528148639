import { getIDToken } from "../../utils/ms-graph-api-call";
const { REACT_APP_API_URL} = process.env;
const headers = {'Content-Type' : 'application/json'};

export default class EngagementScoresApi {
    /**
     * method getEngagementScores
     * @returns a promise of an array of all engagement scores
     */
    async getEngagementScores() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/engagementScores`, {
            headers: headers
        }).then(response => response.json());
    }
    /**
     * method addEngagementScore
     * @param {object} scoreObject: includes the following- EngagementScoreDSC 
     * @returns the newly created Engagement Score.
     */
    async addEngagementScore(scoreObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/engagementScores`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(scoreObject)
        }).then(response => response.json());
    }

    /**
     * method editEngagementScore
     * @param {object} scoreObject: includes the following- EngagementScoreID, EngagementScoreDSC, ActiveFLG
     * @returns the updated engagement score.
     */
    async editEngagementScore(scoreObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;
        
        return fetch(`${REACT_APP_API_URL}/api/engagementScores`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(scoreObject)
        }).then(response => response.json());
    }
}