import React, {useEffect, useState} from 'react';
import { SectionScheduleApi, SectionsApi, SectionRollApi } from "../../shared/services";
import { ObjectDropdown, ObjectMultiSelect } from "../../shared/components";
import ScheduleModal from "./schedule-modal";
import {editIcon, saveIcon} from "../../assets/images/images";
import { arrayToStringList } from '../../utils/string-format';
import DeleteSectionsModal from "./delete-section-modal";

const mySectionsApi = new SectionsApi();
const mySectionRollApi = new SectionRollApi();
const mySectionScheduleApi = new SectionScheduleApi();

export default function SectionsTableRow({section, frequencies, staff, sections, setSections}) {
    const [editable, setEditable] = useState();
    const [schedule, setSchedule] = useState();
    const [sectionNM, setSectionNM] = useState();
    const [primaryStaff, setPrimaryStaff] = useState();
    const [primaryStaffInit, setPrimaryStaffInit] = useState();
    const [otherStaffInit, setOtherStaffInit] = useState();
    const [otherStaff, setOtherStaff] = useState([]);
    const [instructorRoll, setInstructorRoll] = useState([]);
    const [staffHasChanged, setStaffHasChanged] = useState(false);
    const [nonPrimaryStaff, setNonPrimaryStaff] = useState([]);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        // This effect runs when 'data' changes
        setStaffHasChanged(true);
    }, [otherStaff, primaryStaff, sectionNM]);

    useEffect(() => {
        if(section) {
            const selStaff = staff.filter((sta, index) => sta.PersonID === section.SectionStaffID)[0];
            setPrimaryStaff(selStaff);
            setSectionNM(section.SectionNM);

            const otherInstructorIds = section.other_instructors_ids ? section.other_instructors_ids.split(", ") : []

            setOtherStaff(staff.filter((staffMember, index) => otherInstructorIds.includes(staffMember.PersonID.toString())));

            setNonPrimaryStaff(staff.filter((staff) => staff.PersonID !== section.SectionStaffID));
        }
    }, [section, staff]);

// Cannot read properties of undefined (reading 'SectionRollID')

    const handleEdit = () => {
        if(sectionNM !== "" && primaryStaff) {
            if (staffHasChanged) {
                setSaving(true);
                const editSectionProm = mySectionsApi.editSection({
                    SectionID: section.SectionID,
                    SectionNM: sectionNM,
                    SectionStaffID: primaryStaff.PersonID
                });

                const otherInstructorRolls = otherStaff.map((staffMember, index) => ({
                    PersonID: staffMember.PersonID
                }))
    
                const newInstructorRolls = [{
                    PersonID : primaryStaff.PersonID,
                }, ...otherInstructorRolls]
    
                const editInstructorRollsProm = mySectionRollApi.editInstructor({newInstructorRolls, SectionID: section.SectionID, ClassID: section.ClassID});

                setStaffHasChanged(false);

                Promise.all([editSectionProm, editInstructorRollsProm]).then(_ => {
                    setSections().then(s => {
                        setEditable(false);
                        setSaving(false);
                    })
                });
            } else {
                setEditable(false);
            }
        } else {
            setEditable(false);
        }
    }

    if(editable) {
        return (
            <tr>
                <td>{section.TermNM}</td>  
                <td>{section.OutcomeNM}</td>  
                <td>{section.ClassNM}</td>  
                <td>
                    <input className="form-control" type="text" value={sectionNM} onChange={({target}) => setSectionNM(target.value)} />
                </td>  
                <td>
                    <ObjectDropdown 
                        items={staff}
                        itemNameKey="FirstNM"
                        itemNameSecondKey="LastNM"
                        selected={primaryStaff}
                        setSelected={setPrimaryStaff}
                        label="Staff"
                        isBig={false}
                    />
                </td>
                <td>
                    <ObjectMultiSelect 
                        items={nonPrimaryStaff}
                        itemPrimaryKey="PersonID"
                        itemNameKey="FirstNM"
                        itemNameSecondKey="LastNM"
                        selected={otherStaff}
                        setSelected={setOtherStaff}
                        label="Staff Member"
                        isBig={false}
                    />
                </td>
                <td>
                    <button className="btn" onClick={handleEdit}>
                        <img className="edit" alt="edit" src={saveIcon}/>
                    </button>
                </td> 
                <td>
                    <ScheduleModal 
                        SectionScheduleID={section.SectionScheduleID}
                        frequencies={frequencies} 
                        schedule={schedule} 
                        setSchedule={setSchedule}
                    />
                </td>
                <td>
                    <DeleteSectionsModal 
                        section={section}
                        editable={editable}
                        sections={sections}
                        setSections={setSections}
                    />
                </td>
            </tr>
        )
    } else {
        return (
            <tr>
                <td>{section.TermNM}</td>  
                <td>{section.OutcomeNM}</td>  
                <td>{section.ClassNM}</td>  
                <td>{sectionNM}</td>  
                <td>{primaryStaff ? primaryStaff.FirstNM : section.StaffFirstNM} {primaryStaff ? primaryStaff.LastNM : section.StaffLastNM}</td>
                <td>{section.other_instructors}</td>
                <td>
                    {saving ? <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                        </div>
                    </div> : <button className="btn" onClick={() => setEditable(true)}>
                        <img className="edit" alt="edit" src={editIcon}/>
                    </button>}
                </td>  
                <td>
                    <ScheduleModal 
                        SectionScheduleID={section.SectionScheduleID}
                        frequencies={frequencies} 
                        schedule={schedule} 
                        setSchedule={setSchedule}
                    />
                </td>
                <td>
                    <DeleteSectionsModal 
                        section={section}
                        editable={editable}
                        sections={sections}
                        setSections={setSections}
                    />
                </td>
            </tr>
        )
    }
}
