import { getIDToken } from "../../utils/ms-graph-api-call";
const { REACT_APP_API_URL} = process.env;
const headers = {'Content-Type' : 'application/json'};

export default class AttendanceScoresApi {
    /**
     * method getAttendanceScores
     * @returns a promise of an array of all attendance scores
     */
    async getAttendanceScores() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/attendanceScores`, {
            headers: headers
        })
        .then(response => response.json());
    }

    /**
     * method addAttendanceScore
     * @param {object} scoreObject: includes the following- AttendanceScoreDSC 
     * @returns the newly created Attendance Score.
     */
    async addAttendanceScore(scoreObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/attendanceScores`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(scoreObject)
        }).then(response => response.json());
    }

    /**
     * method editAttendanceScore
     * @param {object} scoreObject: includes the following- AttendanceScoreID, AttendanceScoreDSC and ActiveFLG
     * @returns the updated attendance score.
     */
    async editAttendanceScore(scoreObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/attendanceScores`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(scoreObject)
        }).then(response => response.json());
    }
} 