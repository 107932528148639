import { getIDToken } from "../../utils/ms-graph-api-call";
const { REACT_APP_API_URL} = process.env;
const headers = {'Content-Type' : 'application/json'};

export default class AttendanceApi {
    /**
     * function getAttendanceBySection
     * @param section: the SectionID of the attendance records 
     * @returns a promise of an array of attendance records that match the requested section
     */
    async getAttendanceBySection(section) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/attendance/section/${section}`, {
            headers: headers
        }).then(response => response.json());
    }

    /**
     * function getStudentAttendanceBySection
     * @param section: the SectionID of the attendance records
     * @returns a promise of an array of student attendance records that match the requested section
     */
    async getStudentAttendanceBySection(section) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/attendance/students/${section}`, {
            headers: headers
        }).then(response => response.json());
    }
    /**
     * function getInstructorAttendanceBySection
     * @param section: the SectionID of the attendance records. A number.
     * @returns a promise of an array of instructor attendance records for the requested section
     */
    async getInstructorAttendanceBySection(section) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/attendance/instructors/${section}`, {
            headers: headers
        }).then(response => response.json());
    }

    async getSectionHistory(section) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/attendance/section/history/${section}`, {
            headers: headers
        }).then(response => response.json());
    }

    /**
     * Class function addAttendance
     * @param attendanceObject: an object containing the ClassID, SectionID, PersonID, AttendanceScoreID, EngagementScoreID, BehaviorScoreID,
     * AttendanceNoteID, AttendanceProgressNoteID, and the ModifiedByPersonID for the new attendance row. 
     * Make sure to create any notes first, then grab the NoteID's to include them.
     * If the attendance is for an instructor, leave the EngagementScoreID, BehaviorScoreID and AttendanceProgressNoteID null.
     * @returns the newly created attendance row as a promised object with all of the above columns with the AttendanceID (primary key).
     */
    async addAttendance(attendanceObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/attendance`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(attendanceObject)
        }).then(response => response.json());
    }

    //Unlike addAttendance, which only writes one attendance record, addAttendanceRobust writes all attendance records for given day,
    //including all notes attached.
    async addAttendanceRobust(attendanceObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/attendance/attendance`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(attendanceObject)
        }).then(response => response.json());
    }

    /**
     * 
     * @param attendanceObject: an object containing the AttendanceID of the attendance row to edit. Also include all columns
     * of the attendance table. 
     * @returns the newly updated attendance row as a promised object.
     */
    async editAttendance(attendanceObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/attendance`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(attendanceObject)
        }).then(response => response.json());
    }
    
    /**
     * function editAttendanceScore
     * @param attendanceObject: an object with the AttendanceID, the new AttendanceScoreID, and the ModifiedByPersonID
     * @returns the updated Attendance Record
     */
    async editAttendanceScore(attendanceObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/attendance/attendance-score`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(attendanceObject)
        }).then(response => response.json());
    }

    /**
     * function editBehaviorScore
     * @param attendanceObject: an object with the AttendanceID, the new BehaviorScoreID, and the ModifiedByPersonID
     * @returns the updated Attendance Record
     */
    async editBehaviorScore(attendanceObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/attendance/behavior-score`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(attendanceObject)
        }).then(response => response.json());
    }

    /**
     * function editEngagementScore
     * @param attendanceObject: an object with the AttendanceID, the new EngagementScoreID, and the ModifiedByPersonID
     * @returns the updated Attendance Record
     */
    async editEngagementScore(attendanceObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/attendance/engagement-score`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(attendanceObject)
        }).then(response => response.json());
    }

    async editReviewed(attendanceObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/attendance/reviewed`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(attendanceObject)
        }).then(response => response.json());
    }

    async editDate(attendanceObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/attendance/date`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(attendanceObject)
        }).then(response => response.json());
    }

    async editSectionNotes(data) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/attendance/date/section`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(data)
        }).then(response => response.json());
    }

    async deleteAttendanceRecord(data) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;
        
        return fetch(`${REACT_APP_API_URL}/api/attendance/`, {
            method: 'DELETE',
            body: JSON.stringify(data),
            headers
        }).then(response => response.json());
    }

    async deleteSectionNotes(data) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;
        
        return fetch(`${REACT_APP_API_URL}/api/attendance/section/notes`, {
            method: 'DELETE',
            body: JSON.stringify(data),
            headers
        }).then(response => response.json());
    }
}