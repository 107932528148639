import React from 'react';
import ManageTitlesTable from "./manage-titles-table";

export default function AdminManageTitlesView() {
    return (
        <div>
            <h3 className="title">Manage Titles</h3>
            <ManageTitlesTable/>
        </div>
    )
}
