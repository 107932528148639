//const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

/**
 * function currentToDatePicker
 * @param dateTime: a date object 
 * @returns the date represented as a string format YYYY-MM-DD, the format input of type 'date' takes.
 */
export const currentToDatePicker = (dateTime) => {
    const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1;  //Since month is zero indexed, add 1 to result of getMonth.
    const monthZero = month < 10 ? "0" : "";    //Places a zero in front of the month when month is less than 10.
    const day = dateTime.getDate();
    const dayZero = day < 10 ? "0" : "";
    return `${year}-${monthZero}${month}-${dayZero}${day}`;
}

export const databaseToDatePicker = (dateString) => {
    const year = dateString.slice(0, 4);
    const month = dateString.slice(5, 7);
    const day = dateString.slice(8, 10);
    return year.concat("-", month, "-", day);
}
/**
 * function toDatebaseFormat
 * @param {*} dateTimePickerInput 
 * @returns 
 */
export const pickerToDatabaseFormat = (datePickerInput) => {
    const dateAndTime = datePickerInput.concat("T12:00");
    const currentDate = new Date(dateAndTime);
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const monthZero = month < 10 ? "0" : "";
    const day = currentDate.getDate();
    const dayZero = day < 10 ? "0" : "";
    return `${year}-${monthZero}${month}-${dayZero}${day} 12:00:00 -06:00`;
}

/**
 * function pickerToTableFormat
 * @param {*} datePickerInput 
 * @returns string representing date in MM/DD/YYYY format
 */
export const pickerToTableFormat = (datePickerInput) => {
    //Adding a time prevents the date from being goofed up due to time zone differences.
    const dateAndTime = datePickerInput.concat("T12:00");
    const date = new Date(dateAndTime);
    return date.toLocaleDateString();
}

/**
 * function dateAndTimeToDatabase
 * @param {string} datePickerInput: a string generated by input type date
 * @param {string} timePickerInput: a string generated by input type time
 * @returns a string that represents the date and time that can be stored in the database
 */
export const dateAndTimeToDatabase = (datePickerInput, timePickerInput) => {
    const dateAndTime = datePickerInput.concat("T", timePickerInput);
    const currentDate = new Date(dateAndTime);
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const monthZero = month < 10 ? "0" : "";
    const day = currentDate.getDate();
    const dayZero = day < 10 ? "0" : "";
    const hour = currentDate.getHours();
    const hourZero = hour < 10 ? "0" : "";
    const minutes = currentDate.getMinutes();
    const minutesZero = minutes < 10 ? "0" : "";

    const date = new Date(`${year}-${monthZero}${month}-${dayZero}${day} ${hourZero}${hour}:${minutesZero}${minutes}`);

    return date.toISOString();
}

export const databaseToTableFormat = (databaseDate) => {
    const date = new Date(databaseDate);
    return date.toLocaleDateString();
}

/**
 * 
 * Convert date to input date format YYYY-MM-DD
 * 
 * @param {*} date string or date object
 * @returns {string} in format YYYY-MM-DD
 */
export const dateToInputDate = (date) => {
    if (typeof date === "string") {
        date = new Date(date);
    } else if (!(date instanceof Date)) {
        throw new Error("dateToInputDate: date must be a string or Date object");
    }

    return date.toISOString().split("T")[0];
}

export const dbTimeToPicker = (databaseDate) => {
    const date = new Date(databaseDate);
    const hour = date.getHours();
    const hourZero = hour < 10 ? "0" : "";
    const minutes = date.getMinutes();
    const minutesZero = minutes < 10 ? "0" : "";
    return `${hourZero}${hour}:${minutesZero}${minutes}`;
}

//For the database TS that are converted into dates when returned from sequelize and doesn't include time zone offsets.
export const dbTimeStampToPicker = (databaseDateTimeStamp) => {
    const date = new Date(databaseDateTimeStamp);
    let hour = date.getHours() + 7;
    hour = hour > 24 ? hour - 24 : hour;
    const hourZero = hour < 10 ? "0" : "";
    const minutes = date.getMinutes();
    const minutesZero = minutes < 10 ? "0" : "";
    return `${hourZero}${hour}:${minutesZero}${minutes}`;
}

export const dbTimeToTable = (databaseDate) => {
    const date = new Date(databaseDate);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0'+minutes : minutes;
    return hours + ':' + minutes + ' ' + ampm;
}

export const dbTimeStampToTable = (dbDateTimeStamp) => {
    const date = new Date(dbDateTimeStamp);
    let hour = date.getHours() + 7;
    hour = hour > 24 ? hour - 24 : hour;
    let minutes = date.getMinutes();
    const ampm = hour >= 12 ? 'pm' : 'am';
    hour = hour % 12;
    hour = hour ? hour : 12;
    minutes = minutes < 10 ? '0'+minutes : minutes;
    return hour + ':' + minutes + ' ' + ampm;
}

//Takes a string representing a date in format HH:MM where HH is between 00 and 23
//and returns HH:MM AM format.
export const pickerTimeToTable = (time) => {
    let hours = parseInt(time.slice(0,2));
    let minutes = parseInt(time.slice(3,5));
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0'+minutes : minutes;
    return hours + ':' + minutes + ' ' + ampm;
}

/**
 * function compareDateStrings: used to help sort data by date. Defaults to descending order.
 * To get ascending order, multiply result by -1.
 * @param date1Str: a string representing the first date 
 * @param date2Str: a string representing the second date
 * @returns -1 if date1Str is greater, 1 if date1Str is less, and zero if the two dates are the same
 */
export const compareDateStrings = (date1Str, date2Str) => {
    const date1 = new Date(date1Str);
    const date2 = new Date(date2Str);
    if(date1 > date2) {
        return -1;
    } else if(date1 < date2) {
        return 1;
    } else {
        return 0;
    }
}

/**
 * 
 * @param {string} time1Str: a string describing the hours and minutes of a starting time in this format- HH:MM
 * @param {string} time2Str: a second string with the same format describing the second time
 * @returns true if the first time is before the second time, false otherwise
 */
export const compareTimeStrings = (time1Str, time2Str) => {
    const date1 = new Date();
    const date2 = new Date(date1);
    const hours1 = time1Str.slice(0,2);
    const minutes1 = time1Str.slice(3,5);
    const hours2 = time2Str.slice(0,2);
    const minutes2 = time2Str.slice(3,5);
    date1.setHours(hours1, minutes1);
    date2.setHours(hours2, minutes2);
    if(date1 >= date2) {
        return false;
    } else {
        return true;
    }
}

/**
 * function dateIsBetween
 * @param {Date} date: the date in question 
 * @param {Date} dateStart: the start of the date range to check
 * @param {Date} dateEnd: the end of the date range to check
 * @returns 
 */
export const dateIsBetween = (date, dateStart, dateEnd) => {
    if(date > dateStart && date < dateEnd) { 
        return true;
    } else {
        return false;
    }
}

/**
 * function isSameDate
 * @param {Date} date1 
 * @param {Date} date2 
 * @returns true if both dates are the same day, ignoring the exact hour and minute
 */
const isSameDate = (date1, date2) => {
    return date1.getFullYear() === date2.getFullYear()
        && date1.getMonth() === date2.getMonth()
        && date1.getDate() === date2.getDate()
}

//Similar to dateIsBetween, but expands to include dates that are on the same day as the start or end date
//that happen to occur before the start time or after the end time
export const dateIsCurrent = (date, dateStart, dateEnd) => {
    if(date > dateStart && date < dateEnd) { 
        return true;
    } else if(isSameDate(dateStart, date)) {
        return true;
    } else if(isSameDate(dateEnd, date)) {
        return true;
    } else {
        return false;
    }
}