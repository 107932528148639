import React from 'react';
import { ObjectDropdown } from "../../shared/components";
import {pickerToTableFormat} from "../../utils/date-format";

/**
 * React function component InstructorAttendanceRow
 * @param index: the index of the instructor in the instructors and attendanceData arrays. A number.
 * @param instructor: the instructor to take the attendance of in this row. An object
 * @param attendanceData: a state array containing the attendance record of all instructors. The index of this array contains the data for this instructor
 * @param setAtttendanceData: a useState function to update the value of attendance data.
 * @param attendanceScores: a state array containing all instructor valid options for the attendance score. Includes AttendanceScoreDSC and AttendanceScoreID with each option object.
 * @param date: a state string representing the currently selected date. The day to take attendance of.
 * @returns a table row containing form fields for the given instructor.
 */
export default function InstructorAttendanceRow({ index, instructor, attendanceData, setAttendanceData, attendanceScores, date }) {
        //handles the state of the row's fields.
        const handleFieldEdit = (value, dataKey) => {
            const fieldsCopy = [...attendanceData];
            fieldsCopy[index][dataKey] = value;
            setAttendanceData(fieldsCopy);
        }

    return (
        <tr key={index}>
            <td>{pickerToTableFormat(date)}</td>
            <td>{instructor.FirstNM + " " + instructor.LastNM}</td>
            <td>
            <ObjectDropdown 
                    items={attendanceScores}
                    itemNameKey="AttendanceScoreDSC"
                    dataKey="AttendanceScore"
                    selected={attendanceData[index]?.AttendanceScore}
                    setSelectedWithDataKey={handleFieldEdit}
                    label="Attendance"
                    isBig={false}
                />
            </td>
            <td>
                <textarea 
                    className="form-control" 
                    id="instructor-notes" 
                    rows="1" 
                    onChange={({target}) => handleFieldEdit(target.value, "AttendanceNote")}
                    value={attendanceData[index] ? attendanceData[index].AttendanceNote : ""}
                />
            </td>
        </tr>
    )
}
