import React, {useEffect, useState} from 'react';
import { SearchBar } from "../../shared/components";
import { compareDateStrings } from '../../utils/date-format';

export default function ActivitiesTableFilters({activities, setFilteredActivities}) {
    const [searchStr, setSearchStr] = useState("");

    useEffect(() => {
        //Filtering to include any terms whose fields contain the search text
        if(searchStr !== "") {
            const tempStr = searchStr.toLowerCase();

            function strIncludes(element) {
                return element.includes(tempStr);
            }

            let filtered = activities.filter((activity) => {
                console.log(activity);
                return (activity.ActivityNM.toLowerCase().includes(tempStr) || 
                        activity.Students.map(
                            student => student?.FirstNM?.toLowerCase()
                            + " " + student?.LastNM?.toLowerCase()).some(strIncludes) ||
                        activity.PrimaryStaff?.FirstNM?.toLowerCase().includes(tempStr) ||
                        activity.PrimaryStaff?.LastNM?.toLowerCase().includes(tempStr) ||
                        activity.Staff?.map(staffNM => staffNM?.FirstNM?.toLowerCase()
                            + " " + staffNM?.LastNM?.toLowerCase()).some(strIncludes) ||
                        activity?.ActivityNoteTXT?.toLowerCase().includes(tempStr)) ||
                        activity?.ActivityTypeNM?.toLowerCase().includes(tempStr); 
            });
            
            [].slice.call(filtered).sort((a, b) => compareDateStrings(a.ActivityDTS, b.ActivityDTS));
            setFilteredActivities(filtered);
        } else {
            [].slice.call(activities).sort((a, b) => compareDateStrings(a.ActivityDTS, b.ActivityDTS));
            setFilteredActivities(activities);
        }
    }, [searchStr, activities, setFilteredActivities]);

    return (
        <div className="row">
            <div className="col-8"></div>
            <div className="col">
                <SearchBar 
                    onChange={({target}) => setSearchStr(target.value)}
                    placeholder="Search"
                />
            </div>
        </div>
    )
}
