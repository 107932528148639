import React, {useEffect} from 'react';
import { ObjectDropdown } from "../../shared/components";
import {pickerToTableFormat} from "../../utils/date-format";

/**
 * React function component StudentAttendanceRow
 * @param index: the index of the student's attendance record within attendanceData (see below)
 * @param student: an object describing the student to take the attendance of. Used to display their first and last name
 * @param attendanceData: a state array containing the attendance record of all students, including this one. Needed to update as the user inputs data
 * @param setAttendanceData: a useState function to update the value of attendanceData. Used in tandem with index to update attendanceData for the specific student
 * @param attendanceScores: an array of all possible attendance scores. Used to populate attendance dropdown and update AttendanceScoreID within attendanceData
 * @param engagementScores: an array of all possible engagement scores. Used to populate engagement dropdown and update EngagementScoreID
 * @param behaviorScores: an array of all possible behavior scores. Used to populate behavior dropdown and update BehaviorScoreID
 * @param date: the state of the currently selected date
 * @returns a table row with the student's name, dropdowns, and fields to take their attendance for the day.
 */
export default function StudentAttendanceRow({index, student, attendanceData, setAttendanceData, attendanceScores, engagementScores, behaviorScores, date}) {
    const ABSENT_ATTENDANCE_SCORE = 6;
    const UNRECORDED_BEHAVIOR_SCORE = 5;
    const UNRECORDED_ENGAGEMENT_SCORE = 5;

    //handles the state of the row's fields.
    const handleFieldEdit = (value, dataKey) => {
        const fieldsCopy = [...attendanceData];
        fieldsCopy[index][dataKey] = value;
        setAttendanceData(fieldsCopy);
    }

    useEffect(() => {
        //handles the state of the row's fields.
        const handleFieldEdit = (value, dataKey) => {
        const fieldsCopy = [...attendanceData];
        fieldsCopy[index][dataKey] = value;
        setAttendanceData(fieldsCopy);
    }
        if(attendanceData[index]?.AttendanceScore?.AttendanceScoreID === ABSENT_ATTENDANCE_SCORE) {
            if(attendanceData[index]?.BehaviorScore?.BehaviorScoreID !== UNRECORDED_BEHAVIOR_SCORE) {
                const behaviorScoreIndex = behaviorScores.findIndex(item => item.BehaviorScoreID === UNRECORDED_BEHAVIOR_SCORE);
                handleFieldEdit(behaviorScores[behaviorScoreIndex], "BehaviorScore");
            }

            if(attendanceData[index]?.EngagementScore?.EngagementScoreID !== UNRECORDED_ENGAGEMENT_SCORE) {
                const engagementScoreIndex = engagementScores.findIndex(item => item.EngagementScoreID === UNRECORDED_ENGAGEMENT_SCORE);
                handleFieldEdit(engagementScores[engagementScoreIndex], "EngagementScore");
            }
        }
        
    }, [attendanceData, setAttendanceData, index, behaviorScores, engagementScores]);

    return (
        <tr key={index}>
            <td>{pickerToTableFormat(date)}</td>
            <td>{student.FirstNM + " " + student.LastNM}</td>
            <td>
                <ObjectDropdown 
                    items={attendanceScores}
                    itemNameKey="AttendanceScoreDSC"
                    dataKey="AttendanceScore"
                    selected={attendanceData[index]?.AttendanceScore}
                    setSelectedWithDataKey={handleFieldEdit}
                    label="Attendance"
                    isBig={false}
                />
            </td>
            <td>
                <ObjectDropdown
                    items={behaviorScores}
                    itemNameKey="BehaviorScoreDSC"
                    dataKey="BehaviorScore"
                    selected={attendanceData[index]?.BehaviorScore}
                    setSelectedWithDataKey={handleFieldEdit}
                    label="Behavior"
                    isBig={false}
                />
            </td>
            <td>
                <ObjectDropdown 
                    items={engagementScores}
                    itemNameKey="EngagementScoreDSC"
                    dataKey="EngagementScore"
                    selected={attendanceData[index]?.EngagementScore}
                    setSelectedWithDataKey={handleFieldEdit}
                    label="Engagement"
                    isBig={false}
                />
            </td>
            <td>
                <textarea 
                    className="form-control" 
                    id="student-notes" 
                    rows="1" 
                    value={attendanceData[index] ? attendanceData[index].AttendanceNote : ""} 
                    onChange={({target}) => handleFieldEdit(target.value, "AttendanceNote")}
                />
            </td>
            <td>
                <textarea 
                    className="form-control" 
                    id="student-progress" 
                    rows="1" 
                    value={attendanceData[index] ? attendanceData[index].AttendanceProgressNote : ""} 
                    onChange={({target}) => handleFieldEdit(target.value, "AttendanceProgressNote")}
                />
            </td>
        </tr>
    )
}
