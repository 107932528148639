import React, {useState, useEffect} from 'react';
import ActivitiesTableFilters from './activities-filter';
import ActivitiesForm from "./activities-form";
import ActivitiesTable from "./activities-table";
import { ActivityApi } from "../../shared/services";
import { useAuth } from "../../context/auth-context";
import "./activities.css";

export default function ActivitiesViewComponent() {
    const { currentUser, isAdmin } = useAuth();
    const [activities, setActivities] = useState([]);
    const [filteredActivities, setFilteredActivities] = useState([]);

    useEffect(() => {
        const myActivityApi = new ActivityApi();
        myActivityApi.getActivities()
        .then(activityList => {
            if (activityList.length) {
                //If someone other than an admin is logged in, only show the activities where the user is the primary staff.
                if(currentUser && !isAdmin()) {
                    activityList = activityList.filter((activityItem, index) => {
                        return activityItem?.PrimaryStaff?.PersonID === currentUser?.PersonID;
                    });
                }
                setActivities(activityList);
            }
        });
    }, [currentUser, isAdmin]);

    return (
        <div>
            <ActivitiesForm 
                activities={activities} 
                setActivities={setActivities}
            />
            <ActivitiesTableFilters
                activities={activities}
                setFilteredActivities={setFilteredActivities}
            />
            <ActivitiesTable
                activities={activities}
                filteredActivities={filteredActivities}
                setActivities={setActivities}
            />
        </div>
    )
}
