import React, {useEffect, useState} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { NoteTypeApi } from "../../shared/services";
import { editIcon } from "../../assets/images/images";


export default function InstructionNoteModal({isAdd, selected, noteTypes, setNoteTypes, setSelected}) {
    const [noteTypeNM, setNoteTypeNM] = useState("");
    const [noteTypeDSC, setNoteTypeDSC] = useState("");
    const [active, setActive] = useState(0);
    const [modal, setModal] = useState(false);
    const [alertMSG, setAlertMSG] = useState("");
    const [displayAlert, setDisplayAlert] = useState(false);
    const [alertStyle, setAlertStyle] = useState("");
    const toggle = () => setModal(!modal);
    const myNoteTypeApi = new NoteTypeApi();

    useEffect(() => {
        if(!isAdd && selected) {
            setNoteTypeNM(selected.NoteTypeNM);
            setNoteTypeDSC(selected.NoteTypeDSC);
            setActive(selected.ActiveFLG);
        }
    }, [isAdd, selected]);

    const handleSubmit = async () => {
        setDisplayAlert(false);
        if(noteTypeNM !== "" && noteTypeDSC !== "") {
            const newNoteType = await myNoteTypeApi.addNoteType({
                NoteTypeNM : noteTypeNM,
                NoteTypeDSC : noteTypeDSC,
                NoteTypeShortenedNM : "",
                InstructionNoteFLG : 1  //All of note types will be instruction notes. Attendance Note and Attendance Progress note not changeable here.
            });
            let noteTypesCopy = [...noteTypes];
            noteTypesCopy.push(newNoteType);
            setNoteTypes(noteTypesCopy);
            setModal(false);
        } else {
            setAlertMSG("Please fill out all fields.");
            setAlertStyle("alert alert-warning alert-dismissible");
            setDisplayAlert(true);
        }
    }

    const handleEdit = async () => {
        setDisplayAlert(false);
        if(noteTypeNM !== "" && noteTypeDSC !== "") {
            const editedNoteType = await myNoteTypeApi.editNoteType({
                NoteTypeID: selected.NoteTypeID,
                NoteTypeNM: noteTypeNM,
                NoteTypeDSC: noteTypeDSC,
                NoteTypeShortenedNM: "",
                InstructionNoteFLG: 1,
                ActiveFLG: active
            });
            let noteTypesCopy = [...noteTypes];
            noteTypesCopy.forEach((noteType, index) => {
                if(noteType.NoteTypeID === editedNoteType.NoteTypeID) {
                    noteTypesCopy[index] = editedNoteType;
                }
            });
            setNoteTypes(noteTypesCopy);
            setSelected(editedNoteType);
            setModal(false);
        } else {
            setAlertMSG("Please fill out all fields.");
            setAlertStyle("alert alert-warning alert-dismissible");
            setDisplayAlert(true);
        }
    }

    const alert = displayAlert ? (
        <div className={alertStyle} role="alert">
            {alertMSG}
            <button type="button" className="btn-close" onClick={() => setDisplayAlert(false)} aria-label="Close"></button>
        </div> )
        : null

    if(isAdd) {
        return (
            <>
                <button className="btn btn-primary" onClick={toggle}>Add Instruction</button>
                <Modal isOpen={modal}  toggle={toggle} fade={false}>
                    <ModalHeader>Add Instruction Type</ModalHeader>
                    <ModalBody>
                        {alert}
                        <label className="form-label">Instruction Type Name</label>
                        <input className="form-control" value={noteTypeNM} onChange={({target}) => setNoteTypeNM(target.value)}/>
                        <label className="form-label">Instruction Type Description</label>
                        <input className="form-control" value={noteTypeDSC} onChange={({target}) => setNoteTypeDSC(target.value)}/>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-secondary" onClick={() => setModal(false)}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={handleSubmit}>Add Instruction</button>
                    </ModalFooter>
                </Modal>
            </>
        )
    } else {
        return (
            <>
                <button className="btn" onClick={toggle} disabled={selected ? false : true}>
                    <img className="edit" src={editIcon} alt="edit"/>
                </button>
                <Modal isOpen={modal}  toggle={toggle} fade={false}>
                    <ModalHeader>Edit Instruction Type</ModalHeader>
                    <ModalBody>
                        <label className="form-label">Instruction Type Name</label>
                        <input className="form-control" value={noteTypeNM} onChange={({target}) => setNoteTypeNM(target.value)}/>
                        <label className="form-label">Instruction Type Description</label>
                        <input className="form-control" value={noteTypeDSC} onChange={({target}) => setNoteTypeDSC(target.value)}/>
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" checked={active === 1 ? true : false} onChange={() => setActive(active === 1 ? 0 : 1)}/>
                            <label className="form-check-label">Active</label>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-secondary" onClick={() => setModal(false)}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={handleEdit}>Edit Instruction</button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}
