import 'bootstrap/dist/css/bootstrap.min.css';    //Adds Bootstrap styling to entire project
// import './client/assets/css/styles.css';
import React from 'react';  //React will need to be added to every document where a react component is made
import ReactDOM from 'react-dom';   //Needed only here to create the virtual dom for React to work

    //React Router Dom Browser Router and useHistory hook. Both handles client-side routing
import { BrowserRouter as Router, useHistory} from 'react-router-dom';
    
    //Microsoft Authentication Library packages
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
    
    //State mananagment for Authentication. Powered by UseContext. All children of AuthProvider will have access to auth if needed 
import { AuthProvider } from "./client/context/auth-context";
    
    //Configuration for AzureAD connection.
import { loginRequest } from './client/config/auth-config';
import { msalInstance } from "./client/utils/msal-instance"; //Gives us an instance of a Public Client Application.
import { CustomNavigationClient } from "./client/utils/navigation-client";   //Allows Azure AD to redirect for login and logout.
    
    //Contains all views and React Router Dom routing
import Transitions from "./client/transitions/transitions";

/**
 * React functional component App
 * @param pca: an instance of a Public Client Application
 * @returns 
 */
function App ( {pca} ) {
    //Set up a history for redirecting and allow msalInstance to access it.
    const history = useHistory();
    const navigationClient = new CustomNavigationClient(history);
    pca.setNavigationClient(navigationClient);

    //Uses loginRequest configuration to properly read the token. Consumed by MsalAuthenticationTemplate object
    const authRequest = {
        ...loginRequest //{scopes: ["User.Read"]}
    }

    //MsalProvider allows all children to access Azure AD content and functionality
    //MsalAuthenticationTemplate redirects the user to a Microsoft Login page if not logged in and allows access to page when logged in
    return (
        <div>
            <MsalProvider instance={pca}>
                <AuthProvider>
                    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest}>
                        <Transitions/>
                    </MsalAuthenticationTemplate>
                </AuthProvider>
            </MsalProvider> 
        </div>
    )
}

//ReactDOM.render function creates a virtual DOM
//Router takes the app's various pages/routes and renders them as a single virtual DOM
//pca is an instance of a Public Client Application. This gives our App Azure AD access.
ReactDOM.render(
    <Router>
        <App pca={msalInstance}/>
    </Router>, document.querySelector("#root")
)