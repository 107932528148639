import { getIDToken } from "../../utils/ms-graph-api-call";
const {REACT_APP_API_URL} = process.env;
const headers = {
    'Content-Type' : 'application/json',
    'Accept': 'application/json'
};

export default class NoteApi {
    /**
     * Method getNoteTypes
     * @returns an array of each note type.
     */
    async getNoteTypes() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/notes/note-types`, {
            headers: headers
        }).then(response => response.json());
    }
    /**
     * Method addNote
     * @param noteObject: an object with a NoteTypeID, NoteTXT, and the NoteModifiedByPersonID.
     * @returns the newly created note. Includes above fields, the NoteID (primary key),
     *          the NoteCreateDTS, and the NoteModifiedDTS (Date Time Stamp).
     */
    async addNote(noteObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/notes`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(noteObject)
        }).then(response => response.json());
    }
    /**
     * Method editNote
     * @param noteObject: an object with the NoteID of the note to edit, NoteTXT, and the NoteModifiedByPersonID.
     * @returns the newly edited note. Includes the above fields and the date time stamps.
     */
    async editNote(noteObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/notes`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(noteObject)
        }).then(response => response.json());
    }

    /**
     * Method deleteNote
     * @param {number} noteID: the NoteID of the note to delete. 
     * @returns a message stating the note was deleted.
     */
    async deleteNote(noteID) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/notes/${noteID}`, {
            method: 'DELETE',
            headers: headers
        });
    }
}