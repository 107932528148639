import React from 'react';
import ServiceCoordinatorTable from "./service-coordinator-table";

export default function AdminServiceCoordinatorView() {
    return (
        <div>
            <h3 className="title">Service Coordinators</h3>
            <ServiceCoordinatorTable/>
        </div>
    )
}
