import React, { useEffect, useState } from 'react';
import { AttendanceScoresApi, BehaviorScoresApi, EngagementScoresApi, PersonApi } from "../../shared/services";
import StudentAttendanceRow from "./student-attendance-row";
import { ObjectMultiSelect } from "../../shared/components";

const map_url = "https://www.google.com/url?q=https://docs.google.com/document/d/1061QVBnFyG6kGJ7To5RFiTq1R9SBFFKT2f7NuVfdxb8/edit?usp%3Dsharing&sa=D&source=editors&ust=1643828538147034&usg=AOvVaw0gAU5vPnN6rbiVjxOUo5SS"
const behavior_url = "https://docs.google.com/document/d/1h7EFWlksABoMV9wcnUUMbxNVOZjGGrOmJZ6HudkQmqg/edit?usp=sharing";
const engagement_url = "https://docs.google.com/document/d/1lbhEYA3vS0NbHpV7WXhPTPDsmzlfb1rzu8xyhWndrmo/edit?usp=sharing"
/**
 * React function component StudentAttendanceTab
 * @param attendanceData: an array of student attendance data
 * @param setAttendanceData: a useState function to change the value of attendanceData, used to update with the filled out data.
 * @param students: an array of all students in the section
 * @param setStudents: a useState hook function to update the value of students
 * @param section: the currently selected section to take attendance for.
 * @param date: the date to take student attendance on.
 * @returns a tab pane containing a form table for taking student attendance.
 */
export default function StudentAttendanceTab({ attendanceData, setAttendanceData, students, setStudents, section, date }) {
    const [attendanceScores, setAttendanceScores] = useState([]);
    const [behaviorScores, setBehaviorScores] = useState([]);
    const [engagementScores, setEngagementScores] = useState([]);
    const [alert, setAlert] = useState(false);
    const [allStudents, setAllStudents] = useState([]);

    //Ran when component is mounted. Grabs all possibles scores for attendance, behavior, and engagement dropdowns
    useEffect(() => {
        const myAttendanceScoresApi = new AttendanceScoresApi();
        myAttendanceScoresApi.getAttendanceScores()
            .then(attendanceScoresList => {
                setAttendanceScores(attendanceScoresList.filter((score) => score.ActiveFLG === 1));
            });

        const myBehaviorScoresApi = new BehaviorScoresApi();
        myBehaviorScoresApi.getBehaviorScores()
            .then(behaviorScoresList => {
                setBehaviorScores(behaviorScoresList.filter((score) => score.ActiveFLG === 1));
            });

        const myEngagementScoresApi = new EngagementScoresApi();
        myEngagementScoresApi.getEngagementScores()
            .then(engagementScoresList => {
                setEngagementScores(engagementScoresList.filter((score) => score.ActiveFLG === 1));
            });

        const myPersonApi = new PersonApi();
        myPersonApi.getStudents()
            .then(studentList => setAllStudents(studentList));
    }, []); //Empty array causes this to only run once. This useEffect has no state dependencies

    //Create an attendance row for each student.
    const studentTableRows = students.map((student, index) => {
        return (
            <StudentAttendanceRow key={index} index={index} student={student} attendanceData={attendanceData}
                setAttendanceData={setAttendanceData} attendanceScores={attendanceScores} behaviorScores={behaviorScores}
                engagementScores={engagementScores} date={date} section={section}
            />
        )
    });

    //An alert notifying the user that attendance was successfully taken.
    const displayAlert = alert ? (
        <div className="alert alert-info alert-dismissible" role="alert">
            Student Attendance for {section.SectionNM} has been recorded
            <button type="button" className="btn-close" onClick={() => setAlert(false)} aria-label="Close"></button>
        </div>)
        : null

    //If the section is a club, the student roster will not be set, but attendance will be taken for each student that shows up
    //on that day.
    const studentMultiSelect = section.ClassTypeID === 3 ?
        (
            <div className="d-flex justify-content-center mb-2">
                <div>
                    <label>Add Students to Attendance</label>
                    <ObjectMultiSelect
                        items={allStudents}
                        itemPrimaryKey="PersonID"
                        itemNameKey="FirstNM"
                        itemNameSecondKey="LastNM"
                        selected={students}
                        setSelected={setStudents}
                        label="Student"
                        isBig={true}
                    />
                </div>
            </div>
        ) : "";

    return (
        <div id="student-attendance" role="tabpanel" aria-labelledby="student-attendance-tab">
            <br />
            {displayAlert}
            {studentMultiSelect}
            <div className='table-40'>
                <table className="table table-sm table-hover">
                    <thead className="table-light fixed-column-header">
                        <tr>
                            <th scope="col table-header">DATE</th>
                            <th scope="col table-header">STUDENT</th>
                            <th scope="col table-header">ATTENDANCE</th>
                            <th scope="col table-header"><a href={behavior_url} target="_blank" rel="noreferrer">BEHAVIOR</a></th>
                            <th scope="col table-header"><a href={engagement_url} target="_blank" rel="noreferrer">ENGAGEMENT</a></th>
                            <th scope="col table-header"><a href={map_url} target="_blank" rel="noreferrer">ATTENDANCE & BEHAVIOR NOTES</a></th>
                            <th scope="col table-header"><a href={map_url} target="_blank" rel="noreferrer">ENGAGEMENT & PROGRESS NOTES</a></th>
                        </tr>
                    </thead>
                    <tbody>
                        {studentTableRows}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
