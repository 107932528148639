import React, {useEffect, useState} from 'react';
import { ObjectDropdown, SearchBar } from "../../shared/components";
import { SectionRollApi, TermsApi } from "../../shared/services";
import { compareDateStrings } from '../../utils/date-format';

export default function FilterTable({sections, setFilteredSections}) {
    const [terms, setTerms] = useState([]);
    const [term, setTerm] = useState();
    const [searchStr, setSearchStr] = useState("");
    const [sectionRolls, setSectionRolls] = useState([]);

    useEffect(() => {
        const myTermsApi = new TermsApi();
        myTermsApi.getTerms()
            .then(termList => setTerms(termList.sort((a,b) => compareDateStrings(a.EndDTS, b.EndDTS))));
    }, []);

    useEffect(() => {
        const mySectionRollApi = new SectionRollApi();
        //Defining function inside useEffect to handle async behavior.
        const getSectionRollData = async () => {
            let tempSectionRolls = [];
            for(let section of sections) {
                const sectionRoll = await mySectionRollApi.getStudentsBySection(section.SectionID);
                const studentNames = sectionRoll.map((rollItem) => {
                    return `${rollItem.FirstNM} ${rollItem.LastNM}`
                });
                tempSectionRolls.push(studentNames);
            }
            setSectionRolls(tempSectionRolls);
        }
        getSectionRollData();
    }, [sections]);

    const searchArray = (arr, searchStr) => {
        let found = false;
        if(arr) {
            for(let str of arr) {
                if(str.toLowerCase().includes(searchStr)) {
                    found = true;
                }
            }
        }
        return found;
    }

    useEffect(() => {
        setSearchStr(searchStr.toLowerCase());
        if(searchStr !== "" && term) {
            let filtered = sections.filter((sec, index) => {
                return (sec.ClassNM.toLowerCase().includes(searchStr) || sec.SectionNM.toLowerCase().includes(searchStr)
                    || searchArray(sectionRolls[index], searchStr) || searchArray(sectionRolls[index])) 
                    && sec.TermNM === term.TermNM;
            });
            setFilteredSections(filtered);
        } else if(searchStr !== "") {
            let filtered = sections.filter((sec, index) => {
                return (sec.ClassNM.toLowerCase().includes(searchStr) || sec.SectionNM.toLowerCase().includes(searchStr)
                    || searchArray(sectionRolls[index], searchStr) || searchArray(sectionRolls[index]));
            });
            setFilteredSections(filtered);
        } else if(term) {
            let filtered = sections.filter((sec) => {
                return sec.TermNM === term.TermNM;
            });
            setFilteredSections(filtered);
        }else {
            setFilteredSections(sections);
        }
    }, [sections, searchStr, term, sectionRolls, setFilteredSections]);

    return (
        <div className="row">
            <div className="col-2"></div>
            <div className="col-2">
                <ObjectDropdown 
                    items={terms}
                    itemNameKey="TermNM"
                    selected={term}
                    setSelected={setTerm}
                    label="Term"
                    isBig={true}
                    selectAll={true}
                />
            </div>
            <div className="col">
                <SearchBar 
                    onChange={({target}) => setSearchStr(target.value)}
                    placeholder="Search"
                />
            </div>
        </div>
    )
}
