import React, {useEffect, useState} from 'react';
import { ObjectDropdown, SearchBar } from "../../shared/components";
import { ClassTypesApi, TermsApi } from "../../shared/services";
import { compareDateStrings } from '../../utils/date-format';

export default function SectionTableFilters({sections, setFilteredSections}) {
    const [terms, setTerms] = useState([]);
    const [term, setTerm] = useState();
    const [classTypes, setClassTypes] = useState([]);
    const [type, setType] = useState();
    const [searchStr, setSearchStr] = useState("");

    useEffect(() => {
        const myTermsApi = new TermsApi();
        myTermsApi.getTerms()
            .then(termList => {
                let newTerms = termList.sort((a,b) => {
                    return compareDateStrings(a.EndDTS, b.EndDTS);
                })

                setTerms(newTerms)
                setTerm(newTerms[0])
            });

        const myClassTypesApi = new ClassTypesApi();
        myClassTypesApi.getClassTypes()
            .then(classTypeList => setClassTypes(classTypeList));
    }, []);

    useEffect(() => {
        setSearchStr(searchStr.toLowerCase())
        if(searchStr !== "" && term && type) {
            let filtered = sections.filter((sec, index) => {
                return (sec.ClassNM?.toLowerCase().includes(searchStr) || 
                    sec.SectionNM?.toLowerCase().includes(searchStr) || 
                    sec.StaffFirstNM?.toLowerCase().includes(searchStr) || 
                    sec.StaffLastNM?.toLowerCase().includes(searchStr) || 
                    sec.OutcomeNM?.toLowerCase().includes(searchStr)) && 
                    sec.TermNM === term.TermNM && sec.ClassTypeID === type.ClassTypeID;
            });
            setFilteredSections(filtered);
        } else if(term && type) {
            let filtered = sections.filter((sec, index) => {
                return sec.TermNM === term.TermNM && sec.ClassTypeID === type.ClassTypeID;
            });
            setFilteredSections(filtered);
        } else if(searchStr !== "" && term) {
            let filtered = sections.filter((sec, index) => {
                return (sec.ClassNM?.toLowerCase().includes(searchStr) || 
                    sec.SectionNM?.toLowerCase().includes(searchStr) || 
                    sec.StaffFirstNM?.toLowerCase().includes(searchStr) || 
                    sec.StaffLastNM?.toLowerCase().includes(searchStr) || 
                    sec.OutcomeNM?.toLowerCase().includes(searchStr)) && 
                    sec.TermNM === term.TermNM;
            });
            setFilteredSections(filtered);
        } else if(searchStr !== "" && type) {
            let filtered = sections.filter((sec, index) => {
                return (sec.ClassNM?.toLowerCase().includes(searchStr) || 
                    sec.SectionNM?.toLowerCase().includes(searchStr) || 
                    sec.StaffFirstNM?.toLowerCase().includes(searchStr) || 
                    sec.StaffLastNM?.toLowerCase().includes(searchStr) || 
                    sec.OutcomeNM?.toLowerCase().includes(searchStr)) &&
                    sec.ClassTypeID === type.ClassTypeID;
            });
            setFilteredSections(filtered);
        } else if(searchStr !== "") {
            let filtered = sections.filter((sec, index) => {
                return (sec.ClassNM?.toLowerCase().includes(searchStr) || 
                    sec.SectionNM?.toLowerCase().includes(searchStr) || 
                    sec.StaffFirstNM?.toLowerCase().includes(searchStr) || 
                    sec.StaffLastNM?.toLowerCase().includes(searchStr) || 
                    sec.OutcomeNM?.toLowerCase().includes(searchStr));
            });
            setFilteredSections(filtered);
        } else if(term) {
            let filtered = sections.filter((sec, index) => {
                return sec.TermNM === term.TermNM;
            });
            setFilteredSections(filtered);
        } else if(type) {
            let filtered = sections.filter((sec, index) => {
                return sec.ClassTypeID === type.ClassTypeID;
            });
            setFilteredSections(filtered);
        }else {
            setFilteredSections(sections);
        }
    }, [sections, searchStr, term, type, setFilteredSections]);

    return (
        <div className="row">
            <div className="col-2"></div>
            <div className="col-2">
                <ObjectDropdown 
                    items={terms}
                    itemNameKey="TermNM"
                    selected={term}
                    setSelected={setTerm}
                    label="Term"
                    isBig={true}
                    selectAll={true}
                />
            </div>
            <div className="col-2">
                <ObjectDropdown 
                    items={classTypes}
                    itemNameKey="ClassTypeNM"
                    selected={type}
                    setSelected={setType}
                    label="Class Type"
                    isBig={true}
                    selectAll={true}
                />
            </div>
            <div className="col">
                <SearchBar 
                    onChange={({target}) => setSearchStr(target.value)}
                    placeholder="Search"
                />
            </div>
        </div>
    )
}
