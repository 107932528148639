import React, {useEffect, useState} from 'react';
import { SearchBar } from "../../shared/components";
import { compareDateStrings } from '../../utils/date-format';


export default function TermTableFilters({terms, setFilteredTerms}) {
    const [searchStr, setSearchStr] = useState("");

    useEffect(() => {

        //Filtering to include any terms whose fields contain the search text
        if(searchStr !== "") {
            let filtered = terms.filter((_term) => {
                return (_term.TermNM.toLowerCase().includes(searchStr.toLowerCase()) || 
                        _term.StartDTS.includes(searchStr.toLowerCase()) || 
                        _term.EndDTS.includes(searchStr.toLowerCase()));
            });
            filtered.sort((a, b) => compareDateStrings(a.EndDTS, b.EndDTS))
            setFilteredTerms(filtered);
        } else {
            terms.sort((a, b) => compareDateStrings(a.EndDTS, b.EndDTS));
            setFilteredTerms(terms);
        }
    }, [searchStr, terms, setFilteredTerms]);

    return (
        <div className="row">
            <div className="col-6"></div>
            <div className="col">
                <SearchBar 
                    onChange={({target}) => setSearchStr(target.value)}
                    placeholder="Search"
                />
            </div>
        </div>
    )
}
