import React from 'react';
import { Link } from 'react-router-dom';
import "./navbar.components.css";
import { scenicviewBannerLogo } from '../../../assets/images/images';
import LoginAndLogOutButton from "../login-and-logout/login-and-logout-button";

const navbarStyle = {
    backgroundColor: '#1B4B6D', // Set the background color to #1B4B6D
};

const logoStyle = {
    maxHeight: '50px', // Adjust the height as needed
    maxWidth: '150px', // Adjust the width as needed
  };

export default function Navbar() {
    return (
    <nav class="navigation navbar navbar-expand-lg" style={navbarStyle}>
        <div class="container-fluid">
            <a className="navbar-brand" href="#">
                <img src={scenicviewBannerLogo} alt="ScenicView Academy" style={logoStyle}/>
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end" id="navbarTogglerDemo02">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <Link to ="/" className="nav-link nav-link-txt navhover" href="/">ATTENDANCE</Link>
                    </li>
                    <li class="nav-item">
                        <Link to="/contacts" className="nav-link nav-link-txt navhover" href="">CONTACTS</Link>
                    </li>
                    <li class="nav-item">
                        <Link to="/admin" className="nav-link nav-link-txt navhover" href="">ADMIN</Link>
                    </li>
                    <li class="nav-item">
                        <Link to="/dashboard" className="nav-link nav-link-txt navhover" href="">DASHBOARD</Link>
                    </li>
                    <li class="nav-item">
                        <Link to="/levels" className="nav-link nav-link-txt navhover" href="">LEVELS</Link>
                    </li>
                    <li class="nav-item">
                        <LoginAndLogOutButton/>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
)}