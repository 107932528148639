import React from 'react';
import SectionsTableRow from "./sections-table-row";

export default function SectionsTable({ sections, currentSections, showCurrent, setSectionView, setSection}) {
    if(sections.length === 0) { //If no courses match the current course type and term
        return (
            <div>No courses match the term and course type for the current user.</div>
        )
    }

    const handleSelect = (course) => {
        setSection(course);
        setSectionView(true);
    }

    //Use map to make an array of list items representing those courses using the array of course names.
    //The list item key is needed to help React process correctly the changing state of the course list.
    const listToMap = showCurrent ? currentSections : sections;
    const mapList = listToMap.map((section, index) => {
        return (
            <SectionsTableRow key={section.SectionID} section={section} handleSelect={handleSelect}/>
        )
    });

    return (
        <div className='table-80'>
            <table className="table table-sm table-hover">
                <thead className="table-light fixed-column-header">
                    <tr>
                        <th scope="col table-header">TERM</th>
                        <th scope="col table-header">TYPE</th>
                        <th scope="col table-header">SECTION NAME</th>
                        <th scope="col table-header">TIME</th>
                        <th scope="col table-header">LAST RECORD DATE</th>
                    </tr>
                </thead>
                <tbody>
                    {mapList}
                </tbody>
            </table>
        </div>
    )
}
