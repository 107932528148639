import React, { useEffect, useState} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { EngagementScoresApi } from "../../shared/services";
import { editIcon } from "../../assets/images/images";

export default function EngagementScoreModal({isAdd, selected, setSelected, engagementScores, setEngagementScores}) {
    const [engagementScoreDSC, setEngagementScoreDSC] = useState("");
    const [active, setActive] = useState(0);
    const [modal, setModal] = useState(false);
    const [alertMSG, setAlertMSG] = useState("");
    const [displayAlert, setDisplayAlert] = useState(false);
    const [alertStyle, setAlertStyle] = useState("");
    const toggle = () => setModal(!modal);
    const myEngagementScoresApi = new EngagementScoresApi();

    useEffect(() => {
        if(!isAdd && selected) {
            setEngagementScoreDSC(selected.EngagementScoreDSC);
            setActive(selected.ActiveFLG);
        }
    }, [isAdd, selected]);


    const handleSubmit = async () => {
        setDisplayAlert(false);
        if(engagementScoreDSC !== "") {
            const newEngagementScore = await myEngagementScoresApi.addEngagementScore({
                EngagementScoreDSC : engagementScoreDSC
            });
            let engagementScoresCopy = [...engagementScores];
            engagementScoresCopy.push(newEngagementScore);
            setEngagementScores(engagementScoresCopy);
            setModal(false);
        } else {
            setAlertMSG("Please fill out all fields.");
            setAlertStyle("alert alert-warning alert-dismissible");
            setDisplayAlert(true);
        }

    }

    const handleEdit = async () => {
        setDisplayAlert(false);
        if(engagementScoreDSC !== "") {
            const editedEngagementScore = await myEngagementScoresApi.editEngagementScore({
                EngagementScoreID : selected.EngagementScoreID,
                EngagementScoreDSC : engagementScoreDSC,
                ActiveFLG: active
            });
            let engagementScoresCopy = [...engagementScores];
            engagementScoresCopy.forEach((eng, index) => {
                if(eng.EngagementScoreID === editedEngagementScore.EngagementScoreID) {
                    engagementScoresCopy[index] = editedEngagementScore
                }
            });
            setEngagementScores(engagementScoresCopy);
            setSelected(editedEngagementScore);
            setModal(false);
        } else {
            setAlertMSG("Please fill out all fields.");
            setAlertStyle("alert alert-warning alert-dismissible");
            setDisplayAlert(true);
        }
    }

    const alert = displayAlert ? (
        <div className={alertStyle} role="alert">
            {alertMSG}
            <button type="button" className="btn-close" onClick={() => setDisplayAlert(false)} aria-label="Close"></button>
        </div> )
        : null

    if(isAdd) {
        return (
            <>
                <button className="btn btn-primary" onClick={toggle}>Add Engagement Score</button>
                <Modal isOpen={modal}  toggle={toggle} fade={false}>
                    <ModalHeader>Add Engagement Score</ModalHeader>
                    <ModalBody>
                        {alert}
                        <label className="form-label">Engagement Score Name</label>
                        <input className="form-control" value={engagementScoreDSC} onChange={({target}) => setEngagementScoreDSC(target.value)}/>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-secondary" onClick={() => setModal(false)}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={handleSubmit}>Add Engagement Score</button>
                    </ModalFooter>
                </Modal>
            </>
        )
    } else {
        return (
            <>
                <button className="btn" onClick={toggle} disabled={selected ? false : true}>
                    <img className="edit" src={editIcon} alt="edit"/>
                </button>
                <Modal isOpen={modal}  toggle={toggle} fade={false}>
                    <ModalHeader>Edit Engagement Score</ModalHeader>
                    <ModalBody>
                        {alert}
                        <label className="form-label">Engagement Score Name</label>
                        <input className="form-control" value={engagementScoreDSC} onChange={({target}) => setEngagementScoreDSC(target.value)}/>
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" checked={active === 1 ? true : false} onChange={() => setActive(active === 1 ? 0 : 1)}/>
                            <label className="form-check-label">Active</label>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-secondary" onClick={() => setModal(false)}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={handleEdit}>Edit Engagement Score</button>
                    </ModalFooter>
                </Modal>
            </>
        ) 
    }
}
