import React, { useState } from 'react';
import TakeAttendance from "./take-attendance";
import AttendanceHistory from "./attendance-history";
import {currentToDatePicker, dateIsBetween} from "../../utils/date-format";
import "./section-attendance.css";
import { backIcon } from "../../assets/images/images";

export default function SectionAttendance({ section, setAttendanceForm}) {
    const initialDate = dateIsBetween(new Date(), new Date(section.SectionStartDTS), new Date(section.SectionEndDTS)) ?
    new Date() : new Date(section.StartDTS); 
    const [tab, setTab] = useState("take-attendance");
    const [date, setDate] = useState(currentToDatePicker(initialDate));


    const tabToDisplay = tab === "take-attendance" ? <TakeAttendance section={section} date={date} setDate={setDate}/> 
        : <AttendanceHistory section={section}/>

    const activeTab = "nav-link adm-link active";
    const passiveTab = "nav-link adm-link";

    return (
        <div className='container-fluid'>
            <div className="row">
                <div className="col-1">
                    <button className="btn" type="button" onClick={() => setAttendanceForm(false)}>
                        <img className="edit" src={backIcon} alt="back"/>
                    </button>
                </div>
                <div className="col">
                    <h5 className="title">{section.ClassNM} {section.SectionNM}: {section.TermNM}</h5>
                </div>
            </div>
            
            <br/>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className={tab === "take-attendance" ? activeTab : passiveTab} onClick={() => setTab("take-attendance")}>
                        TAKE ATTENDANCE
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={tab === "attendance-history" ? activeTab : passiveTab} onClick={() => setTab("attendance-history")}>
                        ATTENDANCE HISTORY
                    </button>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                {tabToDisplay}
            </div>
        </div> 
    )
}
