import React, {useEffect, useState} from 'react';
import LevelsTable from "./levels-table";
import FilterTable from "./filter-table";
import AddLevel from "./add-level";
import { OutcomeApi, OutcomeLevelApi, ProgramApi } from "../../shared/services";

export default function AdminLevelsView() {
    const [levels, setLevels] = useState([]);
    const [filteredLevels, setFilteredLevels] = useState([]);
    const [program, setProgram] = useState();
    const [outcome, setOutcome] = useState();
    const [outcomes, setOutcomes] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [activeFilter, setActiveFilter] = useState(true);

    useEffect(() => {
        const myOutcomeLevelApi = new OutcomeLevelApi();
        myOutcomeLevelApi.getOutcomeLevels()
            .then(levelsList => {
                const sortedLevels = levelsList.sort((a,b) => {
                    //First sort by program
                    const programCompare = a.ProgramID - b.ProgramID;
                    //if both items have the same program, then sort by outcome
                    if(programCompare === 0) {  
                        const outcomeCompare = a.OutcomeID - b.OutcomeID;
                        //if both items also have the same outcome, sort by Level number
                        if(outcomeCompare === 0) {
                            return a.LevelNM > b.LevelNM ? 1 : -1;
                        } else {
                            return outcomeCompare;
                        }
                    } else {
                        return programCompare;
                    }
                });
                setLevels(sortedLevels)
            });
                
        const myOutcomeApi = new OutcomeApi();
        myOutcomeApi.getOutcomes()
            .then(outcomesList => setOutcomes(outcomesList));
        
        const myProgramApi = new ProgramApi();
        myProgramApi.getPrograms()
            .then(programsList => setPrograms(programsList));
    }, []);

    useEffect(() => {
        const myOutcomeLevelApi = new OutcomeLevelApi();
        let ProgramID = program ? program.ProgramID : null;
        let OutcomeID = outcome ? outcome.OutcomeID : null;
        myOutcomeLevelApi.getOutcomeLevelsByProgramAndOutcome(ProgramID, OutcomeID)
            .then(levelsList => {
                const sortedLevels = levelsList.sort((a,b) => {
                    //First sort by program
                    const programCompare = a.ProgramID - b.ProgramID;
                    //if both items have the same program, then sort by outcome
                    if(programCompare === 0) {  
                        const outcomeCompare = a.OutcomeID - b.OutcomeID;
                        //if both items also have the same outcome, sort by Level number
                        if(outcomeCompare === 0) {
                            return a.LevelNM > b.LevelNM ? 1 : -1;
                        } else {
                            return outcomeCompare;
                        }
                    } else {
                        return programCompare;
                    }
                });
                setLevels(sortedLevels)
            });
    }, [outcome, program]);

    useEffect(() => {
        setFilteredLevels(levels.filter(lv => lv.ArchivedFLG !== activeFilter))
    }, [activeFilter, levels])
    
    return (
        <div>
            <h4 className="title">Add a Level</h4>
            <AddLevel 
                levels={levels} 
                setLevels={setLevels} 
                outcomes={outcomes} 
                programs={programs} />
            <h4 className="title">Current Levels</h4>
            <FilterTable 
                outcomes={outcomes} 
                programs={programs} 
                outcome={outcome} 
                setOutcome={setOutcome} 
                program={program} 
                setProgram={setProgram} 
                levels={levels} 
                setActiveFilter={setActiveFilter}
                setFilteredLevels={setFilteredLevels}
                activeFilter={activeFilter} />
            <LevelsTable 
                levels={filteredLevels}
                setLevels={setLevels} 
                outcomes={outcomes} 
                programs={programs} />
        </div>
    )
}
