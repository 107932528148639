import React, {useState} from 'react';
import { arrayToStringListNoDuplicates } from "../../utils/string-format";
import { databaseToTableFormat, dateToInputDate, pickerToDatabaseFormat } from "../../utils/date-format";
import { editIcon, saveIcon, deleteIcon } from "../../assets/images/images";
import { AttendanceApi } from "../../shared/services";
import { useAuth } from "../../context/auth-context";

/**
 * 
 * @param {historyItem, editable, setEditable, setSectionHistory, index} param0 
 * @returns 
 */
export default function SectionHistoryRow({row, setSectionHistory}) {
    const [editable, setEditable] = useState(false)
    const [date, setDate] = useState(databaseToTableFormat(row.AttendanceDTS));
    const [length, setLength] = useState(row.LengthNBR);
    const myAttendanceApi = new AttendanceApi();
    const {isAdmin} = useAuth();

    const handleEdit = () => {
        const selectedDate = pickerToDatabaseFormat(dateToInputDate(date));

        // EDIT MAKES CHANGES TO: SectionNote Dates
        myAttendanceApi.editDate({
            SectionID: row.SectionID,
            OldDTS: row.AttendanceDTS,
            AttendanceDTS: selectedDate,
            LengthNBR: length
        });

        myAttendanceApi.editSectionNotes({
            SectionID: row.SectionID,
            OldDTS: row.AttendanceDTS,
            SectionNoteDTS: selectedDate,
        })

        setEditable(false);
    }

    const handleDelete = () => {
        // EDIT MAKES CHANGES TO: SectionNote Dates

        if (!window.confirm("Are you sure you would like to delete this attendance entry?")) {
            setEditable(false);
            return;
        }
        myAttendanceApi.deleteAttendanceRecord({
            SectionID: row.SectionID,
            AttendanceDTS: row.AttendanceDTS,
        });

        myAttendanceApi.deleteSectionNotes({
            SectionID: row.SectionID,
            SectionNoteDTS: row.AttendanceDTS,
        })

        setSectionHistory(prev=> {
            let newSectionHistory = [...prev];

            newSectionHistory.splice(row.index, 1);

            return newSectionHistory;
        })

        setEditable(false);
    }
    
    if(editable) {
        return (
            <tr>
                <td>
                    <input type="date" className="form-control" value={dateToInputDate(date)} onChange={({target}) => {
                        setDate(databaseToTableFormat(target.value + "T00:00"));
                    }} />
                </td>
                <td>
                    <input type="number" className="form-control" value={length} onChange={({target}) => setLength(target.value)} min="0"/>
                </td>
                <td>{row.ClassNM} {row.SectionNM}</td>
                <td>{row.ListOfStudentNM ? arrayToStringListNoDuplicates(row.ListOfStudentNM.split(',')) : ""}</td>
                <td>{row.ListOfInstructorNM ? arrayToStringListNoDuplicates(row.ListOfInstructorNM.split(',')) : ""}</td>
                <td>
                    <button className="btn" onClick={handleEdit}>
                        <img src={saveIcon} alt="save" className="edit" />
                    </button>
                    {isAdmin() ? (<button className="btn" onClick={handleDelete}>
                            <img src={deleteIcon} alt="delete" className="delete" />
                        </button>) : null}
                </td>
            </tr>
        )
    } else {
        return (
            <tr>
                <td>{databaseToTableFormat(date)}</td>
                <td>{length}</td>
                <td>{row.ClassNM} {row.SectionNM}</td>
                <td>{row.ListOfStudentNM ? arrayToStringListNoDuplicates(row.ListOfStudentNM.split(',')) : ""}</td>
                <td>{row.ListOfInstructorNM ? arrayToStringListNoDuplicates(row.ListOfInstructorNM.split(',')) : ""}</td>
                <td>
                    <button className="btn" onClick={() => setEditable(true)}>
                        <img src={editIcon} alt="edit" className="edit" />
                    </button>
                </td>
            </tr>
        )
    }
}
