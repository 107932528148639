import React, {useEffect, useState} from 'react';
import AddClassForm from "./add-class-form";
import { ClassApi, ClassTypesApi, ProgramApi, OutcomeApi } from "../../shared/services";
import ClassTableFilters from './class-table-filters';
import ClassTable from './class-table';

export default function AdminClassView() {
    const [classes, setClasses] = useState([]);
    const [classTypes, setClassTypes] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [outcomes, setOutcomes] = useState([]);
    const [filteredClasses, setFilteredClasses] = useState([]);

    useEffect(() => {
        const myClassApi = new ClassApi();
        myClassApi.getClasses()
        .then(classList => {
            classList.sort((a, b) => a.ClassNM.localeCompare(b.ClassNM));
            setFilteredClasses(classList);
            setClasses(classList);
        });

        const myClassTypesApi = new ClassTypesApi();
        myClassTypesApi.getClassTypes()
        .then(typesList => {
            setClassTypes(typesList);
        });

        const myProgramApi = new ProgramApi();
        myProgramApi.getPrograms()
        .then(programList => {
            programList.sort((a,b) => a.ProgramID > b.ProgramID);
            setPrograms(programList);
        });

        const myOutcomeApi = new OutcomeApi();
        myOutcomeApi.getOutcomes()
        .then(outcomeList => {
            outcomeList.sort((a,b) => a.OutcomeID > b.OutcomeID);
            setOutcomes(outcomeList);
        });
        
    }, []);

    return (
        <div>
            <h5 className="title">Add New Class</h5>
            <AddClassForm 
                classes={classes} 
                setClasses={setClasses}
                classTypes={classTypes}
                programs={programs}
                outcomes={outcomes}
            />
            <h5 className="title">Current Classes</h5>
            <ClassTableFilters 
                classes={classes} 
                setClasses={setClasses} 
                setFilteredClasses={setFilteredClasses}
                classTypes={classTypes}
                programs={programs}
                outcomes={outcomes}
            />
            <ClassTable 
                filteredClasses={filteredClasses}
                classTypes={classTypes}
                programs={programs}
                outcomes={outcomes}
            />
        </div>
    )
}
