import React, {useState} from 'react';
import { editIcon , saveIcon } from "../../assets/images/images";
import { NoteApi } from "../../shared/services";
import { useAuth } from "../../context/auth-context";

export default function StudentNoteItem({noteObj, studentNotes, setStudentNotes}) {
    const [editable, setEditable] = useState(false);
    const [noteText, setNoteText] = useState(noteObj.StudentNoteTXT);
    const [displayAlert, setDisplayAlert] = useState(false); //Should the alert be displayed?
    const [alertMSG, setAlertMSG] = useState("");   //What message the alert should have?
    const [alertStyle, setAlertStyle] = useState("alert alert-info alert-dismissible");   //Should the alert style be warn or info?

    const { currentUser } = useAuth();

    const handleEdit = async () => {
        //Use Note service to edit the note
        const myNoteApi = new NoteApi();
        const editedNote = await myNoteApi.editNote({
            NoteID : noteObj.StudentNoteID,
            NoteTXT: noteText,
            NoteModifiedByPersonID: currentUser.PersonID
        });
        //Update state of studentNotes with editedNote
        const index = studentNotes.indexOf(noteObj);
        const studentNotesCopy = [...studentNotes];
        studentNotesCopy[index].StudentNoteTXT = editedNote.NoteTXT;
        setEditable(false);     //Turn editible off
        //Notifiy User of successful edit
        setAlertMSG(`Note for ${noteObj.StudentNM} was successfully updated`);
        setAlertStyle("alert alert-info alert-dismissible");
        setDisplayAlert(true);
    }

    let textarea = editable ? <textarea className="form-control" rows={1} value={noteText} onChange={({target}) => setNoteText(target.value)}/> :
        <textarea className="form-control" rows={1} value={noteText} readOnly/>
    let editButton = editable ? <img className="edit" alt="edit" src={saveIcon} onClick={handleEdit}/> :
        <img className="edit" alt="edit" src={editIcon} onClick={() => setEditable(true)}/>

    const alert = displayAlert ? (
        <div className={alertStyle} role="alert">
            {alertMSG}
            <button type="button" className="btn-close" onClick={() => setDisplayAlert(false)} aria-label="Close"></button>
        </div> )
        : null

    return (
        <div className="mb-2">
            {alert}
            <div className="row">
                <div className="col-3">
                    <label className="form-label">{noteObj.StudentNM}</label>
                </div>
                <div className="col">
                    {textarea}
                </div>
                <div className="col-1">
                    <button className="btn">
                        {editButton}
                    </button>
                </div>
                <div className="col-1"></div>
            </div>
        </div>
    )
}
