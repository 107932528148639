import React, {useEffect, useState} from 'react';
import { SectionsApi } from "../../shared/services";
import { compareDateStrings } from "../../utils/date-format";
import StudentSectionTable from "./student-section-table";
import FilterTable from "./filter-table";

export default function ManageSectionStudentsView() {
    const [sections, setSections] = useState([]);
    const [filteredSections, setFilteredSections] = useState([]);

    useEffect(() => {
        const mySectionsApi = new SectionsApi();
        mySectionsApi.getSections()
            .then(sectionList => {
                const fSections = sectionList.filter((section) => {
                    return section.ClassTypeID === 1;    //Only allow adding and removing students when class type is Class, not 1 on 1 or clubs.
                })
                setSections(fSections.sort((a,b) => compareDateStrings(a.SectionEndDTS, b.SectionEndDTS)));
            });
        
    }, []);
    return (
        <div>
            <h5 className="title">Manage Section Students</h5>
            <FilterTable sections={sections} filteredSections={filteredSections} setFilteredSections={setFilteredSections}/>
            <StudentSectionTable filteredSections={filteredSections}/>
        </div>
    )
}
