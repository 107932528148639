import React, {useState} from 'react';
import { OutcomeLevelApi, PersonApi, StudentLevelApi } from "../../shared/services";

export default function AddStudent({students, setStudents}) {
    const [firstNM, setFirstNM] = useState("");
    const [lastNM, setLastNM] = useState("");
    const [displayAlert, setDisplayAlert] = useState(false);
    const [alertMSG, setAlertMSG] = useState("");
    const [alertStyle, setAlertStyle] = useState("");
    const myOutcomeLevelApi = new OutcomeLevelApi();
    const myStudentLevelApi = new StudentLevelApi();
    const myPersonApi = new PersonApi();

    const handleSubmit = async () => {
        if(firstNM !== "" && lastNM !== "") {
            //Create the new student
            const addedStudent = await myPersonApi.addStudent({
                FirstNM: firstNM,
                LastNM: lastNM
            });
            //Add the new student to the frontend table in sorted order
            const studentsCopy = [...students];
            studentsCopy.push(addedStudent);
            setStudents(studentsCopy.sort((a,b) => { 
                return b.FirstNM > a.FirstNM ? -1 
                : b.FirstNM < a.FirstNM ? 1
                : b.LastNM > a.LastNM ? -1 : 1
            }));
            //Create a student level for each outcomeLevel for the new student
            const outcomeLevels = await myOutcomeLevelApi.getOutcomeLevels();
            for(let outcomeLevel of outcomeLevels) {
                myStudentLevelApi.addStudentLevel({
                    PersonID: addedStudent.PersonID,
                    OutcomeLevelID: outcomeLevel.OutcomeLevelID
                })
            }
            //Notify the user of the successful addition of the student
            setAlertStyle("alert alert-info alert-dismissible");
            setAlertMSG(`The student ${addedStudent.FirstNM} ${addedStudent.LastNM} has been added`);
            setDisplayAlert(true);
            //Clear out fields
            setFirstNM("");
            setLastNM("");
        } else {
            setAlertStyle("alert alert-warning alert-dismissible");
            setAlertMSG(`Please fill out both the first name and last name fields`);
            setDisplayAlert(true);
        }
    }

    const alert = displayAlert ? (
        <div className={alertStyle} role="alert">
            {alertMSG}
            <button type="button" className="btn-close" onClick={() => setDisplayAlert(false)} aria-label="Close"></button>
        </div> )
        : null

    return (
        <>
            {alert}
            <table className="table table-sm table-hover">
                <thead className="table-light">
                    <tr>
                        <th>FIRST NAME</th>
                        <th>LAST NAME</th>
                        <th>SUBMIT</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <input 
                                className="form-control" 
                                type="text" 
                                value={firstNM} 
                                onChange={({target}) => setFirstNM(target.value)}
                            />
                        </td>
                        <td>
                            <input 
                                className="form-control" 
                                type="text" 
                                value={lastNM} 
                                onChange={({target}) => setLastNM(target.value)}
                            />
                        </td>
                        <td>
                            <button className="btn btn-primary" onClick={handleSubmit}>Add Student</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}
