import React, {useEffect, useState} from 'react';
import { editIcon, saveIcon } from "../../assets/images/images";
import { arrayToStringList } from "../../utils/string-format";
import { ObjectMultiSelect } from "../../shared/components";
import { SectionRollApi } from "../../shared/services";
import { dbTimeStampToTable } from '../../utils/date-format';


export default function StudentSectionTableRow({section, students, setStudents}) {
    const [editable, setEditable] = useState(false);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [sectionRoll, setSectionRoll] = useState([]);

    const displaySchedule = () => {
        if(section) {
            let scheduleString = "";
            if(section.MondayStartTS !== section.MondayEndTS) {
                scheduleString = scheduleString
                    .concat("Monday: ", dbTimeStampToTable(section.MondayStartTS), " to ", dbTimeStampToTable(section.MondayEndTS), " ");
            }
            if(section.TuesdayStartTS !== section.TuesdayEndTS) {
                scheduleString = scheduleString
                    .concat("Tuesday: ", dbTimeStampToTable(section.TuesdayStartTS), " to ", dbTimeStampToTable(section.TuesdayEndTS), " ");
            }
            if(section.WednesdayStartTS !== section.WednesdayEndTS) {
                scheduleString = scheduleString
                    .concat("Wednesday: ", dbTimeStampToTable(section.WednesdayStartTS), " to ", dbTimeStampToTable(section.WednesdayEndTS), " ");
            }
            if(section.ThursdayStartTS !== section.ThursdayEndTS) {
                scheduleString = scheduleString
                    .concat("Thursday: ", dbTimeStampToTable(section.ThursdayStartTS), " to ", dbTimeStampToTable(section.ThursdayEndTS), " ");
            }
            if(section.FridayStartTS !== section.FridayEndTS) {
                scheduleString = scheduleString
                    .concat("Friday: ", dbTimeStampToTable(section.FridayStartTS), " to ", dbTimeStampToTable(section.FridayEndTS), " ");
            }
            return scheduleString;
        } else {
            return "";
        }
    }

    useEffect(() => {

        //In order to syncronize the arrival of student data, we must define a function within the useEffect and call it.
        async function fetchData() {

            const mySectionRollApi = new SectionRollApi();
            const sectionRollList = await mySectionRollApi.getStudentsBySection(section.SectionID)
            setSectionRoll(sectionRollList);
            
            const tempStudentRoster = students.filter((stu, index) => {
                return sectionRollList.findIndex(rollItem => rollItem.PersonID === stu.PersonID) !== -1;
            });
            setSelectedStudents(tempStudentRoster);
        }
        fetchData();
    }, [section, students]);


    const handleEdit = async () => {
        //Determine which students were removed.
        const removedStudents = sectionRoll.filter((studentRollItem, index) => {
            return selectedStudents.findIndex(selStu => selStu.PersonID === studentRollItem.PersonID) === -1;
        });
        //Determine which students were added.
        const addedStudents = selectedStudents.filter((selStu, index) => {
            return sectionRoll.findIndex(studentRollItem => studentRollItem.PersonID === selStu.PersonID) === -1;
        });
        const mySectionRollApi = new SectionRollApi();
        //delete all student rolls of removed students
        for(let removedStudent of removedStudents) {
            mySectionRollApi.removePersonFromSection(removedStudent.SectionRollID);
        }
        //create a student roll for each student added
        for(let addedStudent of addedStudents) {
            mySectionRollApi.addPersonToSection({
                ClassID: section.ClassID,
                SectionID: section.SectionID,
                PersonID: addedStudent.PersonID
            });
        }
        //update the section roll
        const sectionRollList = await mySectionRollApi.getStudentsBySection(section.SectionID)
        setSectionRoll(sectionRollList);
        setEditable(false);
    }

    const selectedStudentNames = selectedStudents.map((student, index) => `${student.FirstNM} ${student.LastNM}`);

    if(editable) {
        return (
            <tr>
                <td>{section.TermNM}</td>
                <td>{section.ClassNM} {section.SectionNM}</td>
                <td>{displaySchedule()}</td>
                <td>
                    <div className="w-25 mx-auto">
                        <ObjectMultiSelect 
                            items={students}
                            itemPrimaryKey="PersonID"
                            itemNameKey="FirstNM"
                            itemNameSecondKey="LastNM"
                            selected={selectedStudents}
                            setSelected={setSelectedStudents}
                            label="Student"
                            isBig={false}
                        />
                    </div>
                </td>
                <td>
                    <button className="btn" onClick={handleEdit}>
                        <img className="edit" alt="edit" src={saveIcon}/>
                    </button>
                </td>
            </tr>
        )
    } else {
        return (
            <tr>
                <td>{section.TermNM}</td>
                <td>{section.ClassNM} {section.SectionNM}</td>
                <td>{displaySchedule()}</td>
                <td>{arrayToStringList(selectedStudentNames)}</td>
                <td>
                    <button className="btn" onClick={() => setEditable(true)}>
                        <img className="edit" alt="edit" src={editIcon}/>
                    </button>
                </td>
            </tr>
        )
    }
}
