import React, { useState } from 'react';
import { ObjectDropdown } from "../../shared/components";
import { ClassApi } from "../../shared/services";

export default function AddTermForm({classes, setClasses, classTypes, programs, outcomes}) {
    const [classNM, setClassNM] = useState(""); //Holds value of the class name to add
    const [classType, setClassType] = useState(null);   //Keeps track of classType dropdown selected value
    const [program, setProgram] = useState(null);       //Selected program
    const [outcome, setOutcome] = useState(null);       //Selected outcome
    const [progressRequired, setProgressRequired] = useState(false);    //Checked prop for progress required checkbox
    const [active, setActive] = useState(true); //Checked prop for active class checkbox
    
    //States for the alert to work properly
    const [displayAlert, setDisplayAlert] = useState(false);    //Should the alert be visible
    const [alertMSG, setAlertMSG] = useState("");   //What should the alert say
    const [alertStyle, setAlertStyle] = useState("alert alert-info alert-dismissible"); //What color should the alert be

    // Function that is called when a user presses the "Add New Class" button
    const handleSubmit = async (event) => {
        event.preventDefault();
        setDisplayAlert(false);
        const myClassApi = new ClassApi();
        const progressFLG = progressRequired ? 1 : 0;
        const activeFLG = active ? 1 : 0;
        const outcomeNotRequired = program.ProgramID === 4 || program.ProgramID === 5 || program.ProgramID === 6 ? true : false;
        const OutcomeID = outcome ? outcome.OutcomeID : null;
        if(program && (outcome || outcomeNotRequired) && classType && classNM !== "" ) {
            myClassApi.addClass({
                ClassNM: classNM,
                ProgramID: program.ProgramID,
                OutcomeID: OutcomeID,
                ActiveFLG: activeFLG,
                RequireProgressFLG: progressFLG,
                ClassTypeID: classType.ClassTypeID
            }).then(newClass => {
                setAlertMSG(`${newClass.ClassNM} has been added.`);
                setAlertStyle("alert alert-info alert-dismissible");
                setDisplayAlert(true);
                let tempClasses = [...classes];
                tempClasses.push(newClass);
                setClasses(tempClasses.sort((a,b) => {
                    return b.ClassNM > a.ClassNM ? -1 : 1
                }));
                setClassNM("");
                setClassType(null);
                setProgram(null);
                setOutcome(null);
                setProgressRequired(false);
                setActive(true); 
            });
        } else {
            setDisplayAlert(true);
            setAlertMSG("Please fill out the form completely before submitting the new class.");
            setAlertStyle("alert alert-warning alert-dismissible");
        }
    };

    const alert = displayAlert ? <div className={alertStyle} role="alert">
            {alertMSG}
            <button type="button" className="btn-close" onClick={() => setDisplayAlert(false)} aria-label="Close"></button>
        </div> : null;

    return (
        <>
            {alert}
            <table className="table table-sm table-hover mx-0 mt-2">
                <thead className="table-light">
                    <tr>
                        <th>CLASS NAME</th>
                        <th>CLASS TYPE</th>
                        <th>PROGRAM</th>
                        <th>OUTCOME</th>
                        <th>REQUIRES PROGRESS</th>
                        <th>ACTIVE</th>
                        <th>SAVE</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                        <input className="form-control" value={classNM} onChange={({target}) => setClassNM(target.value)}/>
                        </td>
                        <td>
                            <ObjectDropdown
                                items={classTypes}
                                itemNameKey="ClassTypeNM"
                                selected={classType}
                                setSelected={setClassType}
                                label="Class Type"
                                isBig={true}
                            />
                        </td>
                        <td>
                            <ObjectDropdown 
                                items={programs}
                                itemNameKey="ProgramNM"
                                selected={program}
                                setSelected={setProgram}
                                label="Program"
                                isBig={true}
                            />
                        </td>
                        <td>
                            <ObjectDropdown
                                items={outcomes}
                                itemNameKey="OutcomeNM"
                                selected={outcome}
                                setSelected={setOutcome}
                                label="Outcome"
                                isBig={true}
                            />
                        </td>
                        <td>
                            <input type="checkbox"
                                className="form-check-input"
                                id="progress"
                                onChange={() => setProgressRequired(!progressRequired)}
                                checked={progressRequired}
                            />
                        </td>
                        <td>
                            <input type="checkbox" 
                                className="form-check-input" 
                                id="active" 
                                onChange={() => setActive(!active)}
                                checked={active}
                            />
                        </td>
                        <td>
                            <button className="btn btn-primary" onClick={handleSubmit}>
                                Add New Class
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}
