import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { OutcomeApi, ProgramApi } from "../../shared/services";
import { ObjectDropdown } from "../../shared/components";
import { editIcon } from "../../assets/images/images";


export default function OutcomeModal({isAdd, selected, setSelected, outcomes, setOutcomes}) {
    const [outcomeNM, setOutcomeNM] = useState("");
    const [programs, setPrograms] = useState([]);
    const [program, setProgram] = useState();
    const [active, setActive] = useState(0);
    const [modal, setModal] = useState(false);
    const [alertMSG, setAlertMSG] = useState("");
    const [displayAlert, setDisplayAlert] = useState(false);
    const [alertStyle, setAlertStyle] = useState("");
    const toggle = () => setModal(!modal);
    const myOutcomeApi = new OutcomeApi();

    useEffect(() => {
        const myProgramApi = new ProgramApi();
        myProgramApi.getPrograms()
        .then(programsList => setPrograms(programsList))
    }, []);

    useEffect(() => {
        if(!isAdd && selected && programs) {
            setOutcomeNM(selected.OutcomeNM);
            const selectedProgramIndex = programs.findIndex((pro, index) => pro.ProgramID === selected.ProgramID);
            setProgram(programs[selectedProgramIndex]);
            setActive(selected.ActiveFLG);
        }
    }, [programs, selected, isAdd]);

    const handleSubmit = async () => {
        setDisplayAlert(false);
        if(program && outcomeNM !== "") {
            const newOutcome = await myOutcomeApi.addOutcome({
                OutcomeDSC: "",
                OutcomeNM: outcomeNM,
                ProgramID: program.ProgramID
            });
            let outcomesCopy = [...outcomes];
            outcomesCopy.push(newOutcome);
            setOutcomes(outcomesCopy);
            setModal(false);
        } else {
            setAlertMSG("Please fill out all fields.");
            setAlertStyle("alert alert-warning alert-dismissible");
            setDisplayAlert(true);
        }
    }

    const handleEdit = async () => {
        setDisplayAlert(false);
        if(program && outcomeNM !== "") {
            const editedOutcome = await myOutcomeApi.editOutcome({
                OutcomeID: selected.OutcomeID,
                OutcomeDSC: "",
                OutcomeNM: outcomeNM,
                ProgramID: program.ProgramID,
                ActiveFLG: active
            });
            let outcomesCopy = [...outcomes];
            outcomesCopy.forEach((out, index) => {
                if(out.OutcomeID === editedOutcome.OutcomeID) {
                    outcomesCopy[index] = editedOutcome;
                }
            });
            setOutcomes(outcomesCopy);
            setSelected(editedOutcome);
            setModal(false);
        } else {
            setAlertMSG("Please fill out all fields.");
            setAlertStyle("alert alert-warning alert-dismissible");
            setDisplayAlert(true);
        }
    }

    const alert = displayAlert ? (
        <div className={alertStyle} role="alert">
            {alertMSG}
            <button type="button" className="btn-close" onClick={() => setDisplayAlert(false)} aria-label="Close"></button>
        </div> )
        : null
    if(isAdd) {
        return (
            <>
                <button className="btn btn-primary" onClick={toggle}>Add Outcome</button>
                <Modal isOpen={modal}  toggle={toggle} fade={false}>
                    <ModalHeader>Add Outcome</ModalHeader>
                    <ModalBody>
                        {alert}
                        <label className="form-label">Outcome Name</label>
                        <input className="form-control" value={outcomeNM} onChange={({target}) => setOutcomeNM(target.value)}/>
                        <ObjectDropdown
                            items={programs}
                            itemNameKey="ProgramNM"
                            selected={program}
                            setSelected={setProgram}
                            label="Program"
                            isBig="true"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-secondary" onClick={() => setModal(false)}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={handleSubmit}>Add Outcome</button>
                    </ModalFooter>
                </Modal>
            </>
        )
    } else {
        return (
            <>
                <button className="btn" onClick={toggle} disabled={selected ? false : true}>
                    <img className="edit" src={editIcon} alt="edit"/>
                </button>
                <Modal isOpen={modal}  toggle={toggle} fade={false}>
                    <ModalHeader>Edit Outcome</ModalHeader>
                    <ModalBody>
                        {alert}
                        <label className="form-label">Outcome Name</label>
                        <input className="form-control" value={outcomeNM} onChange={({target}) => setOutcomeNM(target.value)}/>
                        <ObjectDropdown
                            items={programs}
                            itemNameKey="ProgramNM"
                            selected={program}
                            setSelected={setProgram}
                            label="Program"
                            isBig="true"
                        />
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" checked={active === 1 ? true : false} onChange={() => setActive(active === 1 ? 0 : 1)}/>
                            <label className="form-check-label">Active</label>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-secondary" onClick={() => setModal(false)}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={handleEdit}>Edit Outcome</button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}
