import {useState} from 'react';

export default function SearchInput({placeholder = "", onSearch}) {
    const [searchText, setSearchText] = useState("");

    const handleInput = (e) => {
        setSearchText(e.target.value.trim());
    }
    const handleSearch = () => onSearch(searchText);

    return (
        <div class="input-group mb-3">
            <input type="text" value={searchText} onChange={handleInput} className="form-control" placeholder={"Search " + placeholder} />
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" onClick={handleSearch} type="button">Search</button>
            </div>
        </div>
    );
}
