import React, {useEffect, useState} from 'react';
import { ClassApi, DashboardApi, TermsApi } from '../../shared/services';
import StudentScorecardTables from './student-scorecard-tables';
import { compareDateStrings } from '../../utils/date-format';
import StudentScorecardFilter from './student-scorecard-filter';

export default function StudentScorecard() {
    const [student, setStudent] = useState();
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [term, setTerm] = useState();
    const [selectedTerms, setSelectedTerms] = useState([]);
    const [terms, setTerms] = useState([]);
    const [program, setProgram] = useState();
    const [selectedPrograms, setSelectedPrograms] = useState([]);
    const [selectedClasses, setSelectedClasses] = useState([]);
    const [classes, setClasses] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    useEffect(() => {
        if(selectedStudents.length !== 0) {
            const myDashboardApi = new DashboardApi();
            const PersonIDs = selectedStudents ? selectedStudents.map(student => student.PersonID) : null;
            myDashboardApi.getStudentTerms(PersonIDs)
                .then(termList => {
                    setTerms(termList.sort((a,b) => {
                        return compareDateStrings(a.EndDTS, b.EndDTS);
                    }));
                });

            myDashboardApi.getStudentClasses(PersonIDs)
                .then(classList => {
                    setClasses(classList.sort((a,b) => a.ClassNM > b.ClassNM ? 1 : -1));
                })
        } else {
            const myTermsApi = new TermsApi();
            myTermsApi.getTerms()
                .then(termList => {
                    setTerms(termList.sort((a,b) => {
                        return compareDateStrings(a.EndDTS, b.EndDTS);
                    }));
                });

            const myClassApi = new ClassApi();
            myClassApi.getClasses()
                .then(classList => {
                    setClasses(classList.sort((a,b) => a.ClassNM > b.ClassNM ? 1 : -1));
                });
        }
    }, [selectedStudents]);

    return (
        <div>
            <StudentScorecardFilter
                student={student}
                setStudent={setStudent}
                selectedStudents={selectedStudents}
                setSelectedStudents={setSelectedStudents}
                term={term}
                setTerm={setTerm}
                selectedTerms={selectedTerms}
                setSelectedTerms={setSelectedTerms}
                terms={terms}
                setTerms={setTerms}
                classes={classes}
                setClasses={setClasses}
                program={program}
                setProgram={setProgram}
                selectedPrograms={selectedPrograms}
                setSelectedPrograms={setSelectedPrograms}
                selectedClasses={selectedClasses}
                setSelectedClasses={setSelectedClasses}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
            />
            <StudentScorecardTables
                student={student}
                selectedStudents={selectedStudents}
                term={term}
                selectedTerms={selectedTerms}
                program={program}
                selectedPrograms={selectedPrograms}
                selectedClasses={selectedClasses}
                startDate={startDate}
                endDate={endDate}
            />
        </div>
    )
}
