import React, {useEffect, useState} from 'react';
import { editIcon, saveIcon } from "../../assets/images/images";
import { StudentLevelApi } from "../../shared/services";
import { currentToDatePicker, databaseToTableFormat, databaseToDatePicker, pickerToDatabaseFormat } from "../../utils/date-format";

export default function LevelsTableRow({ level, index, levels, setLevels }) {
    const [inProgress, setInProgress] = useState(level.InProgressFLG);
    const [completed, setCompleted] = useState(level.CompletedFLG);
    const [completedDate, setCompletedDate] = useState(level.CompletedDTS);
    const [notApplicable, setNotApplicable] = useState(level.NotApplicableFLG);
    const [reviewed, setReviewed] = useState(level.ReviewedFLG);
    const [editable, setEditable] = useState(false);

    
    useEffect(() => {
        setInProgress(level.InProgressFLG);
        setCompleted(level.CompletedFLG);
        setCompletedDate(level.CompletedDTS ? databaseToDatePicker(level.CompletedDTS) : currentToDatePicker(new Date()));
        setNotApplicable(level.NotApplicableFLG);
        setReviewed(level.ReviewedFLG);
    }, [level])
    
    const handleEdit = async () => {
        //Import service class to access student level backend
        const myStudentLevelApi = new StudentLevelApi();
        //If the student has completed the level or the level has been marked not applicable, record the date of completion
        const CompletedDTS = (completed === 1 || notApplicable === 1) ? pickerToDatabaseFormat(completedDate)
            : null;
        //Edit the Student Level
        const editedLevel = await myStudentLevelApi.editStudentLevel({
            StudentLevelID: level.StudentLevelID,
            CompletedFLG: completed,
            NotApplicableFLG: notApplicable,
            CompletedDTS,
            InProgressFLG: inProgress,
            ReviewedFLG: reviewed
        });
        
        const levelsCopy = [...levels];
        levelsCopy[index].CompletedFLG = editedLevel.CompletedFLG;
        levelsCopy[index].NotApplicableFLG = editedLevel.NotApplicableFLG;
        levelsCopy[index].CompletedDTS = editedLevel.CompletedDTS;
        setLevels(levelsCopy);
        setEditable(false);
    }

    const editButton = editable ? 
        <button className="btn" onClick={handleEdit}>
            <img className="edit" alt="edit" src={saveIcon}/>
        </button> : 
        <button className="btn" onClick={() => setEditable(true)}>
            <img className="edit" alt="edit" src={editIcon}/>
        </button>

    const dateCompleted = editable ? 
        <input
            className="form-control"
            type="date" 
            value={completedDate} 
            onChange={({target}) => setCompletedDate(target.value)}

        /> 
        : level.CompletedDTS ? databaseToTableFormat(level.CompletedDTS) : "Incomplete";

    return (
        <tr>
            <td>{level.FirstNM} {level.LastNM}</td>
            <td>{level.ProgramNM}</td>
            <td>{level.OutcomeNM}</td>
            <td>{level.LevelNM}</td>
            <td>{level.LevelDSC}</td>
            <td>
                <input type="checkbox"
                    disabled={!editable}
                    onChange={() => setInProgress(inProgress === 1 ? 0 : 1)}
                    checked={inProgress === 1 ? true : false}
                />
            </td>
            <td>
                <input type="checkbox"
                    disabled={!editable}
                    onChange={() => setCompleted(completed === 1 ? 0 : 1)} 
                    checked={completed  === 1 ? true : false} 
                />
            </td>
            <td>
                <input type="checkbox"
                    disabled={!editable}
                    onChange={() => setNotApplicable(notApplicable === 1 ? 0 : 1)}
                    checked={notApplicable  === 1 ? true : false}
                />
            </td>
            <td>
                {dateCompleted}
            </td>
            <td>
                <input type="checkbox"
                    disabled={!editable}
                    onChange={() => setReviewed(reviewed === 1 ? 0 : 1)}
                    checked={reviewed  === 1 ? true : false}
                />
            </td>
            <td>
                {editButton}
            </td>
        </tr>
    )
}
