import { getIDToken } from "../../utils/ms-graph-api-call";
const {REACT_APP_API_URL} = process.env;
const headers = {'Content-Type' : 'application/json'};

export default class LogApi {

    /**
     * function LogToServer
     * @param logObject: an object containing the message to log to the server's console.
     * message: a string to console log to the server.
     * @returns an object stating whether the log was successful or not, including the requested log message.
     */
    async logToServer(logObj) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/log`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(logObj)
        }).then(response => response.json());
    }
}