import React from 'react';
import './drop-down.css';    //CSS critical for dropdown's functionality
import "./multi-select.css";

/**
 * React functional component ObjectMultiSelectDropdown
 * @param items: An array of objects that are selectable.
 * @param itemPrimaryKey: the unique identifier of an object. Use mssql Primary Keys if available.
 * @param itemNameKey: a string discribing the key value pair that will display as the option in the multiselect dropdown.
 * @param itemNameSecondKey: a second string that works in tandem with itemNameKey. Used when first and last name of people need to be displayed, but are listed in 
 * two separate keys of the object.
 * @param selected: a state array that describes which options are selected.
 * @param setSelected: the useState hook function to change the value of param selected.
 * @param label: a string that describes what the options are. Used to generate the multiselect's button text as `${selected.length} ${label}(s) selected`. Example: 4 Student(s) selected.
 * @param isBig: a boolean describing whether the styling of the dropdown is big or not
 * @returns a dropdown component that can take objects as options and can have multiple items selected.
 */
export default function ObjectMultiSelect({items, itemPrimaryKey, itemNameKey, itemNameSecondKey, selected, setSelected, label, isBig, disabled=false }) {

    const handleSelect = (value) => {
        let selectedCopy = [...selected];
        let index = selectedCopy.findIndex(clickedItem => clickedItem[itemPrimaryKey] === parseInt(value));
        if(index !== -1) {  //If the item was previously selected, unselect it
            selectedCopy.splice(index, 1);
        } else {    //If the item wasn't previously selected, select it
            items.forEach((i) => {
                if(i[itemPrimaryKey] === parseInt(value)) {
                    selectedCopy.push(i);
                }
            });
        }
        setSelected(selectedCopy);
    }

    //Generates all dropdown items
    const dropdownItems = items.map((item, index) => {
        const optionString = itemNameSecondKey ? item[itemNameKey] + " " + item[itemNameSecondKey] 
            : item[itemNameKey];
        
        let dropdownItemStyle = "";
        let selectedIndex = selected.findIndex(clickedItem => clickedItem[itemPrimaryKey] === item[itemPrimaryKey]);
        if(selectedIndex !== -1) {
            dropdownItemStyle = "btn btn-dropdown-primary dropdown-item"
        } else {
            dropdownItemStyle = "btn btn-dropdown-selected dropdown-item"
        }
        return (
            <button type="button" key={index} value={item[itemPrimaryKey]} className={dropdownItemStyle} 
                onClick={(event) => handleSelect(event.target.value)}
            >
                {optionString}
            </button>
        )

    });

    const style = isBig ? "btn btn-outline-dark dropdown-toggle" 
        : "btn btn-outline-dark dropdown-toggle btn-sm";

    return (
        <div className="dropdown">
            <button className={ style }
                type="button" 
                id="dropdownMenuButton" 
                data-toggle="dropdown" 
                aria-haspopup="true" 
                aria-expanded="false"
                disabled={disabled}
            >
                {selected.length} {label}(s) selected
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{display: (disabled) ? 'none': ''}}>
                {dropdownItems}
            </div>
        </div>
    )
}
