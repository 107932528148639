import React, { useEffect, useState } from 'react';
import { ObjectDropdown } from "../../shared/components";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {compareTimeStrings, compareDateStrings, databaseToDatePicker, pickerToDatabaseFormat} from "../../utils/date-format";

export default function AddScheduleModal({frequencies, schedule, setSchedule, term}) {
    const [frequency, setFrequency] = useState(null);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [monStart, setMonStart] = useState("");
    const [monEnd, setMonEnd] = useState("");
    const [tuesStart, setTuesStart] = useState("");
    const [tuesEnd, setTuesEnd] = useState("");
    const [wedStart, setWedStart] = useState("");
    const [wedEnd, setWedEnd] = useState("");
    const [thursStart, setThursStart] = useState("");
    const [thursEnd, setThursEnd] = useState("");
    const [friStart, setFriStart] = useState("");
    const [friEnd, setFriEnd] = useState("");
    const [modal, setModal] = useState(false);
    const [displayAlert, setDisplayAlert] = useState(false);
    const [alertMSG, setAlertMSG] = useState("");
    const [alertStyle, setAlertStyle] = useState("");
    const toggle = () => setModal(!modal);

    useEffect(() => {
        if(term) {
            setStartDate(databaseToDatePicker(term.StartDTS));
            setEndDate(databaseToDatePicker(term.EndDTS));
        }
    }, [term]);

    useEffect(() => {
        if(schedule === null) {
            setFrequency(null);
            setMonStart("");
            setMonEnd("");
            setTuesStart("");
            setTuesEnd("");
            setWedStart("");
            setWedEnd("");
            setThursStart("");
            setThursEnd("");
            setFriStart("");
            setFriEnd("");
        }
    }, [schedule]);

    const oneOfTwoNull = (input1, input2) => {
        if(input1 && !input2) {
            return true;
        } else if(!input1 && input2) {
            return true;
        } else {
            return false;
        }
    }

    const writeSchedule = () => {
        setDisplayAlert(false);
        //If none of the time fields are filled out
        if(!monStart && !monEnd && !tuesStart && !tuesEnd && !wedStart && !wedEnd && !thursStart && !thursEnd && !friStart && !friEnd) {
            setAlertMSG("Fill out at least one day please");
            setAlertStyle("alert alert-warning alert-dismissible");
            setDisplayAlert(true);
            //Prevent writing
        //If only one of two time fields for a day is filled out
        } else if(oneOfTwoNull(monStart, monEnd) || oneOfTwoNull(tuesStart, tuesEnd) || oneOfTwoNull(wedStart, wedEnd) || oneOfTwoNull(thursStart, thursEnd) || oneOfTwoNull(friStart, friEnd)) {
            setAlertMSG("Please include both a start and end time");
            setAlertStyle("alert alert-warning alert-dismissible");
            setDisplayAlert(true);
        //If the start time is after the end time
        } else if(monStart && !compareTimeStrings(monStart, monEnd)) {
            setAlertMSG("Monday start time is after the end time");
            setAlertStyle("alert alert-warning alert-dismissible");
            setDisplayAlert(true);
        } else if(tuesStart && !compareTimeStrings(tuesStart, tuesEnd)) {
            setAlertMSG("Tuesday start time is after the end time");
            setAlertStyle("alert alert-warning alert-dismissible");
            setDisplayAlert(true);
        } else if(wedStart && !compareTimeStrings(wedStart, wedEnd)) {
            setAlertMSG("Wednesday start time is after the end time");
            setAlertStyle("alert alert-warning alert-dismissible");
            setDisplayAlert(true);
        } else if(thursStart && !compareTimeStrings(thursStart, thursEnd)) {
            setAlertMSG("Thursday start time is after the end time");
            setAlertStyle("alert alert-warning alert-dismissible");
            setDisplayAlert(true);
        } else if(friStart && !compareTimeStrings(friStart, friEnd)) {
            setAlertMSG("Friday start time is after the end time");
            setAlertStyle("alert alert-warning alert-dismissible");
            setDisplayAlert(true);
        } else if(!frequency) {
            setAlertMSG("Please select a frequency");
            setAlertStyle("alert alert-warning alert-dismissible");
            setDisplayAlert(true);
        } else if(!startDate || !endDate) {
            setAlertMSG("Please fill out both start date and end date");
            setAlertStyle("alert alert-warning alert-dismissible");
            setDisplayAlert(true);
        }else if(compareDateStrings(startDate, endDate) === -1) {
            setAlertMSG("Start date is after end date");
            setAlertStyle("alert alert-warning alert-dismissible");
            setDisplayAlert(true);
        } else {
            const scheduleItems = {
                FrequencyID: frequency.FrequencyID,
                SectionStartDTS: pickerToDatabaseFormat(startDate),
                SectionEndDTS: pickerToDatabaseFormat(endDate)

            }
            if(monStart && monEnd) {
                scheduleItems.MondayStartTS = monStart;
                scheduleItems.MondayEndTS = monEnd;
            }
            if(tuesStart && tuesEnd) {
                scheduleItems.TuesdayStartTS = tuesStart;
                scheduleItems.TuesdayEndTS = tuesEnd;
            }
            if(wedStart && wedEnd) {
                scheduleItems.WednesdayStartTS = wedStart;
                scheduleItems.WednesdayEndTS = wedEnd;
            }
            if(thursStart && thursEnd) {
                scheduleItems.ThursdayStartTS = thursStart;
                scheduleItems.ThursdayEndTS = thursEnd;
            }
            if(friStart && friEnd) {
                scheduleItems.FridayStartTS = friStart;
                scheduleItems.FridayEndTS = friEnd;
            }
            setSchedule(scheduleItems);
            setModal(false);
        }
    }

    const alert = displayAlert ? (
        <div className={alertStyle} role="alert">
            {alertMSG}
            <button type="button" className="btn-close" onClick={() => setDisplayAlert(false)} aria-label="Close"></button>
        </div> )
        : null

    return (
        <>
            <button className="btn btn-primary" onClick={toggle}>Schedule</button>
            <Modal isOpen={modal} toggle={toggle} fade={false}>
                <ModalHeader>
                    Schedule Section
                </ModalHeader>
                <ModalBody>
                    <ObjectDropdown
                        id="frequency-dropdown"
                        items={frequencies}
                        itemNameKey='FrequencyDSC'
                        selected={frequency}
                        setSelected={setFrequency}
                        label="Frequency"
                        isBig={true}
                    />
                    {alert}
                    <table className="table table-sm table-hover mx-0 mt-2">
                        <thead className="table-light">
                            <tr>
                                <th></th>
                                <th>START</th>
                                <th>END</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Monday</td>
                                <td>
                                    <input type="time" className="form-control" value={monStart} onChange={({target}) => setMonStart(target.value)} />
                                </td>
                                <td>
                                    <input type="time" className="form-control" value={monEnd} onChange={({target}) => setMonEnd(target.value)} />
                                </td>
                            </tr>
                            <tr>
                                <td>Tuesday</td>
                                <td>
                                    <input type="time" className="form-control" value={tuesStart} onChange={({target}) => setTuesStart(target.value)} />
                                </td>
                                <td>
                                <input type="time" className="form-control" value={tuesEnd} onChange={({target}) => setTuesEnd(target.value)} />
                                </td>
                            </tr>
                            <tr>
                                <td>Wednesday</td>
                                <td>
                                    <input type="time" className="form-control" value={wedStart} onChange={({target}) => setWedStart(target.value)} />
                                </td>
                                <td>
                                    <input type="time" className="form-control" value={wedEnd} onChange={({target}) => setWedEnd(target.value)} />
                                </td>
                            </tr>
                            <tr>
                                <td>Thursday</td>
                                <td>
                                    <input type="time" className="form-control" value={thursStart} onChange={({target}) => setThursStart(target.value)} />
                                </td>
                                <td>
                                    <input type="time" className="form-control" value={thursEnd} onChange={({target}) => setThursEnd(target.value)} />
                                </td>
                            </tr>
                            <tr>
                                <td>Friday</td>
                                <td>
                                    <input type="time" className="form-control" value={friStart} onChange={({target}) => setFriStart(target.value)} />
                                </td>
                                <td>
                                    <input type="time" className="form-control" value={friEnd} onChange={({target}) => setFriEnd(target.value)} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <label className="form-label">Start Date</label>
                    <input type="date" className="form-control" value={startDate} onChange={({target}) => setStartDate(target.value)}/>
                    <label className="form-label">End Date</label>
                    <input type="date" className="form-control" value={endDate} onChange={({target}) => setEndDate(target.value)}/>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-secondary" onClick={() => setModal(false)}>Close</button>
                    <button type="button" className="btn btn-primary" onClick={writeSchedule}>Write Schedule</button>
                </ModalFooter>
            </Modal>
        </>
    )
}
