import React, {useEffect, useState} from 'react';
import StudentAttendanceTab from "../student-attendance-tab/student-attendance-tab";
import InstructorAttendanceTab from "../instructor-attendance-tab/instructor-attendance-tab";
import { databaseToDatePicker, pickerToDatabaseFormat } from "../../utils/date-format";
import { AttendanceApi, InstructionNoteApi, NoteTypeApi, SectionRollApi } from "../../shared/services";
import { useAuth } from "../../context/auth-context"; 

export default function TakeAttendance({section, date, setDate}) {
    const { currentUser } = useAuth();  //The currently logged in user to record ModifiedByPersonID.
    const [tab, setTab] = useState("student-attendance");
    const [students, setStudents] = useState([]);   //All students in the section
    const [studentAttendanceData, setStudentAttendanceData] = useState([]); //State of all fields in form. Index of array corresponds to student index in students
    const [instructors, setInstructors] = useState([]);
    const [instructorAttendanceData, setInstructorAttendanceData] = useState([]);
    const [sectionLength, setSectionLength] = useState("0");
    const [instructionNoteHistory, setInstructionNoteHistory] = useState([]);
    const [noteData, setNoteData] = useState([]);
    const [noteTypes, setNoteTypes] = useState([]);
    const [displayAlert, setDisplayAlert] = useState(false);
    const [alertMSG, setAlertMSG] = useState("");
    const [alertStyle, setAlertStyle] = useState("");
    const [displayDateAlert, setDisplayDateAlert] = useState(false);
    const [dateAlertMSG, setDateAlertMSG] = useState("");
    const [dateAlertStyle, setDateAlertStyle] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [classHeld, setClassHeld] = useState(true);
    const myAttendanceApi = new AttendanceApi();
    const ABSENT_ATTENDANCE_SCORE = 6;

    const tabToDisplay = tab === "student-attendance" ? 
        <StudentAttendanceTab 
            attendanceData={studentAttendanceData} 
            setAttendanceData={setStudentAttendanceData}
            students={students} 
            setStudents={setStudents} 
            section={section} 
            date={date}
        /> 
        : <InstructorAttendanceTab 
            attendanceData={instructorAttendanceData} 
            setAttendanceData={setInstructorAttendanceData}
            instructors={instructors} 
            setInstructors={setInstructors} 
            section={section} 
            date={date}
            noteData={noteData} 
            setNoteData={setNoteData} 
            noteTypes={noteTypes} 
            setNoteTypes={setNoteTypes}
        />

    const activeTab = "nav-link adm-link active";
    const passiveTab = "nav-link adm-link";

    useEffect(() => {
        const myNoteTypeApi = new NoteTypeApi();
        myNoteTypeApi.getInstructionNoteTypes()
            .then(noteTypes => {
                setNoteTypes(noteTypes);

                const notesData = []

                for(let noteType of noteTypes) {
                    notesData.push({
                        NoteTypeID: noteType.NoteTypeID, 
                        NoteTXT: "", 
                        NoteTypeNM: noteType.NoteTypeNM,
                    });
                }
                setNoteData(notesData);
            });

        const myInstructionNoteApi = new InstructionNoteApi();
        myInstructionNoteApi.getInstructionNotesBySection(section.SectionID)
            .then(instructionNotes => {
                setInstructionNoteHistory(instructionNotes);
            });
    }, [section]);

    //This useEffect ensures that dates that have already been filled out will not be allowed.
    useEffect(() => {
        let dateMatch = false;
        for(let historyItem of instructionNoteHistory) {
            if(databaseToDatePicker(historyItem.SectionNoteDTS) === date) {
                dateMatch = true;
                setDateAlertMSG(`The date ${date} already has an attendance record, please edit that record in the Attendance History Tab or select another date.`);
                setDateAlertStyle("alert alert-warning alert-dismissible");
                setDisplayDateAlert(true);
            }
        }
        if(dateMatch) {
            setButtonDisabled(true);
        } else {
            setButtonDisabled(false);
            setDisplayDateAlert(false);
        }
    }, [date, instructionNoteHistory]);

    //Ran everytime either the state of section, currentUser, setAttendanceData, or setStudents changes. 
    //Grabs the student roll and instructor roll to initialize the state of the form data.
    useEffect(() => {
        const mySectionRollApi = new SectionRollApi();
        mySectionRollApi.getStudentsBySection(section.SectionID) //get the student roll for the section
        .then(studentList => {
            setStudents(studentList);
            //Setup the state for each student by mapping the studentList
            const initialRoll = studentList.map((student, index) => {
                return { ClassID: section.ClassID, PersonID: student.PersonID, ModifiedByPersonID: currentUser.PersonID }
            });
            //Store that map into the attendanceDate state
            setStudentAttendanceData(initialRoll);
        });

        mySectionRollApi.getInstructorsBySection(section.SectionID)
        .then(instructorList => {
            setInstructors(instructorList);
            const initialRoll = instructorList.map((instructor, index) => {
                return { ClassID: section.ClassID, PersonID: instructor.PersonID, ModifiedByPersonID: currentUser.PersonID }
            });
            //Store that map into the attendanceDate state
            setInstructorAttendanceData(initialRoll);
        });
    }, [section, currentUser]);
    
    //Once the student roster is fetched (or selected in case of a Club), initialize the roll.
    useEffect(() => {
        const initialRoll = students.map((student, index) => {
            return { ClassID: section.ClassID, PersonID: student.PersonID, ModifiedByPersonID: currentUser.PersonID }
        });
        //Store that map into the attendanceDate state
        setStudentAttendanceData(initialRoll);
    }, [currentUser, section, students]);

    //Checks form data for each student to ensure each required field was filled out
    const verifyStudentData = () => {
        let isValid = true;
        for(let studentValues of studentAttendanceData) {
            if(!studentValues.AttendanceScore
                || !studentValues.EngagementScore 
                || !studentValues.BehaviorScore ) {
                    isValid = false;
            }

            if(section.RequireProgressFLG === 1 
                && studentValues.AttendanceScore?.AttendanceScoreID !== ABSENT_ATTENDANCE_SCORE
                && (!studentValues.AttendanceProgressNote || studentValues.AttendanceProgressNote === "")) {
                isValid = false;
            }
        }
        return isValid;
    }

    //Checks form data for each instructor to ensure each required field was filled out
    const verifyInstructorData = () => {
        let isValid = true;
        for(let instructorValues of instructorAttendanceData) {
            if(!instructorValues.AttendanceScore ) {
                isValid = false;
            }
        }
        return isValid;
    }

    const verifyInstructionNotes = () => {
        // Checks if any notes are filled out. Only requires 1 to be filled out.
        const anyNotesFilledOut = noteData.some(nd => nd.NoteTXT !== "");

        const allMarkedAbsent = studentAttendanceData.every(sa => (sa.AttendanceScore && sa.AttendanceScore.AttendanceScoreID === 6));

        const sectionRequiresProgress = section.RequireProgressFLG === 1

        if (sectionRequiresProgress) {
            return (allMarkedAbsent || anyNotesFilledOut);
        } else {
            return true;
        }
    }

    const clearStudentFields = () => {
        const tempStudentAttendanceData = [...studentAttendanceData];
        tempStudentAttendanceData.forEach(attendanceDataItem => {
            attendanceDataItem.AttendanceNote = "";
            attendanceDataItem.AttendanceProgressNote = "";
            attendanceDataItem.AttendanceScoreID = null;
            attendanceDataItem.EngagementScoreID = null;
            attendanceDataItem.BehaviorScoreID = null;
        });
        setStudentAttendanceData(tempStudentAttendanceData);
    }

    const clearInstructorFields = async () => {
        const tempInstructorAtttendanceData = [...instructorAttendanceData];
        tempInstructorAtttendanceData.forEach(attendanceDataItem => {
            attendanceDataItem.AttendanceNote = "";
            attendanceDataItem.AttendanceScoreID = null;
        });
        setInstructorAttendanceData(tempInstructorAtttendanceData);
    }

    const clearInstructionNotes = async () => {
        const newNoteData = [...noteData]
        newNoteData.forEach(note => {
            note.NoteTXT = "";
        })

        setNoteData(newNoteData);
    }

    const submitAttendance = async () => {
        setButtonDisabled(true);
        if(classHeld) { // TODO: CHECK IF EVERYONE IS MARKED ABSENT
            const studentsFilledOut = verifyStudentData();
            const instructorsFilledOut = verifyInstructorData();
            const instructionFilledOut = verifyInstructionNotes();
            if(sectionLength && studentsFilledOut && instructorsFilledOut && instructionFilledOut) {
                myAttendanceApi.addAttendanceRobust({
                    SectionInstructionNotes : noteData,
                    InstructorAttendance: instructorAttendanceData,
                    StudentAttendance: studentAttendanceData,
                    ClassID: section.ClassID,
                    AttendanceDTS: pickerToDatabaseFormat(date),
                    SectionID: section.SectionID,
                    UserPersonID: currentUser.PersonID,
                    LengthNBR: sectionLength,
                    ClassNotHeld: false
                }).then(attendanceResponse => {
                    //If the backend responds with an error message, notify the user
                    if(attendanceResponse.error) {
                        setAlertMSG(`Attendance for ${date} wasn't successfully written. Cause: ${attendanceResponse.error}`);
                        setAlertStyle("alert alert-danger alert-dismissible");
                        setDisplayAlert(true);
                        setButtonDisabled(false);
                    } else {
                        //Add SectionInstructionNote to list if SectionInstructionNotes to prevent writing on the same date
                        // const instructionNoteHistoryCopy = [...instructionNoteHistory];
                        // instructionNoteHistoryCopy.push(attendanceResponse.SectionInstructionNote);
                        // setInstructionNoteHistory(instructionNoteHistoryCopy);
                        //Alert user of success
                        setAlertMSG(`Attendance for ${date} has been successfully recorded`);
                        setAlertStyle("alert alert-info alert-dismissible");
                        setDisplayAlert(true);
                        //Clear fields for next usage
                        clearStudentFields();
                        clearInstructorFields();
                        clearInstructionNotes();
                    }
                });
            } else {
                //Give alert to user to fill out more information depending on what is required.
                if(!studentsFilledOut) {
                    setAlertMSG(`Please fill out all required student information.`);
                } else if(!instructorsFilledOut) {
                    setAlertMSG(`Please fill out all required instructor information.`);
                } else if(!instructionFilledOut){
                    setAlertMSG(`Please fill out at least one instruction note.`);
                } else if(!sectionLength) {
                    setAlertMSG("Please add a section length");
                } else {
                    setAlertMSG("Not all required fields were filled out");
                }
                setAlertStyle("alert alert-warning alert-dismissible");
                setDisplayAlert(true);
                setButtonDisabled(false);
            }
        //If class wasn't held is marked, take attendance with class not held values.
        } else {
            //Take attendance without requiring fields filled in. No verification needed.
            myAttendanceApi.addAttendanceRobust({
                SectionInstructionNotes : noteData,
                InstructorAttendance: instructorAttendanceData,
                StudentAttendance: studentAttendanceData,
                ClassID: section.ClassID,
                AttendanceDTS: pickerToDatabaseFormat(date),
                SectionID: section.SectionID,
                UserPersonID: currentUser.personID,
                LengthNBR: sectionLength,
                ClassNotHeld: true
            }).then(attendanceResponse => {
                //If the backend responds with an error message, notify the user
                if(attendanceResponse.error) {
                    setAlertMSG(`Attendance for ${date} wasn't successfully written. Cause: ${attendanceResponse.error}`);
                    setAlertStyle("alert alert-danger alert-dismissible");
                    setDisplayAlert(true);
                } else {
                    //Add SectionInstructionNote to list if SectionInstructionNotes to prevent writing on the same date
                    // const instructionNoteHistoryCopy = [...instructionNoteHistory];
                    // instructionNoteHistoryCopy.push(attendanceResponse.SectionInstructionNote);
                    // setInstructionNoteHistory(instructionNoteHistoryCopy);
                    //Alert user of success
                    setAlertMSG(`Attendance for ${date} has been successfully recorded as a no classes held day.`);
                    setAlertStyle("alert alert-info alert-dismissible");
                    setDisplayAlert(true);
                    setClassHeld(true);
                    //Clear fields for next usage
                    clearStudentFields();
                    clearInstructorFields();
                    clearInstructionNotes();
                }
            });
            
        }
        
    }

    const alert = displayAlert ? (
        <div className={alertStyle} role="alert">
            {alertMSG}
            <button type="button" className="btn-close" onClick={() => setDisplayAlert(false)} aria-label="Close"></button>
        </div> )
        : null

    const dateAlert = displayDateAlert ? (
        <div className={dateAlertStyle} role="alert">
            {dateAlertMSG}
            <button type="button" className="btn-close" onClick={() => setDisplayDateAlert(false)} aria-label="Close"></button>
        </div> )
        : null

    return (
        <>
            {dateAlert}
            <div className="row my-2">
                <div className="col-2"></div>
                <div className="col-2">
                    <label className="form-label">Date</label>
                    <input 
                        type="date" 
                        className="form-control" 
                        value={date} 
                        onChange={({target}) => setDate(target.value)} 
                        min={databaseToDatePicker(section.StartDTS)}
                        max={databaseToDatePicker(section.EndDTS)}
                    />
                </div>
                <div className="col-2">
                    <label className="form-label">Section Length (minutes)</label>
                    <input 
                        type="number" 
                        className="form-control" 
                        min="0" 
                        value={sectionLength} 
                        onChange={({target}) => setSectionLength(target.value)} 
                    />
                </div>
                <div className="col-3">
                    <label className="form-label">Class Held?</label>
                    <div className="form-check form-switch">
                        <label className="form-label">{classHeld ? "Class Held" : "Class Not Held"}</label>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={!classHeld}
                            onChange={() => setClassHeld(!classHeld)}
                        />
                    </div>
                </div>
                <div className="col-2"></div>
            </div>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className={tab === "student-attendance" ? activeTab : passiveTab} id="student-attendance-tab" onClick={() => setTab("student-attendance")}>
                        STUDENT INFO
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={tab === "instructor-attendance" ? activeTab : passiveTab} id="instrutor-attendance-tab" onClick={() => setTab("instructor-attendance")}>
                        INSTRUCTOR INFO
                    </button>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                {tabToDisplay}
            </div>
            <div className="container mt-4">
                <button className="btn btn-primary w-100" onClick={submitAttendance} disabled={buttonDisabled}>Submit Attendance</button>
                {alert}
            </div>
        </>
    )
}
