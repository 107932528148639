import { getIDToken } from "../../utils/ms-graph-api-call";
const { REACT_APP_API_URL} = process.env;
const headers = {'Content-Type' : 'application/json'};

export default class BehaviorScoresApi {
    /**
     * method getBehaviorScores
     * @returns a promise of an array of all behavior scores
     */
    async getBehaviorScores() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;
        
        return fetch(`${REACT_APP_API_URL}/api/behaviorScores`, {
            headers: headers
        }).then(response => response.json());
    }

    /**
     * method addBehaviorScore
     * @param {object} scoreObject: includes the following- BehaviorScoreDSC 
     * @returns the newly created Behavior Score.
     */
    async addBehaviorScore(scoreObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/behaviorScores`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(scoreObject)
        }).then(response => response.json());
    }

    /**
     * method editBehaviorScore
     * @param {object} scoreObject: includes the following: BehaviorScoreID, BehaviorScoreDSC, ActiveFLG
     * @returns the updated behavior score.
     */
    async editBehaviorScore(scoreObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/behaviorScores`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(scoreObject)
        }).then(response => response.json());
    }
}