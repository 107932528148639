import React, {useEffect, useState} from 'react';
import { ActivityDetailApi, DashboardApi } from "../../shared/services";
import StudentContactRow from "./student-contact-row";
import { compareDateStrings, dateIsBetween } from '../../utils/date-format';
import CsvDownloader from 'react-csv-downloader';
import "./dashboard.css";

export default function StudentContactTable({selectedStudents, selectedPrimaryStaff, selectedContactTypes, startDate, endDate, reviewedAscending, searchText}) {
    const [studentContactData, setStudentContactData] = useState([]);
    const [editable, setEditable] = useState(false);
    const myActivityDetailApi = new ActivityDetailApi();
    const [noRows, setNoRows] = useState(false);
    const [tableLoading, setTableLoading] = useState(false)

    useEffect(() => {
        const PersonIDs = selectedStudents ? selectedStudents.map(student => student.PersonID) : null;
        const PrimaryStaffPersonIDs = selectedPrimaryStaff ? selectedPrimaryStaff.map(staff => staff.PersonID) : null;
        const ContactTypeIDs = selectedContactTypes ? selectedContactTypes.map(contactType => contactType.ActivityTypeID) : null;
        const myDashboardApi = new DashboardApi();
        setTableLoading(true);
        setNoRows(false);
        myDashboardApi.getStudentContactData(PersonIDs, PrimaryStaffPersonIDs, ContactTypeIDs, searchText)
            .then(contactData => {
                const filteredContactData = contactData.filter((item) => {
                    return dateIsBetween(new Date(item.ActivityDTS), new Date(startDate), new Date(endDate));
                });
                //If a start and end date are selected, only display activity data that is between those two dates.
                const dataToDisplay = startDate && endDate ? filteredContactData : contactData;
                setStudentContactData(dataToDisplay.sort((a,b) => {
                    return compareDateStrings(a.ActivityDTS, b.ActivityDTS);
                }));
                setTableLoading(false);
                setNoRows(dataToDisplay.length === 0);
            })
    }, [selectedStudents, selectedPrimaryStaff, selectedContactTypes, startDate, endDate, searchText]);

    useEffect(() => {
        studentContactData.forEach(scd => {
            scd.ActivityNote = scd.ActivityNote.replaceAll("\r", "").replaceAll("\n", " ");
            scd.StudentNote = scd.StudentNote.replaceAll("\r", "").replaceAll("\n", " ");
        })
    }, [studentContactData])

    const CSVheaders = [
        {displayName: "Student", id: "StudentNM"},
        {displayName: "Contact Title", id: "ActivityNM"},
        {displayName: "Contact Type", id: "ActivityTypeNM"},
        {displayName: "Date", id: "ActivityDatePretty"},
        {displayName: "Time", id: "ActivityTimePretty"},
        {displayName: "Contact Notes", id: "ActivityNote"},
        {displayName: "Student Notes", id: "StudentNote"},
        { displayName: "Documented By", id: "PrimaryStaffNM"}
    ];

    const handleSave = () => {
        const reviewedRecords = studentContactData.map(r => ({ActivityDetailID: r.ActivityDetailID, ReviewedFLG: r.ReviewedFLG}))
        myActivityDetailApi.reviewActivityDetail(reviewedRecords)
        setEditable(false);
    }

    const setContactRecords = (cr) => {
        setStudentContactData(
            studentContactData.map(crs =>
                crs.ActivityDetailID === cr.ActivityDetailID ? {...cr} : crs
        ))
    }

    const filterReview = function(x) {
        if (reviewedAscending.reviewFilter === "reviewed") {
            return (x.ReviewedFLG === 1) ? x : null;
        } else if (reviewedAscending.reviewFilter === "not reviewed") {
            return (x.ReviewedFLG === 0) ? x : null;
        }
        return x;
    };

    const displayTableRows = studentContactData.filter(filterReview).map((contactRecord, index) => {
        return (
            <StudentContactRow key={index} contactRecord={contactRecord} setContactRecords={setContactRecords} handleSave={handleSave} editable={editable} setEditable={setEditable}/>
        );
    });

    return (
        <>
            <div className="download-container mx-1">
                <CsvDownloader columns={CSVheaders} datas={studentContactData} filename="student_contact_map" separator="," wrapColumnChar='"' disabled={studentContactData === []} bom={false}>
                    <button className="btn btn-primary" disabled={studentContactData === []}>Download Table Data</button>
                </CsvDownloader>
            </div>
            <div className='table-60'>
                <table className="table table-sm table-hover mx-0 mt-2">
                    <thead className="table-light fixed-column-header">
                        <tr>
                            <th>STUDENT</th>
                            <th>DOCUMENTED BY</th>
                            <th>CONTACT TITLE</th>
                            <th>CONTACT TYPE</th>
                            <th>DATE</th>
                            <th>TIME</th>
                            <th>CONTACT NOTES</th>
                            <th>STUDENT NOTES</th>
                            <th>REVIEWED</th>
                            <th>EDIT</th>
                        </tr>
                    </thead>
                    {tableLoading ? (<tbody />) : (
                        <tbody>
                            {displayTableRows}
                        </tbody>
                    )}
                </table>
                {tableLoading ? (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                        </div>
                    </div>
                ) : ""}
                {(noRows) ? (
                    <div className="d-flex justify-content-center">
                        No results found.
                    </div>) : null}
            </div>

        </>
    )
}
