import React, {useState} from 'react';
import { databaseToTableFormat } from "../../utils/date-format";
import { Dropdown, NewlineText } from "../../shared/components";
import { editIcon, saveIcon } from "../../assets/images/images";
import { AttendanceApi, NoteApi } from "../../shared/services";
import { useAuth } from "../../context/auth-context";

export default function StudentHistoryRow({attendanceRecord, attendanceValues, behaviorValues, engagementValues}) {
    const [editable, setEditable] = useState(false);
    const [notes, setNotes] = useState(attendanceRecord.AttendanceNoteTXT);
    const [progressNotes, setprogressNotes] = useState(attendanceRecord.AttendanceProgressNoteTXT);
    const [attendanceScoreDSC, setAttendanceScoreDSC] = useState(attendanceRecord.AttendanceScoreDSC);
    const [attendanceScoreID, setAttendanceScoreID] = useState(attendanceRecord.AttendanceScoreID);
    const [engagementScoreDSC, setEngagementScoreDSC] = useState(attendanceRecord.EngagementScoreDSC);
    const [engagementScoreID, setEngagementScoreID] = useState(attendanceRecord.EngagementScoreID);
    const [behaviorScoreDSC, setBehaviorScoreDSC] = useState(attendanceRecord.BehaviorScoreDSC);
    const [behaviorScoreID, setBehaviorScoreID] = useState(attendanceRecord.BehaviorScoreID);
    const { currentUser } = useAuth();

    const editHistoryRow = () => {
        const myAttendanceApi = new AttendanceApi();
        const myNoteApi = new NoteApi();
        myNoteApi.editNote({
            NoteID: attendanceRecord.AttendanceNoteID,
            NoteTXT: notes,
            NoteModifiedByPersonID: currentUser.PersonID
        });
        myNoteApi.editNote({
            NoteID: attendanceRecord.AttendanceProgressNoteID,
            NoteTXT: progressNotes,
            NoteModifiedByPersonID: currentUser.PersonID
        });
        myAttendanceApi.editAttendance({
            AttendanceID: attendanceRecord.AttendanceID,
            ClassID: attendanceRecord.ClassID,
            PersonID: attendanceRecord.StudentID,
            AttendanceScoreID: attendanceScoreID,
            EngagementScoreID: engagementScoreID,
            BehaviorScoreID: behaviorScoreID,
        });
        setEditable(false);
    }

    /**
     * function setOption: used to manage the values of all dropdowns
     * @param key: the key of the dropdown the user interacted with
     * @param value: the value that the user selected within the dropdown
     */
     const setOption = (key, value) => {
        if(key === "attendance-dropdown") { //If the attendance dropdown was changed, find the matching value in the attendanceValues array
            const selectedAttendanceScore = attendanceValues.filter(attendanceSc => attendanceSc.AttendanceScoreDSC === value)[0];
            setAttendanceScoreID(selectedAttendanceScore.AttendanceScoreID);
            setAttendanceScoreDSC(selectedAttendanceScore.AttendanceScoreDSC);
        } else if(key === "engagement-dropdown") {  //If the engagement dropdown was changed
            const selectedEngagement = engagementValues.filter(engagementSc => engagementSc.EngagementScoreDSC === value)[0];
            setEngagementScoreID(selectedEngagement.EngagementScoreID);
            setEngagementScoreDSC(selectedEngagement.EngagementScoreDSC);
        } else {    //if the behavior dropdown was changed
            const selectedBehavior = behaviorValues.filter(behaviorSc => behaviorSc.BehaviorScoreDSC === value)[0];
            setBehaviorScoreID(selectedBehavior.BehaviorScoreID);
            setBehaviorScoreDSC(selectedBehavior.BehaviorScoreDSC);
        }
    };

    const attendanceOptions = attendanceValues.map((option, index) => option.AttendanceScoreDSC);
    const behaviorOptions = behaviorValues.map((option, index) => option.BehaviorScoreDSC);
    const engagementOptions = engagementValues.map((option, index) => option.EngagementScoreDSC);

    if(editable) {
        return (
            <tr>
                <td>{databaseToTableFormat(attendanceRecord.AttendanceDTS)}</td>
                <td>{attendanceRecord.StudentFirstNM + " " + attendanceRecord.StudentLastNM}</td>
                <td>
                    <Dropdown 
                        id="attendance-dropdown" 
                        items={attendanceOptions} 
                        label="Attendance" 
                        onSelect={setOption}
                        initialValue={attendanceScoreDSC}    
                    />
                </td>
                <td>
                    <Dropdown 
                        id="behavior-dropdown" 
                        items={behaviorOptions} 
                        label="Behavior" 
                        onSelect={setOption}
                        initialValue={behaviorScoreDSC}
                    />
                </td>
                <td>
                    <Dropdown 
                        id="engagement-dropdown" 
                        items={engagementOptions} 
                        label="Engagement" 
                        onSelect={setOption}
                        initialValue={engagementScoreDSC}
                    />
                </td>
                <td>
                    <textarea 
                        className="form-control" 
                        id="notes" 
                        rows="1" 
                        value={notes}
                        onChange={({target}) => setNotes(target.value)}
                    />
                </td>
                <td>
                <textarea 
                        className="form-control" 
                        id="progress-notes" 
                        rows="1" 
                        value={progressNotes}
                        onChange={({target}) => setprogressNotes(target.value)}
                    />
                </td>
                <td>
                    <button type="button" className="btn" onClick={editHistoryRow}>
                        <img className="edit" alt="edit" src={saveIcon}/>
                    </button>
                </td>
            </tr>
        )
    } else {
        return (
            <tr>
                <td>{databaseToTableFormat(attendanceRecord.AttendanceDTS)}</td>
                <td>{attendanceRecord.StudentFirstNM + " " + attendanceRecord.StudentLastNM}</td>
                <td>{attendanceScoreDSC}</td>
                <td>{behaviorScoreDSC}</td>
                <td>{engagementScoreDSC}</td>
                <td>
                    <NewlineText text={notes}/>
                </td>
                <td>
                    <NewlineText text={progressNotes}/>
                </td>
                <td>
                    <button type="button" className="btn" onClick={() => setEditable(true)}>
                        <img className="edit" alt="edit" src={editIcon}/>
                    </button>
                </td>
            </tr>
        )
    }
}
