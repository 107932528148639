import { getIDToken } from "../../utils/ms-graph-api-call";
const {REACT_APP_API_URL} = process.env;
const headers = {'Content-Type' : 'application/json'};

export default class NoteApi {
    /**
     * Method getNoteTypes
     * @returns an array of each note type.
     */
    async getNoteTypes() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/note-type`, {
            headers: headers
        }).then(response => response.json());
    }

    async getInstructionNoteTypes() {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/notes/types/instruction`, {
            headers: headers
        }).then(response => response.json());
    }
    /**
     * Method addNoteType
     * @param {object} noteTypeObject: an object with a NoteTypeDSC, NoteTypeNM, NoteTypeShortenedNM, and InstructionNoteFLG.
     * @returns the newly created note type.
     */
    async addNoteType(noteTypeObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;

        return fetch(`${REACT_APP_API_URL}/api/note-type`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(noteTypeObject)
        }).then(response => response.json());
    }
    /**
     * Method editNoteType
     * @param {object} noteObject: an object with the NoteTypeID, NoteTypeDSC, NoteTypeNM, NoteTypeShortenedNM, InstructionNoteFLG, and ActiveFLG.
     * @returns the newly edited note type.
     */
    async editNoteType(noteTypeObject) {
        const idToken = await getIDToken();
        const bearer = `Bearer ${idToken}`;
        headers.authorization = bearer;
        
        return fetch(`${REACT_APP_API_URL}/api/note-type`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(noteTypeObject)
        }).then(response => response.json());
    }
}