import React from 'react';
import { Switch, Route, Redirect} from 'react-router-dom';//React Router Dom components. Handles server side routing
import { Navbar } from "../shared/components";  //Navigation bar for app
import {AttendanceViewComponent, ActivitiesViewComponent, AdminViewComponent, DashboardViewComponent, LevelsViewComponent} from '../views';
import { useAuth } from "../context/auth-context";

/**
 * React functional component Pages
 * @returns the Navigation bar and all of the views of the app with client side routing.
 */
export default function Transitions() {
    //Switch and Route gives access to the different pages of the app without need to load in next page
    return (
        <>
            <Navbar/>
            <Switch>
                <Route exact path="/" component={AttendanceViewComponent}/>
                <Route path="/contacts" component={ActivitiesViewComponent}/>
                <PrivateRoute path="/admin">
                    <AdminViewComponent/>
                </PrivateRoute>
                <Route path="/dashboard" component={DashboardViewComponent}/>
                <Route path="/levels" component={LevelsViewComponent}/>
            </Switch>     
        </>
    )
}

const PrivateRoute = ({ children, ...rest}) => {
    const { isAdmin } = useAuth();

    return (
        <Route {...rest} render={({location}) => 
            isAdmin() ? (children) : (
            <Redirect to={{pathname: '/', state: {from: location}}} />
            )
        }/>
    )
}
