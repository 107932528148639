import { LogLevel } from "@azure/msal-browser"; //Used to write logs about the connection to Azure AD
//Environment variables
const { REACT_APP_AD_APPLICATION_ID, REACT_APP_TENANT_ID } = process.env;

//Browser check varaibles
const ua = window.navigator.userAgent;  //Determine the browser
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;    //True if internet explorer is being used
const isEdge = msedge > 0;  //True if Microsoft Edge is being used
const isFirefox = firefox > 0;  //True if Firefox is being used

export const msalConfig = {
    auth: { //This connects our Single Page Application to our Microsoft Azure AD accounts
        clientId: REACT_APP_AD_APPLICATION_ID,
        authority: `https://login.microsoftonline.com/${REACT_APP_TENANT_ID}`,
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    },
    cache: {    //If the browser is Internet Explorer, Microsoft Edge, or Firefox, then store auth state in a Cookie
        storeAuthStateInCookie: isIE || isEdge || isFirefox
    },
    system: {
        loggerOptions: {    //Used to log messages on success or failure of AD connection
            loggerCallback: (level, message, containsPii) => {
                if(containsPii) {
                    return;
                }
                switch(level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        //console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

export const msalConfigForDashboard = {
    auth: { //This connects our Single Page Application to our Microsoft Azure AD accounts
        clientId: REACT_APP_AD_APPLICATION_ID,
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    },
    cache: {    //If the browser is Internet Explorer, Microsoft Edge, or Firefox, then store auth state in a Cookie
        storeAuthStateInCookie: isIE || isEdge || isFirefox
    },
    system: {
        loggerOptions: {    //Used to log messages on success or failure of AD connection
            loggerCallback: (level, message, containsPii) => {
                if(containsPii) {
                    return;
                }
                switch(level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        //console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

//Add any scopes needed for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"]   //Allows us to read user information from a token
}

//Add any endpoints needed for MS Graph API services. 
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"  //Used to send fetch request to get Azure AD user's info.
}